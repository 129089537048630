import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Slide,
  Box,
  Button,
  TextField,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StandAlone from 'components/cards/StandAloneChat';

import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';

interface DropdownData {
  [category: string]: {
    [subCategory: string]: any;
  };
}

const keywords: { [key: string]: string[] } = {
  Finance: [],
  'Health Care': [],
  'Media and Entertainment': []
};

const dropdownData: DropdownData = {
  Finance: {
    'Client Assist': [
      { value: 'Personalized-Account Opening', uuid: uuidv4() },
      { value: 'Personalized-Loan Application', uuid: uuidv4() },
      { value: 'Trade and Investment', uuid: uuidv4() },
      { value: 'Finance Encyclopedia', uuid: uuidv4() },
      { value: 'Logistics', uuid: uuidv4() }
    ],
    'Employee Assist': [
      { value: 'Compliance', uuid: uuidv4() },
      { value: 'Encyclopedia (Internal)', uuid: uuidv4() },
      { value: 'Customer Information', uuid: uuidv4() }
    ]
  },
  Insurance: {
    'Vehicle Insurance': [
      { value: 'New Insurance', uuid: uuidv4() },
      { value: 'Claim Checker', uuid: uuidv4() }
    ],
    'Health Insurance': [
      { value: 'New Insurance', uuid: uuidv4() },
      { value: 'Claim Checker', uuid: uuidv4() }
    ],
    'Life Insurance': [
      { value: 'New Insurance', uuid: uuidv4() },
      { value: 'Claim Checker', uuid: uuidv4() }
    ]
  },
  'Health Care': {
    'Patient AI Assist': [
      { value: 'Treatment Assist', uuid: uuidv4() },
      { value: 'Encyclopedia', uuid: uuidv4() },
      { value: 'Symptom Checker', uuid: uuidv4() },
      { value: 'Logistics', uuid: uuidv4() }
    ],
    'Operations AI Assist': [
      { value: 'Billing or Invoicing', uuid: uuidv4() },
      { value: 'Insurance Assist', uuid: uuidv4() },
      { value: 'Admin Work Flow', uuid: uuidv4() },
      { value: 'Issue Resolution', uuid: uuidv4() }
    ],
    'Provider AI Assist': [
      { value: 'Patient Id', uuid: uuidv4() },
      { value: 'Encyclopedia', uuid: uuidv4() },
      { value: 'Care Plan', uuid: uuidv4() },
      { value: 'References', uuid: uuidv4() }
    ]
  },
  'Media and Entertainment': {
    Media: [
      { value: 'Visual media', uuid: uuidv4() },
      { value: 'Print Media', uuid: uuidv4() }
    ],
    Entertainment: [
      { value: 'Movies', uuid: uuidv4() },
      { value: 'TV Shows', uuid: uuidv4() }
    ]
  }
};
const getUUID = (selectedValue: string, selectedSub: string, checkedValue: string): string | undefined => {
  const data = dropdownData;
  if (data[selectedValue] && data[selectedValue][selectedSub]) {
    const items = data[selectedValue][selectedSub];
    const item = items.find((item: any) => item.value === checkedValue);
    return item?.uuid;
  }
  return undefined;
};

const hasNonEmptyValue = (data: { [key: string]: string }): boolean => {
  return Object.values(data).some((value) => value !== '' && value !== null && value !== undefined);
};

const StandAloneChatBox: React.FC = () => {
  const [checked, setChecked] = useState<string>('Personalized-Account Opening');
  const [selectedValue, setSelectedValue] = useState<string>('Finance');
  const [selectedSub, setSelectedSub] = useState<string>('Client Assist');
  const [accordionData, setAccordionData] = useState<any>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const searchParams = new URLSearchParams(window.location.search);
  const [formOpen, setFormOpen] = useState(false);
  const titleValue = searchParams.get('title');
  const [expanded, setExpanded] = useState<string[]>([]);
  const [questions, setQuestions] = useState<string[]>([]);
  useEffect(() => {
    if (titleValue) {
      const valueMap: { [key: string]: string } = {
        Finance: 'Finance',
        HealthCare: 'Health Care',
        Insurance: 'Insurance',
        NexEnt: 'Media and Entertainment'
      };
      const subMap: { [key: string]: string } = {
        Finance: 'Client Assist',
        'Health Care': 'Patient AI Assist'
      };

      const newValue = valueMap[titleValue];
      const newSub = subMap[newValue] || '';
      setSelectedValue(newValue);
      setSelectedSub(newSub);
    }
  }, [titleValue]);
  console.log(formData, 'formData');
  useEffect(() => {
    setExpanded(Object.keys(accordionData));
  }, [accordionData]);

  useEffect(() => {
    const svgUrl = 'https://genai-modules.s3.amazonaws.com/architectures/standalone-chatbot.svg';
    fetch(svgUrl)
      .then((response) => (response.ok ? response.text() : Promise.reject(`Network response was not ok: ${response.statusText}`)))
      .then((svgContent) => {
        const container = document.getElementById('svg-container');
        if (container) {
          container.innerHTML = svgContent;
        }
      })
      .catch((error) => {
        const container = document.getElementById('svg-container');
        if (container) {
          container.innerText = 'Error loading SVG';
        }
      });
  }, [isOpen]);

  const handleChange = (event: any) => {
    const value = event.target.value as string;
    setSelectedValue(value);
    setAccordionData({});
    setFormData({});

    if (dropdownData[value]) {
      const firstKey = Object.keys(dropdownData[value])[0] || '';
      setSelectedSub(firstKey);
      if (dropdownData[value][firstKey]) {
        setChecked(dropdownData[value][firstKey][0].value || '');
      }
    }
  };

  const handleSubChange = (event: any) => {
    const value = event.target.value as string;

    setSelectedSub(value);
    setChecked(dropdownData[selectedValue][value][0].value || '');
  };

  const handleDropdownChange = (values: string): void => {
    for (const key in keywords) {
      if (keywords[key].some((keyword) => values.toLowerCase().includes(keyword.toLowerCase()))) {
        setSelectedValue(key);
        const firstKey = Object.keys(dropdownData[key])[0] || '';
        setSelectedSub(firstKey);
        setChecked(dropdownData[key][firstKey][0].value || '');
        return;
      }
    }
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded((prev) => (newExpanded ? [...prev, panel] : prev.filter((item) => item !== panel)));
  };

  const filteredAccordionData = Object.keys(accordionData).reduce((acc: DropdownData, key: string) => {
    if (hasNonEmptyValue(accordionData[key])) {
      acc[key] = accordionData[key];
    }
    return acc;
  }, {});

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction={'row'} alignItems={'center'} sx={{ height: 64, my: 2 }} justifyContent={'space-between'}>
        <Typography variant="h4" sx={{ color: '#00248C', p: 1 }}>
          Digital AI Ally
        </Typography>
        <button
          style={{
            background: '#E4EDFF',
            border: 0,
            height: '100%',
            padding: '0px 30px',
            borderRadius: 8,
            cursor: 'pointer',
            color: '#2824a3',
            fontSize: '14px',
            fontWeight: 500,
            maxHeight: 48
          }}
          onClick={() => setIsOpen(true)}
        >
          Architecture Diagram
        </button>
      </Stack>
      <Grid container spacing={2} sx={{ pb: 5 }}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="category-select-label">Select a category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={selectedValue}
              label="Select a category"
              onChange={handleChange}
            >
              {Object.keys(dropdownData).map((each) => (
                <MenuItem key={each} value={each}>
                  {each}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {Object.keys(dropdownData[selectedValue])?.length > 0 && (
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="subcategory-select-label">Select a sub category</InputLabel>
              <Select
                labelId="subcategory-select-label"
                id="subcategory-select"
                value={selectedSub}
                label="Sample Questions"
                onChange={handleSubChange}
              >
                {Object.keys(dropdownData[selectedValue]).map((each) => (
                  <MenuItem key={each} value={each}>
                    {each}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={3}>
          <Card sx={{ height: '100%' }}>
            <Stack>
              <img
                src={
                  selectedValue === 'Health Care'
                    ? healthCare
                    : selectedValue === 'Insurance'
                    ? insuranceBot
                    : selectedValue === 'Finance'
                    ? financeBot
                    : mediaBot
                }
                alt="chatbot"
                width="300px"
                height="250px"
                style={{ marginLeft: '5%' }}
              />
              <FormControl sx={{ m: 1 }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={checked}
                  name="radio-buttons-group"
                  onChange={(e) => setChecked(e.target.value)}
                >
                  {dropdownData[selectedValue][selectedSub]?.map((each: DropdownItem) => (
                    <FormControlLabel
                      key={each.uuid}
                      value={each.value}
                      control={<Radio />}
                      label={each.value}
                      sx={{ bgcolor: '#E4EDFF', m: 1 }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Stack>
          </Card>
        </Grid> */}
        <Grid item xs={12} md={9}>
          <StandAlone
            questions={questions}
            handleDropdownChange={handleDropdownChange}
            selectedValue={selectedValue}
            setAccordionData={setAccordionData}
            setFormData={setFormData}
            checked={checked}
            selectedSub={selectedSub}
            setQuestions={setQuestions}
            getUUID={getUUID}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          {filteredAccordionData &&
            Object.keys(filteredAccordionData).map((key, index) => (
              <Slide direction="left" in={true} timeout={500} key={index}>
                <Accordion
                  expanded={expanded.includes(`panel${index}`)}
                  onChange={handleAccordionChange(`panel${index}`)}
                  sx={{
                    border: 'none',
                    boxShadow: 4,
                    mt: 1,
                    borderRadius: 2
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      background: 'linear-gradient(to right, #3870e0, #00248c)',
                      color: 'white',
                      fontWeight: 'semibold',
                      fontSize: '18px',
                      borderRadius: 2
                    }}
                  >
                    {key}
                  </AccordionSummary>
                  <AccordionDetails>
                    {Object.entries(filteredAccordionData[key]).map(
                      ([subKey, value]) =>
                        value && (
                          <div key={subKey}>
                            <strong>{subKey}:</strong> {value}
                          </div>
                        )
                    )}
                  </AccordionDetails>
                </Accordion>
              </Slide>
            ))}
          {Object.values(formData).filter((value) => value !== '').length > 1 && (
            <Slide direction="left" in={true} timeout={500} style={{ marginTop: 30 }}>
              <Button
                variant="contained"
                onClick={() => {
                  setFormOpen(true);
                }}
              >
                Show Form
              </Button>
            </Slide>
          )}
        </Grid>
      </Grid>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '95%',
            maxHeight: '90vh',
            overflowY: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
              px: 2,
              backgroundImage: `linear-gradient(90deg, #3870E0 0%, #00248C 100%)`
            }}
          >
            <Typography
              sx={{
                color: '#fff',
                fontWeight: '600',
                textAlign: 'center',
                flex: '1',
                p: 1.5
              }}
            >
              Architecture Diagram
            </Typography>

            <IconButton
              sx={{
                color: '#fff',
                flex: '0'
              }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ p: 5 }}>
            <div id="svg-container" style={{ width: '90%', overflow: 'hidden', margin: 'auto' }}></div>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={formOpen}
        onClose={() => setFormOpen(false)}
        sx={{
          borderRadius: 10
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            maxHeight: '90vh',
            overflowY: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 4
          }}
        >
          {checked === 'Personalized-Account Opening' ? (
            <h2>Bank Account Opening Form</h2>
          ) : checked === 'Personalized-Loan Application' ? (
            <h2>Insurance Form</h2>
          ) : (
            <></>
          )}
          <hr
            style={{
              marginBottom: 20
            }}
          />
          <Grid container spacing={2}>
            {Object.entries(formData).map(([key, value]) => (
              <Grid item xs={12} md={key.length < 10 ? 6 : 12} key={key}>
                <label>{key}</label>
                <TextField name={key} value={value} fullWidth />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default StandAloneChatBox;
