import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateProps } from 'types/root';
import useAxios from 'utils/axios';
import useUserData from './useUserData';
import { getPastMonths } from 'pages/utils/getPastMonths';

export const getMonthName = (month: number, year: number) => {
  const date = new Date(year, month - 1);
  const monthName = date.toLocaleString('default', { month: 'short' });
  return monthName + ' ' + year;
};

export const getDates = (data: string) => {
  const dateParts = data.split('-');
  const startDateString = dateParts[0];
  const endDateString = dateParts[1];
  const startDate = new Date(startDateString.trim());
  const endDate = new Date(endDateString.trim());
  const from_date = startDate.toISOString().slice(0, 10);
  const to_date = endDate.toISOString().slice(0, 10);
  return {
    from_date,
    to_date
  };
};
export const useGraphData = () => {
  const { getUserData } = useUserData();
  const filters = useSelector((state: RootStateProps) => state.filters.data);
  const axiosServices = useAxios();
  const [, setLoading] = useState(false);
  const fetchData = async (params: any, url: string) => {
    if (filters[0].value.length !== 0 && filters.filter((f) => f.value === params.filter).length === 0) {
      const { filter, ...rest } = params;
      params = { ...rest, from_date: getDates(filter).from_date, to_date: getDates(filter).to_date };
    }
    setLoading(true);
    return await axiosServices
      .get(url, { params: { ...params, aws_account_id: getUserData('selectedAwsAccountId') } })
      .then((res) => {
        setLoading(false);
        return {
          xaxisData: getPastMonths(params.filter),
          yaxisData: res.data.data.items,
          cumulativeData: res.data.data.cumulative,
          cardData: res.data.data.card_data || null,
          loading: false
        };
      })
      .catch(() => {
        setLoading(false);
        return { xaxisData: [], yaxisData: [], cumulativeData: [], cardData: null, loading: false };
      });
  };
  return { fetchData };
};
