import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import AmplifyLogin from 'pages/auth/amplify-login';
import { Navigate, useLocation } from 'react-router';
import EnvironmentMode from 'enums/environmentMode';
import { useEnvironment } from 'hooks';
// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));
const InquiryComponent = Loadable(lazy(() => import('pages/auth/inquiry')));

// ==============================|| AUTH ROUTING ||============================== //
function ShowCheckMail({ children }: { children: JSX.Element }) {
  const location = useLocation();
  if (location.state?.data.codeVerifyFrom) {
    return <>{children}</>;
  }
  return <Navigate to="/login" replace />;
}
function RestrictRegisterDemo() {
  const { currentEnvironment } = useEnvironment();
  if (currentEnvironment === EnvironmentMode.DEMO) {
    return <AuthLogin />;
  }
  return <AuthRegister />;
}
const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <AuthLogin />
    },
    { path: 'login', element: <AuthLogin /> },
    { path: 'checking-login', element: <AmplifyLogin /> },
    {
      path: 'register',
      element: <RestrictRegisterDemo />
    },
    {
      path: 'forgot-password',
      element: <AuthForgotPassword />
    },
    {
      path: 'check-mail',
      element: (
        <ShowCheckMail>
          <AuthCheckMail />
        </ShowCheckMail>
      )
    },
    {
      path: 'reset-password',
      element: <AuthResetPassword />
    },
    {
      path: 'code-verification',
      element: <AuthCodeVerification />
    },
    { path: 'inquiry', element: <InquiryComponent /> }
  ]
};

export default LoginRoutes;
