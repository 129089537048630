import { memo, useEffect, useMemo, useState } from 'react';

// material-ui
// import { alpha, useTheme } from '@mui/material/styles';
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, styled } from '@mui/material';

// third-party
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes';

import {
  useColumnOrder,
  useExpanded,
  useFilters,
  useGroupBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  Column,
  HeaderGroup,
  Row,
  Cell,
  useBlockLayout,
  useResizeColumns
} from 'react-table';
import { useNavigate } from 'react-router';
// project import
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';

import {
  HidingSelect,
  HeaderSort,
  TablePagination,
  EmptyTable,
  ExportTableData,
  IndeterminateCheckbox,
  IndeterminateRadio
} from 'components/third-party/ReactTable';

import {
  renderFilterTypes,
  GlobalFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
  SliderColumnFilter,
  NumberRangeColumnFilter,
  customFilter
} from 'utils/react-table';

// assets
import { getSelectedRowType, subUrlType } from 'pages/utils/getSavingsData';
import { useSticky } from 'react-table-sticky';
import { formatValue } from 'utils/formatter';
import { MinusOutlined } from '@ant-design/icons';

// ==============================|| REACT TABLE ||============================== //

const TableWrapper = styled('div')(({ theme: any }) => ({
  '.header': {
    position: 'sticky',
    zIndex: 1,
    width: 'fit-content'
  },
  '& th[data-sticky-td]': {
    position: 'sticky',
    zIndex: '5 !important'
  }
}));

const ReactTable = memo((props: any) => {
  const { columns, data, enableClick, savingsType }: { columns: Column[]; data: []; enableClick: any; savingsType: any } = props;
  const [tablePage, setTablePage] = useState<{ pageIndex: number; pageSize: number }>({
    pageIndex: 0,
    pageSize: 10
  });
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const navigate = useNavigate();

  const defaultColumn: any = useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  );

  const VisibleColumn = props.hideColumns || [];
  const includesTypeColumns = columns.reduce((c: { id: string; value: string }[], v) => {
    if (v.filter === 'includes' && typeof v.accessor === 'string') {
      c.push({ id: v.accessor, value: '' });
    }
    return c;
  }, []);
  const [tableHiddenColumns, setTableHiddenColumns] = useState(props.hideColumns);

  const initialState = useMemo(
    () => ({
      filters: [...includesTypeColumns],
      hiddenColumns: columns
        .filter((col: Column<{}>) => VisibleColumn.includes(col.accessor as string))
        .map((col) => col.accessor) as string[],
      pageIndex: 0,
      pageSize: 10
    }),
    [props.savingsType, props.hideColumns]
  );

  const onHiddenColsChange = (val: any) => {
    setTableHiddenColumns(val);
    setHiddenColumns(val);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
    setHiddenColumns,
    allColumns,
    state: { hiddenColumns, pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      globalFilter: customFilter,
      initialState,
      filterTypes,
      manualPagination: props.manualPagination
    },
    useGlobalFilter,
    useFilters,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useSticky,
    useBlockLayout,
    useResizeColumns,
    (hooks) => {
      if (props.selection) {
        if (props.selection === 'single')
          hooks.allColumns.push((columns: Column[]) => [
            {
              id: 'row-selection-rd',
              accessor: 'Selection',
              disableSortBy: true,
              width: 70,
              maxWidth: 70,
              minWidth: 70,
              Header: '',
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
                const currentState = row.getToggleRowSelectedProps();
                return (
                  <IndeterminateRadio
                    indeterminate={false}
                    checkedAria={currentState.checked}
                    labelAria={`Select row ${row.index + 1}`}
                    onClick={() => {
                      toggleAllRowsSelected(false);
                      toggleRowSelected(row.id, !currentState.checked);
                    }}
                    {...row.getToggleRowSelectedProps()}
                  />
                );
              }
            },
            ...columns
          ]);
        else
          hooks.allColumns.push((columns: Column[]) => [
            {
              id: 'row-selection-chk',
              accessor: 'Selection',
              disableSortBy: true,
              width: 70,
              maxWidth: 70,
              minWidth: 70,
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <IndeterminateCheckbox indeterminate labelAria="Select all rows" {...getToggleAllPageRowsSelectedProps()} />
              ),
              Cell: ({ row }: any) => (
                <IndeterminateCheckbox labelAria={`Select row ${row.index + 1}`} {...row.getToggleRowSelectedProps()} />
              )
            },
            ...columns
          ]);
      } else {
        hooks.allColumns.push((columns) => [...columns]);
      }
    }
  );
  useEffect(() => {
    if (props.handleSelectedRows) props.handleSelectedRows(selectedFlatRows.map((row) => row.original));
  }, [selectedFlatRows]);

  useEffect(() => {
    setTableHiddenColumns(
      columns.filter((col: Column<{}>) => (props.hideColumns || []).includes(col.accessor as string)).map((col) => col.accessor) as string[]
    );
  }, [props.savingsType, props.hideColumns, allColumns]);

  const handleRowClick = (row: any) => {
    row = row.original;
    if (enableClick) {
      let subType = row.savings_type.replace(/\s+/g, '-');
      subType = getSelectedRowType(subType) || subType;
      navigate(`/trekora/${savingsType}${subUrlType(subType)}/${subType}`);
    }
  };
  let headers: LabelKeyObject[] = [];
  allColumns.map((item) => {
    if (!hiddenColumns?.includes(item.id) && item.id !== 'selection' && item.id !== 'edit') {
      headers.push({ label: typeof item.Header === 'string' ? item.Header : '#', key: item.id });
    }
    return item;
  });

  const getFileName = () => {
    if (props.enableClick) return `${props.savingsTypeLabel || props.savingsType}`;
    else return `${props.savingsTypeLabel || props.savingsType} Recommendations`;
  };
  return (
    <>
      <Stack spacing={2}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 0 }} justifyContent="space-between" sx={{ p: 2, pb: 0 }}>
          {props.enableSearch && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="small"
              searchPlaceholder={props.searchPlaceholder}
              handleSearchTable={props.handleSearchTable}
            />
          )}
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 2 }}>
            {VisibleColumn.length > 0 && (
              <HidingSelect
                hiddenColumns={tableHiddenColumns!}
                setHiddenColumns={onHiddenColsChange}
                allColumns={allColumns}
                disable={!(page.length > 0)}
              />
            )}
            {!props.noDownload && <ExportTableData data={data} columns={columns} filename={getFileName()} disable={!(page.length > 0)} />}
          </Stack>
        </Stack>
        <TableWrapper>
          <Table {...getTableProps()}>
            <TableHead sx={{ borderTopWidth: 2, backgroundColor: 'secondary.main' }}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: HeaderGroup<{}>, index: number) => {
                    return (
                      <TableCell
                        {...column.getHeaderProps([{ className: column.className }])}
                        sx={{
                          color: 'white',
                          position: 'sticky !important',
                          borderTopWidth: 2,
                          backgroundColor: 'secondary.main',
                          display: 'flex !important'
                        }}
                        scope="col"
                      >
                        <Stack direction="row" spacing={1.15} alignItems="center">
                          <HeaderSort column={column} sort />
                          <Box {...column.getResizerProps()} sx={{ position: 'absolute', right: -6, opacity: 0, zIndex: 1 }}>
                            <MinusOutlined style={{ transform: 'rotate(90deg)' }} />
                          </Box>
                        </Stack>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>

            {/* striped table -> add class 'striped' */}
            <TableBody {...getTableBodyProps()} className="striped">
              {props.showFilter &&
                headerGroups.map((group: HeaderGroup<{}>) => (
                  <TableRow {...group.getHeaderGroupProps()}>
                    {group.headers.map((column: HeaderGroup) => (
                      <TableCell {...column.getHeaderProps([{ className: column.className }])}>
                        {column.canFilter ? column.render('Filter') : null}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {page.length > 0 ? (
                page.map((row: Row, i: number) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      onClick={async () => {
                        if (props.selection === 'multi') {
                          row.toggleRowSelected();
                        } else if (props.selection === 'single') {
                          await row.toggleRowSelected();
                          const isSelected = row.isSelected;
                          if (isSelected) {
                            selectedFlatRows.forEach((selectedRow) => {
                              if (selectedRow.id !== row.id) {
                                selectedRow.toggleRowSelected(false);
                              }
                            });
                          }
                        }
                        handleRowClick(row);
                      }}
                      sx={{
                        cursor: 'pointer'
                        //  bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit'
                      }}
                    >
                      {row.cells.map((cell: Cell) => {
                        return (
                          <TableCell
                            {...cell.getCellProps([{ className: cell.column.className }])}
                            // style={{ borderRight: '1px solid rgb(240, 240, 240)' }}
                            sx={{ display: 'flex !important', alignItems: 'center' }}
                          >
                            <div className={cell.column.ellipsis ? 'ellipsis' : ''} style={{ display: 'flex', alignItems: 'center' }}>
                              {cell.column.format ? formatValue(cell.column.format, cell.value) : cell.render('Cell')}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <EmptyTable msg="No Data" colSpan={9} />
              )}
            </TableBody>
          </Table>
        </TableWrapper>

        {(props.totalRows || rows.length) > 10 && (
          <Box sx={{ p: 2, py: 0, paddingBottom: 2 }}>
            <TablePagination
              gotoPage={(v) => {
                if (props.manualPagination) {
                  setTablePage((prevState) => ({
                    ...prevState,
                    pageIndex: v
                  }));
                  props.handlePage({ pageNumber: v, pageSize: tablePage.pageSize });
                } else gotoPage(v);
              }}
              rows={props.totalRows || rows.length}
              setPageSize={(v) => {
                if (props.manualPagination) {
                  setTablePage({
                    pageIndex: 0,
                    pageSize: v
                  });
                  props.handlePage({ pageNumber: 0, pageSize: v });
                  setPageSize(v);
                } else setPageSize(v);
              }}
              pageIndex={props.manualPagination ? tablePage.pageIndex : pageIndex}
              pageSize={props.manualPagination ? tablePage.pageSize : pageSize}
            />
          </Box>
        )}
      </Stack>
    </>
  );
});

export interface ITableProps {
  headerData?: {
    title?: any;
    savingsType?: any;
    secondary?: any;
    subheader?: string | undefined;
  };
  columns: any;
  data: any[];
  hideColumns?: any[];
  selection?: 'single' | 'multi' | 'noSelection' | undefined;
  enableClick?: boolean | undefined;
  rowSelection?: any;
  boxShadow?: any;
  bgColor?: any;
  borderTop?: any;
  shadow?: any;
  searchPlaceholder?: string | undefined;
  savingsTypeLabel?: string | undefined;
  onActionClick?: any;
  showFilter?: boolean;
  handleSelectedRows?: Function;
  totalRows?: number;
  manualPagination?: boolean | false;
  handlePage?: Function;
  handleSearchTable?: (value: string) => void;
  selectedRows?: any[];
  rowKey?: string;
  showLockRecords?: boolean;
  noDownload?: boolean;
  shouldRender?: boolean;
  enableSearch?: boolean;
}
const areEqual = (prevProps: any, nextProps: any) => {
  let render = false;
  if (prevProps.data.length === nextProps.data.length) {
    const prevValues = prevProps.data.map((obj: any) => (prevProps.rowKey ? obj[prevProps.rowKey] : Object.values(obj)[0]));
    const nextValues = nextProps.data.map((obj: any) => (nextProps.rowKey ? obj[nextProps.rowKey] : Object.values(obj)[0]));

    const areFirstValuesEqual = JSON.stringify(prevValues) === JSON.stringify(nextValues);
    if (areFirstValuesEqual) {
      render = true;
    } else {
      render = false;
    }
  } else render = false;
  return render;
};

const MemoizedReactTable = memo(ReactTable, areEqual);
const TraverseDataTable = memo((props: ITableProps) => {
  const { columns: originalColumns, data } = props;

  const columns = originalColumns.map((c: any) => {
    const column: any = {
      Header: c.label,
      accessor: c.id,
      isInfo: c.isInfo ? true : false,
      className: '',
      filterMethod: null,
      disableFilters: true,
      disableGroupBy: true,
      disableSortBy: c.disableSortBy || false,
      format: c.format,
      ellipsis: c.ellipsis ? c.ellipsis : false
    };
    if (c.filter === 'fuzzyText') {
      column.disableFilters = false;
      column.dataType = 'text';
      column.filter = 'fuzzyText';
    }
    if (c.filter === 'equals') {
      column.filter = 'equals';
      column.Filter = SliderColumnFilter;
      column.disableFilters = false;
    }
    if (c.filter === 'between') {
      column.filter = 'between';
      column.Filter = NumberRangeColumnFilter;
      column.disableFilters = false;
    }
    if (c.filter === 'includes') {
      column.filter = 'includes';
      column.Filter = SelectColumnFilter;
      column.dataType = 'select';
      column.disableFilters = false;
    }
    if (c.maxWidth) {
      column.width = c.width;
      column.maxWidth = c.maxWidth;
      column.minWidth = c.minWidth;
    }
    // if (c.isSort === false) column.disableSortBy = true;
    return column;
  });

  data?.forEach((d: any) => {
    columns.forEach((column: any) => {
      if (column.format) {
        d[column.accessor + '_formatted'] = formatValue(column.format, d[column.accessor]);
      }
    });
  });

  const tblProps = { ...props };
  tblProps.columns = columns;
  tblProps.data = data;

  if (props.enableSearch === undefined) tblProps.enableSearch = true;
  return (
    <MainCard
      title={props.headerData?.title || ''}
      secondary={props.headerData?.secondary}
      subheader={props.headerData?.subheader || ''}
      content={false}
    >
      <ScrollX>
        <MemoizedReactTable {...tblProps} />
      </ScrollX>
    </MainCard>
  );
});

export default TraverseDataTable;
