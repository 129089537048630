export enum StatusEnum {
  CHILD_ACCOUNT = 'Child Account',

  PAYER_ACCOUNT = 'Payer Account',

  NOT_PAYER = 'NOT_PAYER_ACCOUNT',
  NOT_PAYER_TEXT = 'AWS Account is not a Payer',
  NOT_PAYER_ACTION = "Redo 'Add Account' with AWS Payer Account ID",

  INVALID_S3 = 'INVALID_S3',
  INVALID_S3_TEXT = 'Invalid S3 Bucket',
  INVALID_S3_ACTION = 'Update the S3 Bucket Configured for CUR',

  OUTSIDE_ORG = 'OUTSIDE_ORGANIZATION',
  OUTSIDE_ORG_AWS_ACC = 'AWS Account Not Part of AWS Organizations',
  OUTSIDE_ORG_TEXT = 'AWS Account Not Part of Organizations',
  OUTSIDE_ORG_ACTION = "Add Account to AWS Organizations and Redo 'Add Account'",

  SETUP_IN_PROGRESS = 'SETUP_IN_PROGRESS',
  SETUP_IN_PROGRESS_TEXT = 'Setup In Progress',

  SETUP_COMPLETED = 'SETUP_COMPLETED',
  SETUP_COMPLETED_TEXT = 'Setup Completed',

  OTHER_PAYER_EXCEPTION = 'OTHER',
  ERROR = 'ERROR',
  SETUP_INCOMPLETE_TEXT = 'Setup Incomplete',
  INCOMPLETE_ACTION = 'Redo Add Account/Contact Support',

  NOT_CONFIGURED = 'STACK_NOT_CONFIGURED',
  NOT_CONFIGURED_TEXT = 'CloudFormation Stack Not Properly Configured',

  DELETION_IN_PROGRESS = 'DELETION_IN_PROGRESS',
  DELETION_IN_PROGRESS_TEXT = 'Deletion In Progress',
  DELETED = 'DELETED',

  NO_ACTION = 'No Action Required',
  LOADING = 'LOADING',
  EXCEPTION = 'EXCEPTION',
  OTHER = 'OTHER'
}
