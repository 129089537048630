import { Button, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, alpha, useTheme } from '@mui/material';
import MainCard from 'components/MainCard';
import { Cell, Column, HeaderGroup, useTable } from 'react-table';
import { CircularProgressWithLabel } from 'shared';
import { NumberRangeColumnFilter, SelectColumnFilter, SliderColumnFilter } from 'utils/react-table';

interface FileProps {
  handleUploadClick?: () => void;
  handleCancel?: () => void;
  showUploadFileActions?: boolean;
  fileUploadProgress?: number;
  uploadResultMessage?: string;
  showProgressBar?: boolean;
}
interface ReactSubTableProps {
  columns: Column[];
  data: [];
  loading: boolean;
  fileProperties: FileProps;
}

function ReactSubTable(props: ReactSubTableProps) {
  const { columns, data, loading } = props;
  const { handleUploadClick, handleCancel, showUploadFileActions, fileUploadProgress, uploadResultMessage, showProgressBar } =
    props.fileProperties || {};
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data
    },
    (hooks) => {
      if (showUploadFileActions) {
        hooks.allColumns.push((columns) => [
          ...columns,
          {
            accessor: 'actions',
            id: 'actions',
            Header: 'Actions',
            Cell: () => {
              return (
                <>
                  <Stack direction="row" alignItems="center" justifyContent="left" spacing={1}>
                    {showProgressBar ? (
                      <CircularProgressWithLabel value={fileUploadProgress} message={uploadResultMessage} />
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (handleUploadClick) {
                            handleUploadClick();
                          }
                        }}
                      >
                        Upload Documents
                      </Button>
                    )}

                    <Button
                      variant="outlined"
                      onClick={() => {
                        if (handleCancel) handleCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </>
              );
            }
          }
        ]);
      }
    }
  );

  if (loading) {
    return (
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {[0, 1, 2].map((item: number) => (
            <TableRow key={item}>
              {[0, 1, 2, 3, 4, 5].map((col: number) => (
                <TableCell key={col}>
                  <Skeleton animation="wave" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: HeaderGroup) => (
              <TableCell {...column.getHeaderProps([{ className: column.className }])}>{column.render('Header')}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell: Cell) => (
                <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
export function SubRowAsync({ row, rowProps, columns, data }: any) {
  const theme = useTheme();
  const backColor = alpha(theme.palette.primary.lighter, 0.1);

  return (
    <TableRow sx={{ bgcolor: backColor, '&:hover': { bgcolor: `${backColor} !important` } }}>
      <TableCell colSpan={18} sx={{ p: 2.5 }}>
        <SubRow columns={columns} data={data} />
      </TableCell>
    </TableRow>
  );
}

export function SubRow(props: any) {
  const { columns: originalColumns, data, ...restProps } = props;
  const columns = originalColumns.map((c: any) => {
    const column: any = {
      Header: c.label,
      accessor: c.id,
      isInfo: c.isInfo ? true : false,
      className: '',
      filterMethod: null,
      disableFilters: true,
      disableGroupBy: true,
      disableSortBy: c.disableSortBy || false,
      format: c.format
    };
    if (c.filter === 'fuzzyText') {
      column.disableFilters = false;
      column.dataType = 'text';
      column.filter = 'fuzzyText';
    }
    if (c.filter === 'equals') {
      column.filter = 'equals';
      column.Filter = SliderColumnFilter;
      column.disableFilters = false;
    }
    if (c.filter === 'between') {
      column.filter = 'between';
      column.Filter = NumberRangeColumnFilter;
      column.disableFilters = false;
    }
    if (c.filter === 'includes') {
      column.filter = 'includes';
      column.Filter = SelectColumnFilter;
      column.dataType = 'select';
      column.disableFilters = false;
    }
    // if (c.isSort === false) column.disableSortBy = true;
    return column;
  });
  return (
    <MainCard content={false} sx={{ ml: { xs: 2.5, sm: 5, md: 6, lg: 10, xl: 12 } }}>
      <ReactSubTable columns={columns} data={data || []} loading={data.length > 0 ? false : true} {...restProps} />
    </MainCard>
  );
}
