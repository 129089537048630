import { Grid, InputAdornment, TextField, Stack, Button } from '@mui/material';
//import axios from 'axios';
import Loading from 'components/Loading';
import SelectWithSearch from 'components/SelectWithSearch';
import { useEffect, useState } from 'react';
import useAxios from 'utils/axios';
import { REGEX } from 'utils/constants';
import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { URLS } from 'constants/endpoints';
import countries from 'constants/countries';
import { useFormik } from 'formik';
import { NOTIFY } from 'shared';
import { useAuth, useUserData } from 'hooks';
import { limitTextFieldLength } from 'utils/formikUtility';
import { capitalizeEachWord } from 'utils/capitalizeEachWord';

const phoneUtil = PhoneNumberUtil.getInstance();

const DEFAULT_VALUE = {
  last_name: '',
  first_name: '',
  organization: '',
  email: '',
  aws_account_id: '',
  aws_s3_bucket_name: '',
  id: null,
  user_id: '',
  job_title: '',
  mobile: '',
  is_aws_account_setup_completed: false,
  aws_account_name: '',
  aws_account_status: ''
};

export const ProfileComponent = () => {
  const axiosServices = useAxios();
  const [loading, setLoading] = useState(false);
  const { addUserData } = useUserData();
  const [focused, setFocused] = useState(false);
  const [userData, setUserData] = useState(DEFAULT_VALUE);
  const { updateAttributes } = useAuth();
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    setLoading(true);
    axiosServices
      .get(URLS.USER.GET_USER)
      .then((res: any) => {
        setUserData(res.data.data);
        addUserData('userData', res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    formik.resetForm();
    formik.setFieldValue('phoneCode', userData.mobile.split(' ')[0]);
  };

  const formik = useFormik({
    initialValues: {
      first_name: userData.first_name,
      last_name: userData.last_name,
      job_title: userData.job_title,
      mobile: userData.mobile.split(' ')[1] || '',
      email: userData.email,
      organization: userData.organization,
      phoneCode: userData.mobile.split(' ')[0] || ''
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required('First Name is required').matches(REGEX.ALPHABET_CHECK.EXPRESSION, REGEX.ALPHABET_CHECK.MESSAGE),
      last_name: Yup.string().required('Last Name is required').matches(REGEX.ALPHABET_CHECK.EXPRESSION, REGEX.ALPHABET_CHECK.MESSAGE),
      job_title: Yup.string()
        .required('Job Title is required')
        .matches(REGEX.ALPHANUMERIC_CHECK.EXPRESSION, REGEX.ALPHANUMERIC_CHECK.MESSAGE),
      organization: Yup.string()
        .required('Organization is required')
        .matches(REGEX.ALPHANUMERIC_CHECK.EXPRESSION, REGEX.ALPHANUMERIC_CHECK.MESSAGE),
      mobile: Yup.string()
        .required('Phone number is required')
        .test('code', 'Please select Phone code', function () {
          if (this.parent.phoneCode === '') return false;
          return true;
        })
        .test('invalid-number', ' Only numerical characters allowed.', function (value) {
          if (/^[0-9]*$/.test(value!)) {
            return true;
          } else {
            return false;
          }
        })
        .test('invalid-number', 'Mobile Number is not valid', function (value) {
          try {
            const parsedPhoneNumber = phoneUtil.parse(
              this.parent.phoneCode + value,
              phoneUtil.getRegionCodeForCountryCode(Number(this.parent.phoneCode))
            );
            if (phoneUtil.isValidNumber(parsedPhoneNumber)) {
              return true;
            } else {
              return false;
            }
          } catch (err) {
            return false;
          }
        })
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      const { phoneCode, ...rest } = values;
      axiosServices
        .put(URLS.USER.UPDATE_USER, { ...rest, mobile: phoneCode + ' ' + rest.mobile })
        .then((res) => {
          getUserData();
          NOTIFY.Success('Profile updated successfully');
          updateAttributes(`${formik.values.first_name} ${formik.values.last_name}`);
        })
        .catch((err: Error) => {
          NOTIFY.Error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  return (
    <Stack spacing={2} sx={{ width: { xs: '100%', md: '65%' } }}>
      <Loading show={loading} />
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              id="firstname-profile"
              value={formik.values.first_name}
              name="first_name"
              onBlur={formik.handleBlur}
              onChange={(e: any) => {
                const capitalizedValue = capitalizeEachWord(e.target.value);
                limitTextFieldLength(capitalizedValue, 'first_name', formik.setFieldError, formik.setFieldValue);
              }}
              label="First Name*"
              fullWidth
              error={Boolean(formik.errors.first_name)}
              variant="outlined"
              helperText={formik.errors.first_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="lastname-profile"
              value={formik.values.last_name}
              name="last_name"
              onBlur={formik.handleBlur}
              onChange={(e: any) => {
                const capitalizedValue = capitalizeEachWord(e.target.value);
                limitTextFieldLength(capitalizedValue, 'last_name', formik.setFieldError, formik.setFieldValue);
              }}
              label="Last Name*"
              fullWidth
              error={Boolean(formik.errors.last_name)}
              variant="outlined"
              helperText={formik.errors.last_name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="jobTitle-profile"
              value={formik.values.job_title}
              name="job_title"
              onBlur={formik.handleBlur}
              onChange={(e: any) => {
                const capitalizedValue = capitalizeEachWord(e.target.value);
                limitTextFieldLength(capitalizedValue, 'job_title', formik.setFieldError, formik.setFieldValue);
              }}
              label="Job Title*"
              fullWidth
              error={Boolean(formik.errors.job_title)}
              variant="outlined"
              helperText={formik.errors.job_title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="company-profile"
              value={formik.values.organization}
              name="organization"
              onBlur={formik.handleBlur}
              onChange={(e: any) => {
                const capitalizedValue = capitalizeEachWord(e.target.value);
                limitTextFieldLength(capitalizedValue, 'organization', formik.setFieldError, formik.setFieldValue);
              }}
              label="Organization*"
              fullWidth
              error={Boolean(formik.errors.organization)}
              variant="outlined"
              helperText={formik.errors.organization}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="phoneNumber-profile"
              name="mobile"
              fullWidth
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={(e) => {
                formik.handleBlur(e);
                setFocused(false);
              }}
              variant="outlined"
              label="Phone Number*"
              helperText={formik.touched.mobile && formik.errors.mobile}
              error={Boolean(formik.touched.mobile && formik.errors.mobile)}
              InputLabelProps={{
                shrink: focused || formik.values.mobile?.length > 0,
                style: { marginLeft: 100 }
              }}
              onFocus={() => setFocused(true)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: '0px' }}>
                    <SelectWithSearch
                      options={[...countries]}
                      value={formik.values.phoneCode}
                      onValueChange={(code) => formik.setFieldValue('phoneCode', code)}
                    />
                  </InputAdornment>
                )
              }}
              sx={{
                '& .MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root': {
                  className: 'inputBase'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email-profile"
              type="email"
              value={formik.values.email}
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              label="Email Address*"
              fullWidth
              error={Boolean(formik.touched.email && formik.errors.email)}
              variant="outlined"
              helperText={formik.touched.email && formik.errors.email}
              disabled={true}
              sx={{
                '& .Mui-disabled': {
                  color: '#595959',
                  '-webkit-text-fill-color': '#595959'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
              <Button variant="outlined" onClick={handleCancel} disabled={!formik.dirty}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" disabled={!formik.dirty}>
                Update Profile
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Stack>
  );
};
