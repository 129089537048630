import { URLS } from 'constants/endpoints';
import useAxios from 'utils/axios';

export const useImages = () => {
  const axiosServices = useAxios();
  const getImages = async (prodKey: string, nonProdKey: string) => {
    return await axiosServices
      .get(`${URLS.SAP.GET_IMAGES}?img1_filename=${prodKey}&img2_filename=${nonProdKey}`)
      .then(async (res) => {
        const prodUrl = await fetchImageAsBlob(res.data.data.image1);
        const nonProdUrl = await fetchImageAsBlob(res.data.data.image2);
        return { prodUrl, nonProdUrl };
      })
      .catch((err) => {
        console.log(err);
        return { prodUrl: '', nonProdUrl: '' };
      });
  };
  const fetchImageAsBlob = async (base64Data: string) => {
    const response = await fetch(`data:image/jpeg;base64,${base64Data}`);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };
  return { getImages, fetchImageAsBlob };
};
