import jsPDF from 'jspdf';

const useGeneratePdf = () => {
  const generatePDF = (keys: string[], headers: string[]) => {
    var doc = new jsPDF('p', 'pt');
    var totalImages = keys.length;
    var loadedImages = 0;
    var currentY = 10;

    keys.forEach((key, index) => {
      var img = new Image();
      img.src = key;

      // Wait for the Image to load
      img.onload = function () {
        var pageWidth = doc.internal.pageSize.getWidth();

        var width = pageWidth - 20;
        var height = (width / img.width) * img.height;

        doc.setFontSize(14);
        doc.text(headers[index], 10, currentY + 10);
        doc.addImage(img, 'PNG', 10, currentY + 20, width, height);

        currentY += height + 40;

        loadedImages++;
        if (loadedImages === totalImages) {
          doc.save('SAP Architecture Images.pdf');
        }
      };
    });
  };

  return { generatePDF };
};

export default useGeneratePdf;
