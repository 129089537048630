import { FormikErrors, FormikTouched, getIn } from 'formik';

export const hasFormikError = <T>(field: keyof T, error: FormikErrors<any>, touched: FormikTouched<any>): boolean => {
  const fieldTouched = touched[field as string];
  const fieldError = getIn(error, field as string);
  return Boolean(fieldTouched) && Boolean(fieldError);
};

export const getFormikErrorMessage = <T>(field: keyof T, error: FormikErrors<any>, touched: FormikTouched<any>): string | undefined => {
  if (hasFormikError<T>(field, error, touched)) {
    return getIn(error, field as string);
  }
};

export const limitTextFieldLength = (
  value: string,
  fieldName: string,
  setFieldError: (field: string, errorMsg: string) => void,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>,
  charLen: number = 50
): void => {
  if (value.length <= charLen) {
    setFieldValue(fieldName, value);
  } else {
    setFieldError(fieldName, `Only ${charLen} characters allowed`);
  }
};
