import { BarChartOutlined, UserOutlined, LineChartOutlined, FileTextOutlined, DashboardOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  BarChartOutlined,
  UserOutlined,
  LineChartOutlined,
  FileTextOutlined,
  DashboardOutlined
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //
const dashboard: NavItemType = {
  id: 'dashboard-group',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/trekora/dashboard',
      icon: icons.DashboardOutlined
    }
  ]
};

const commercialSavings: NavItemType = {
  id: 'commercialSavings-group',
  type: 'group',
  children: [
    {
      id: 'Commercial Savings',
      title: <FormattedMessage id="commercialSavings" />,
      type: 'collapse',
      icon: icons.FileTextOutlined,
      url: '/trekora/commercialSavings',
      children: [
        {
          id: 'Reserved Instances',
          title: <FormattedMessage id="reservedInstances" />,
          url: '/trekora/commercialSavings/RI',
          type: 'collapse',
          children: [
            {
              id: 'Amazon ElastiCache',
              title: <FormattedMessage id="elastiCache" />,
              type: 'item',
              url: '/trekora/commercialSavings/RI/Amazon-ElastiCache',
              breadcrumbs: false
            },
            {
              id: 'Amazon Opensearch Service',
              title: <FormattedMessage id="openSearch" />,
              type: 'item',
              url: '/trekora/commercialSavings/RI/Amazon-OpenSearch',
              breadcrumbs: false
            },
            {
              id: 'Amazon Redshift',
              title: <FormattedMessage id="redshift" />,
              type: 'item',
              url: '/trekora/commercialSavings/RI/Amazon-Redshift',
              breadcrumbs: false
            },
            {
              id: 'Amazon Relational Database Service',
              title: <FormattedMessage id="rds" />,
              type: 'item',
              url: '/trekora/commercialSavings/RI/Amazon-RDS',
              breadcrumbs: false
            }
          ]
        },
        {
          id: 'Savings Plan',
          title: <FormattedMessage id="savingsPlan" />,
          url: '/trekora/commercialSavings/Savings-Plan',
          type: 'collapse',
          children: [
            {
              id: 'COMPUTE_SP',
              title: <FormattedMessage id="compute" />,
              type: 'item',
              url: '/trekora/commercialSavings/Savings-Plan/Compute',
              breadcrumbs: false
            },
            {
              id: 'EC2_INSTANCE_SP',
              title: <FormattedMessage id="ec2" />,
              type: 'item',
              url: '/trekora/commercialSavings/Savings-Plan/EC2',
              breadcrumbs: false
            },
            {
              id: 'SAGEMAKER_SP',
              title: <FormattedMessage id="sageMaker" />,
              type: 'item',
              url: '/trekora/commercialSavings/Savings-Plan/SageMaker',
              breadcrumbs: false
            }
          ]
        }
      ]
    }
  ]
};

const architecturalSavings: NavItemType = {
  id: 'architecturalSavings-group',
  type: 'group',
  children: [
    {
      id: 'Architectural Savings',
      title: <FormattedMessage id="architecturalSavings" />,
      type: 'collapse',
      url: '/trekora/architecturalSavings',
      icon: icons.LineChartOutlined,
      children: [
        {
          id: 'AMD Migration',
          title: <FormattedMessage id="amd" />,
          type: 'item',
          url: '/trekora/architecturalSavings/AMD-Migration',
          breadcrumbs: false
        },
        {
          id: 'Graviton Migration',
          title: <FormattedMessage id="graviton" />,
          type: 'item',
          url: '/trekora/architecturalSavings/Graviton-Migration',
          breadcrumbs: false
        },
        {
          id: 'EBS Modernization',
          title: <FormattedMessage id="ebs" />,
          type: 'item',
          url: '/trekora/architecturalSavings/EBS-Modernization',
          breadcrumbs: false
        },
        {
          id: 'S3 Optimization',
          title: <FormattedMessage id="s3Optimization" />,
          url: '/trekora/architecturalSavings/S3-Optimization',
          type: 'collapse',
          children: [
            {
              id: 'API',
              title: <FormattedMessage id="api" />,
              type: 'item',
              url: '/trekora/architecturalSavings/S3-Optimization/API',
              breadcrumbs: false
            },
            {
              id: 'Storage',
              title: <FormattedMessage id="storage" />,
              type: 'item',
              url: '/trekora/architecturalSavings/S3-Optimization/Storage',
              breadcrumbs: false
            }
          ]
        },
        {
          id: 'Data Transfer',
          title: <FormattedMessage id="dataTransfer" />,
          url: '/trekora/architecturalSavings/Data-Transfer',
          type: 'collapse',
          children: [
            {
              id: 'DTO',
              title: <FormattedMessage id="dto-short" />,
              type: 'item',
              url: '/trekora/architecturalSavings/Data-Transfer/Out-To-Internet',
              breadcrumbs: false
            },
            {
              id: 'DTAZ',
              title: <FormattedMessage id="dtaz-short" />,
              type: 'item',
              url: '/trekora/architecturalSavings/Data-Transfer/Inter-AZ',
              breadcrumbs: false
            },
            {
              id: 'DTIR',
              title: <FormattedMessage id="dtir-short" />,
              type: 'item',
              url: '/trekora/architecturalSavings/Data-Transfer/Inter-Region',
              breadcrumbs: false
            }
          ]
        },
        {
          id: 'NAT Gateway',
          title: <FormattedMessage id="nat" />,
          url: '/trekora/architecturalSavings/NAT-Gateway',
          type: 'collapse',
          children: [
            {
              id: 'NGW Hours',
              title: <FormattedMessage id="hours" />,
              type: 'item',
              url: '/trekora/architecturalSavings/NAT-Gateway/Hours',
              breadcrumbs: false
            },
            {
              id: 'NGW Bytes',
              title: <FormattedMessage id="bytes" />,
              type: 'item',
              url: '/trekora/architecturalSavings/NAT-Gateway/Bytes',
              breadcrumbs: false
            }
          ]
        }
      ]
    }
  ]
};

const operationalSavings: NavItemType = {
  id: 'operationalSavings-group',
  type: 'group',
  children: [
    {
      id: 'Operational Savings',
      title: <FormattedMessage id="operationalSavings" />,
      type: 'collapse',
      url: '/trekora/operationalSavings',
      icon: icons.BarChartOutlined,
      children: [
        {
          id: 'Low Utilization Amazon EC2 Instances',
          title: 'Low Utilization Amazon EC2 Instances',
          type: 'item',
          url: '/trekora/operationalSavings/Underutilized-EC2',
          breadcrumbs: true
        },
        {
          id: 'Underutilized Amazon EBS Volumes',
          title: 'Underutilized Amazon EBS Volumes',
          type: 'item',
          url: '/trekora/operationalSavings/Underutilized-EBS',
          breadcrumbs: true
        },
        {
          id: 'Idle Load Balancers',
          title: 'Idle Load Balancers',
          type: 'item',
          url: '/trekora/operationalSavings/Underutilized-ELB',
          breadcrumbs: true
        }
      ]
    }
  ]
};

// const operationalSavings: NavItemType = {
//   id: 'operationalSavings-group',
//   type: 'group',
//   children: [
//     {
//       id: 'operationalSavings',
//       title: 'Operational Savings',
//       type: 'item',
//       url: '/trekora/operationalSavings',
//       icon: icons.BarChartOutlined
//     }
//   ]
// };
export { dashboard, commercialSavings, architecturalSavings, operationalSavings };
