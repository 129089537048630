import { Typography, Stack } from '@mui/material';
import { ActionModal } from 'shared';

export const AmdActionModal = (props: any) => {
  const CommandText = (
    <Stack spacing={1}>
      <Typography>
        Use the following instructions to change the instance type of an EBS-backed instance if the instance type that you need is
        compatible with the instance's current configuration.
      </Typography>
      <Typography>To change the instance type of an Amazon EBS-backed instance:</Typography>
      <ol>
        <li>
          (Optional) If the new instance type requires drivers that are not installed on the existing instance, you must connect to your
          instance and install the drivers first. For more information, see{' '}
          <a href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/resize-limitations.html" target="_blank" rel="noopener noreferrer">
            Compatibility for changing the instance type
          </a>
          .
        </li>
        <li>
          Open the Amazon EC2 console at{' '}
          <a href="https://console.aws.amazon.com/ec2/" target="_blank" rel="noopener noreferrer">
            https://console.aws.amazon.com/ec2/
          </a>
          .
        </li>
        <li>
          In the navigation pane, choose <b>Instance</b> that you want to modify.
        </li>
        <li>
          Select the instance and choose <b>Instance state, Stop instance</b>. When prompted for confirmation, choose <b>Stop</b>. It can
          take a few minutes for the instance to stop.
        </li>
        <li>
          With the instance still selected, choose <b>Actions, Instance settings, Change instance type</b>. This option is grayed out if the
          instance state is not stopped.
        </li>
        <li>
          On the <b>Change instance type</b> page, do the following:
        </li>
        <li>
          For <b>Instance type</b>, select the instance type that you want. If the instance type is not in the list, then it's not
          compatible with the configuration of your instance. Instead, use the following instructions:{' '}
          <a
            href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-instance-resize.html#migrate-instance-configuration"
            target="_blank"
            rel="noopener noreferrer"
          >
            Change the instance type by launching a new instance
          </a>
          .
        </li>
        <li>
          (Optional) If the instance type that you selected supports EBS optimization, select <b>EBS-optimized</b> to enable EBS
          optimization, or deselect <b>EBS-optimized</b> to disable EBS optimization. If the instance type that you selected is EBS
          optimized by default, <b>EBS-optimized</b> is selected and you can't deselect it.
        </li>
        <li>Choose Apply to accept the new settings.</li>
        <li>
          To start the instance, select the instance and choose <b>Instance state, Start instance</b>. It can take a few minutes for the
          instance to enter the running state. If your instance won't start, see{' '}
          <a
            href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/troubleshoot-change-instance-type.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Troubleshoot changing the instance type
          </a>
          .
        </li>
        <li>
          <a href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-instance-resize.html" target="_blank" rel="noopener noreferrer">
            https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-instance-resize.html
          </a>
          .
        </li>
      </ol>
    </Stack>
  );
  const CLIText = (
    <Stack spacing={1}>
      <Typography>
        To change instance type using CLI, please refer to the following AWS User Guide:{' '}
        <a
          href="https://docs.aws.amazon.com/cli/latest/userguide/cli-services-ec2-instance-type-script.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://docs.aws.amazon.com/cli/latest/userguide/cli-services-ec2-instance-type-script.html
        </a>
        .
      </Typography>
    </Stack>
  );
  return <ActionModal CommandText={CommandText} CLIText={CLIText} open={props.open} onClose={props.handleClose} title={props.title} />;
};
