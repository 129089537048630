import { URLS } from 'constants/endpoints';
import { serviceFilterNamesToAPI } from 'constants/response';
import { SavingsData } from 'constants/types';

//To display header name in each page
export const getSavingsName = (type: string | undefined): string => {
  switch (type?.toLowerCase()) {
    case 'commercialsavings':
      return 'Commercial Savings';
    case 'ri':
      return 'Reserved Instances';
    case 'savings-plan':
      return 'Savings Plan';
    case 'amazon-rds':
      return 'Amazon Relational Database Service';
    case 'amazon-elasticache':
      return 'Amazon ElastiCache';
    case 'amazon-opensearch':
    case 'amazon-elasticsearch':
      return 'Amazon OpenSearch';
    case 'amazon-redshift':
      return 'Amazon Redshift';
    case 'compute':
      return 'Compute';
    case 'ec2':
      return 'EC2';
    case 'sagemaker':
      return 'SageMaker';
    case 'data-transfer':
      return 'Data Transfer';
    case 'nat-gateway':
      return 'NAT Gateway';
    case 's3-optimization':
      return 'S3 Optimization';
    case 'architecturalsavings':
      return 'Architectural Savings';
    case 'amd-migration':
      return 'AMD Migration';
    case 'ebs-modernization':
      return 'EBS Modernization';
    case 'out-to-internet':
      return 'Data Transfer Out';
    case 'inter-az':
      return 'Data Transfer Inter Availability Zone';
    case 'inter-region':
      return 'Data Transfer Inter Region';
    case 'hours':
      return 'NAT Gateway Hours';
    case 'bytes':
      return 'NAT Gateway Bytes';
    case 'api':
      return 'API';
    case 'storage':
      return 'Storage';
    case 'graviton-migration':
      return 'Graviton Migration';
    case 'underutilized-ec2':
      return 'Under-utilized EC2 Instances';
    case 'underutilized-ebs':
      return 'Under-utilized EBS Volumes';
    case 'underutilized-elb':
      return 'Under-utilized ELB';
    default:
      return '';
  }
};

export const getTableNames = (name: string): string => {
  switch (name?.toLowerCase()) {
    case 'reserved_instances':
      return 'Reserved Instances';
    case 'savings_plan':
      return 'Savings Plan';
    case 'compute_sp':
      return 'Compute';
    case 'ec2_instance_sp':
      return 'EC2';
    case 'sagemaker_sp':
      return 'SageMaker';
    case 'amazon_elastic_cache_service':
    case 'elasticache_savings':
      return 'Amazon ElastiCache';
    case 'amazon_opensearch_service':
    case 'opensearch_savings':
      return 'Amazon OpenSearch';
    case 'amazon_redshift_service':
    case 'redshift_savings':
      return 'Amazon Redshift';
    case 'amazon_relational_database_service':
      return 'Amazon Relational Database Service';
    case 'rds_savings':
      return 'Amazon RDS';
    case 'amd':
      return 'AMD Migration';
    case 'ebs_modernisation':
    case 'ebs':
      return 'EBS Modernization';
    case 'graviton':
      return 'Graviton Migration';
    case 'ngw_gateway':
      return 'NAT Gateway';
    case 'data_transfer':
      return 'Data Transfer';
    case 's3_optimization':
      return 'S3 Optimization';
    case 'nat_gateway_bytes':
    case 'ngw_bytes':
      return 'NAT Gateway Bytes';
    case 'nat_gateway_hours':
    case 'ngw_hours':
      return 'NAT Gateway Hours';
    case 'dto':
      return 'Out To Internet';
    case 'dtir':
      return 'Inter Region';
    case 'dtaz':
      return 'Inter AZ';
    case 'api':
      return 'API';
    case 'storage':
      return 'Storage';
    case 'idle_load_balancers':
      return 'Idle Load Balancers';
    case 'low_utilization_amazon_ec2_instances':
      return 'Low Utilization Amazon EC2 Instances';
    case 'underutilized_amazon_ebs_volumes':
      return 'Underutilized Amazon EBS Volumes';
    default:
      return '';
  }
};
export const getSavingsData = (type: string | undefined): SavingsData => {
  let serviceFilter = '';
  let headerUrl = '';
  let graphUrl = '';
  let recommendationsUrl = '';
  let planType = {};

  switch (type?.toLowerCase()) {
    case 'ri':
      headerUrl = URLS.COMMERCIAl.RI_HEADER;
      graphUrl = URLS.COMMERCIAl.RI_GRAPH_DATA;
      break;
    case 'amazon-rds':
      serviceFilter = serviceFilterNamesToAPI['rds'];
      recommendationsUrl = URLS.COMMERCIAl.RDS_RECOMMENDATIONS;
      planType = { ...planType, service: serviceFilterNamesToAPI['rds'] };
      break;
    case 'amazon-elasticache':
      serviceFilter = serviceFilterNamesToAPI['elasticache'];
      recommendationsUrl = URLS.COMMERCIAl.ELASTICACHE_RECOMMENDATIONS;
      planType = { ...planType, service: serviceFilterNamesToAPI['elasticache'] };
      break;
    case 'amazon-opensearch':
      serviceFilter = serviceFilterNamesToAPI['opensearch'];
      recommendationsUrl = URLS.COMMERCIAl.OS_RECOMMENDATIONS;
      planType = { ...planType, service: serviceFilterNamesToAPI['opensearch'] };
      break;
    case 'amazon-redshift':
      serviceFilter = serviceFilterNamesToAPI['redshift'];
      recommendationsUrl = URLS.COMMERCIAl.RS_RECOMMENDATIONS;
      planType = { ...planType, service: serviceFilterNamesToAPI['redshift'] };
      break;

    case 'savings-plan':
      graphUrl = URLS.COMMERCIAl.SP_GRAPH_DATA;
      headerUrl = URLS.COMMERCIAl.SP_HEADER;
      break;
    case 'compute':
      serviceFilter = serviceFilterNamesToAPI['compute'];
      recommendationsUrl = URLS.COMMERCIAl.SP_RECOMMENDATIONS;
      planType = { ...planType, savings_plan: serviceFilterNamesToAPI['compute'] };
      break;
    case 'ec2':
      serviceFilter = serviceFilterNamesToAPI['ec2'];
      recommendationsUrl = URLS.COMMERCIAl.SP_RECOMMENDATIONS;
      planType = { ...planType, savings_plan: serviceFilterNamesToAPI['ec2'] };
      break;
    case 'sagemaker':
      serviceFilter = serviceFilterNamesToAPI['sageMaker'];
      recommendationsUrl = URLS.COMMERCIAl.SP_RECOMMENDATIONS;
      planType = { ...planType, savings_plan: serviceFilterNamesToAPI['sageMaker'] };
      break;

    case 'amd-migration':
      serviceFilter = serviceFilterNamesToAPI['amd'];
      graphUrl = URLS.ARCHITECTURAL.AMD_GRAVITON_EBS_GRAPH_DATA;
      recommendationsUrl = URLS.ARCHITECTURAL.AMD_RECOMMENDATIONS;
      planType = { ...planType, savings_type: serviceFilterNamesToAPI['amd'] };
      break;
    case 'ebs-modernization':
      serviceFilter = serviceFilterNamesToAPI['ebs'];
      graphUrl = URLS.ARCHITECTURAL.AMD_GRAVITON_EBS_GRAPH_DATA;
      recommendationsUrl = URLS.ARCHITECTURAL.EBS_RECOMMENDATIONS;
      planType = { ...planType, savings_type: serviceFilterNamesToAPI['ebs'] };

      break;
    case 'graviton-migration':
      serviceFilter = serviceFilterNamesToAPI['graviton'];
      graphUrl = URLS.ARCHITECTURAL.AMD_GRAVITON_EBS_GRAPH_DATA;
      recommendationsUrl = URLS.ARCHITECTURAL.GRAVITON_RECOMMENDATIONS;
      planType = { ...planType, savings_type: serviceFilterNamesToAPI['graviton'] };
      break;

    case 'data-transfer':
      headerUrl = URLS.ARCHITECTURAL.DT_HEADER;
      graphUrl = URLS.ARCHITECTURAL.DT_GRAPH_DATA;
      break;
    case 'out-to-internet':
      serviceFilter = serviceFilterNamesToAPI['dto'];
      graphUrl = URLS.ARCHITECTURAL.DT_TYPE_GRAPH_DATA;
      recommendationsUrl = URLS.ARCHITECTURAL.DT_RECOMMENDATIONS;
      planType = { ...planType, dt_plan_type: serviceFilterNamesToAPI['dto'] };

      break;
    case 'inter-az':
      serviceFilter = serviceFilterNamesToAPI['dtaz'];
      graphUrl = URLS.ARCHITECTURAL.DT_TYPE_GRAPH_DATA;
      recommendationsUrl = URLS.ARCHITECTURAL.DT_RECOMMENDATIONS;
      planType = { ...planType, dt_plan_type: serviceFilterNamesToAPI['dtaz'] };

      break;
    case 'inter-region':
      serviceFilter = serviceFilterNamesToAPI['dtir'];
      graphUrl = URLS.ARCHITECTURAL.DT_TYPE_GRAPH_DATA;
      recommendationsUrl = URLS.ARCHITECTURAL.DT_RECOMMENDATIONS;
      planType = { ...planType, dt_plan_type: serviceFilterNamesToAPI['dtir'] };
      break;

    case 'nat-gateway':
      headerUrl = URLS.ARCHITECTURAL.NGW_HEADER;
      graphUrl = URLS.ARCHITECTURAL.NGW_GRAPH_DATA;
      break;
    case 'hours':
      serviceFilter = serviceFilterNamesToAPI['natHours'];
      graphUrl = URLS.ARCHITECTURAL.NGW_TYPE_GRAPH_DATA;
      recommendationsUrl = URLS.ARCHITECTURAL.NGW_RECOMMENDATIONS;
      planType = { ...planType, nat_gateway_type: serviceFilterNamesToAPI['natHours'] };
      break;
    case 'bytes':
      serviceFilter = serviceFilterNamesToAPI['natBytes'];
      graphUrl = URLS.ARCHITECTURAL.NGW_TYPE_GRAPH_DATA;
      recommendationsUrl = URLS.ARCHITECTURAL.NGW_RECOMMENDATIONS;
      planType = { ...planType, nat_gateway_type: serviceFilterNamesToAPI['natBytes'] };
      break;

    case 's3-optimization':
      headerUrl = URLS.ARCHITECTURAL.S3_OPT_HEADER;
      graphUrl = URLS.ARCHITECTURAL.S3_OPT_GRAPH_DATA;
      break;
    case 'api':
      serviceFilter = serviceFilterNamesToAPI['api'];
      graphUrl = URLS.ARCHITECTURAL.S3_TYPE_GRAPH_DATA;
      recommendationsUrl = URLS.ARCHITECTURAL.S3_OPT_RECOMMENDATIONS;
      planType = { ...planType, s3_opt_type: serviceFilterNamesToAPI['api'] };
      break;
    case 'storage':
      serviceFilter = serviceFilterNamesToAPI['storage'];
      graphUrl = URLS.ARCHITECTURAL.S3_TYPE_GRAPH_DATA;
      recommendationsUrl = URLS.ARCHITECTURAL.S3_OPT_RECOMMENDATIONS;
      planType = { ...planType, s3_opt_type: serviceFilterNamesToAPI['storage'] };
      break;
    case 'underutilized-ec2':
      serviceFilter = serviceFilterNamesToAPI['underutilized-ec2'];
      graphUrl = URLS.OPERATIONAL.GRAPH_DATA_BY_TYPE;
      recommendationsUrl = URLS.OPERATIONAL.EC2_RECOMMENDATIONS;
      planType = { ...planType, savings_type: serviceFilterNamesToAPI['underutilized-ec2'] };
      break;
    case 'underutilized-ebs':
      serviceFilter = serviceFilterNamesToAPI['underutilized-ebs'];
      graphUrl = URLS.OPERATIONAL.GRAPH_DATA_BY_TYPE;
      recommendationsUrl = URLS.OPERATIONAL.EBS_VOL_RECOMMENDATIONS;
      planType = { ...planType, savings_type: serviceFilterNamesToAPI['underutilized-ebs'] };
      break;
    case 'underutilized-elb':
      serviceFilter = serviceFilterNamesToAPI['underutilized-elb'];
      graphUrl = URLS.OPERATIONAL.GRAPH_DATA_BY_TYPE;
      recommendationsUrl = URLS.OPERATIONAL.ELB_RECOMMENDATIONS;
      planType = { ...planType, savings_type: serviceFilterNamesToAPI['underutilized-elb'] };
      break;
    default:
      break;
  }

  return { service_filter: serviceFilter, headerUrl, graphUrl, recommendationsUrl, planType };
};

//navigation url type of sub url on click of row in data grid
export const getSelectedRowType = (item: string): string => {
  switch (item?.toLowerCase()) {
    case 'amazon-elasticache':
      return 'Amazon-Elasticache';
    case 'amazon-opensearch':
      return 'Amazon-OpenSearch';
    case 'amazon-redshift-service':
      return 'Amazon-Redshift';
    case 'amazon-rds':
    case 'amazon-relational-database':
    case 'amazon-relational-database-service':
      return 'Amazon-RDS';
    case 'amd-migration':
      return 'AMD-Migration';
    case 'graviton-migration':
      return 'Graviton-Migration';
    case 'nat-gateway-bytes':
      return 'Bytes';
    case 'nat-gateway-hours':
      return 'Hours';
    case 'reserved-instances':
      return 'RI';
    case 'compute':
      return 'Compute';
    case 'ec2':
      return 'EC2';
    case 'sagemaker':
      return 'SageMaker';
    case 'inter-region':
      return 'Inter-Region';
    case 'inter-az':
      return 'Inter-AZ';
    case 'out-to-internet':
      return 'Out-To-Internet';
    case 'idle-load-balancers':
      return 'Underutilized-ELB';
    case 'low-utilization-amazon-ec2-instances':
      return 'Underutilized-EC2';
    case 'underutilized-amazon-ebs-volumes':
      return 'Underutilized-EBS';
    default:
      return '';
  }
};

//navigation sub url on click of row in data grid
export const subUrlType = (item: string): string => {
  switch (item?.toLowerCase()) {
    case 'amazon-elasticache':
    case 'amazon-opensearch':
    case 'amazon-open-search':
    case 'amazon-relational-database-service':
    case 'amazon-redshift':
    case 'amazon-rds':
      return '/RI';
    case 'compute':
    case 'ec2':
    case 'sagemaker':
      return '/Savings-Plan';
    case 'out-to-internet':
    case 'inter-az':
    case 'inter-region':
      return '/Data-Transfer';
    case 'bytes':
    case 'hours':
      return '/NAT-Gateway';
    case 'storage':
    case 'api':
      return '/S3-Optimization';
    default:
      return '';
  }
};

export const isArchitecturalItems = (item: string): boolean => {
  if (
    item?.toLowerCase() === 'amd-migration' ||
    item?.toLowerCase() === 'ebs-modernization' ||
    item?.toLowerCase() === 'graviton-migration'
  )
    return true;
  return false;
};
