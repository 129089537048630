import React, { ReactNode } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import AWSCognitoContext from 'contexts/AWSCognitoContext';
import { useAuth } from 'hooks';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,

  p: 3
};

export interface ISessionManagerProps {
  children: ReactNode;
}

export const SessionManager: React.FC<ISessionManagerProps> = ({ children }) => {
  const context = React.useContext(AWSCognitoContext);
  const [lastActivityTime, setLastActivityTime] = React.useState(Date.now());
  const [open, setOpen] = React.useState(false);
  const { refreshCognitoToken } = useAuth();
  // Set the user's last activity time when the component mounts
  React.useEffect(() => {
    setLastActivityTime(Date.now());
  }, []);

  // Check the user's activity time every minute
  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - lastActivityTime;
      const idleTime = 15 * 60 * 1000;
      const warningTime = 10 * 60 * 1000;
      if (timeSinceLastActivity > idleTime) {
        context?.logout();
        window.location.href = '/login';
      } else if (timeSinceLastActivity > warningTime) {
        setOpen(true);
      }
    }, 3 * 1000);

    return () => clearInterval(interval);
  }, [lastActivityTime]);

  // Update the user's last activity time when they interact with the app
  const handleUserActivity = () => {
    setLastActivityTime(Date.now());
  };

  return (
    <div onClick={handleUserActivity}>
      {children}

      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography variant="h6" component="h2" style={{ fontWeight: 'bold', textAlign: 'center' }}>
            Inactive Session Alert!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            It looks like you've been inactive for a while. Would you like to stay signed in? If no action is taken, you'll be automatically
            logged out for your security.
          </Typography>
          <Box textAlign="center">
            {' '}
            <Button
              variant="contained"
              sx={{
                mt: 3
              }}
              onClick={() => {
                setOpen(false);
                refreshCognitoToken();
              }}
            >
              Stay signed in
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
