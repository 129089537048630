// material-ui
import { Grid, Typography } from '@mui/material';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { IUserType } from 'constants/tableTypes';
import { useAuth } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import jwt_decode from 'jwt-decode';

// project import
import AuthWrapper from 'sections/auth/AuthWrapper';
import useAxios from 'utils/axios';
import { URLS } from 'constants/endpoints';
import Loading from 'components/Loading';

// ================================|| LOGIN ||================================ //

const AmplifyLogin = () => {
  const { addSession, getAttributes } = useAuth();
  const navigate = useNavigate();
  const axiosServices = useAxios();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    Hub.listen('auth', (capsule: any) => {
      // You can explore the capsule object to see its structure and find the appropriate types.
      console.log(capsule);

      // Accessing specific properties (example):
      const { event, data } = capsule.payload;
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          console.log('grating...');
          setLoading(true);
          getToken().then(async (userToken: CognitoUserSession | void) => {
            if (userToken) {
              addSession(userToken);
              const attributes: any = await getAttributes();
              const serviceToken = localStorage.getItem('serviceToken');
              const attributes_jwt: any = jwt_decode(serviceToken!);
              console.log(attributes, attributes_jwt);
              let name: any = '';
              let subAttribute: any = '';
              let emailAttribute: any = '';
              if (attributes) {
                name = attributes.find((a: any) => a.Name === 'name');
                subAttribute = attributes.find((a: any) => a.Name === 'sub');
                emailAttribute = attributes.find((a: any) => a.Name === 'email');
              }
              const userData: IUserType = {
                user_id: subAttribute,
                first_name: name,
                last_name: name,
                job_title: '',
                organization: '',
                mobile: '',
                email: emailAttribute,
                invite_request_id: '',
                role: 'Admin',
                subscription_plan: 0
              };
              axiosServices
                .get(URLS.USER.GET_USER)
                .then((res) => {
                  if (res.data.data) {
                    var refreshIntervalId = setInterval(() => {
                      const token = localStorage.getItem('serviceToken');
                      if (token) {
                        navigate('/home');
                        clearInterval(refreshIntervalId);
                      }
                    }, 200);
                  }
                })
                .catch((err) => {
                  if (err.status === 400) {
                    console.log('In catch');
                    axiosServices
                      .post(URLS.USER.ADD_USER, userData)
                      .then(() => {
                        setLoading(false);
                        console.log('registartion success');
                        var refreshIntervalId = setInterval(() => {
                          const token = localStorage.getItem('serviceToken');
                          if (token) {
                            console.log('in catch after register success');
                            navigate('/home');
                            clearInterval(refreshIntervalId);
                          }
                        }, 200);
                      })
                      .catch(() => {
                        setLoading(false);
                        console.log('Error in registartion');
                      });
                  }
                })
                .finally(() => {
                  setLoading(false);
                });
              console.log(userToken);
            } else {
              console.log('No user token available.');
            }
          });
          break;
        case 'signOut':
          console.log(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          break;
      }
    });
  }, []);

  function getToken() {
    return Auth.currentSession()
      .then((session) => {
        console.log(session);
        return session;
      })
      .catch((err) => console.log(err));
  }
  return (
    <AuthWrapper>
      <Loading show={loading} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3">Amplify Login </Typography>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default AmplifyLogin;
