import { ReactNode } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import AuthCard from './AuthCard';
import './auth-forms/css/auth.css';
interface Props {
  children: ReactNode;
}

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({ children }: Props) => {
  return (
    <Box sx={{ height: '100vh' }}>
      <Grid container>
        <Grid item xs={12} container justifyContent="center" sx={{ minHeight: 'calc(100vh)' }}>
          <Grid item sx={{ px: 3, py: 4, height: '100%' }}>
            <Stack spacing={2} sx={{ height: '100%' }}>
              <Stack direction={'row'} justifyContent={'center'}>
                <img src="/logo.svg" alt="logo" style={{ width: '100%', maxWidth: '280px' }} />
              </Stack>
              <Stack sx={{ mt: 5 }}>
                <AuthCard>{children}</AuthCard>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AuthWrapper;
