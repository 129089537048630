import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Modal, Stack, Tab, IconButton, Typography } from '@mui/material';
import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { CustomerServiceOutlined } from '@ant-design/icons';
import { NeedHelp } from 'shared/callUs/NeedHelp';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  p: 1,
  maxHeight: '90%',
  overflowY: 'auto',
  borderRadius: '8px',
  padding: '40px'
};

export const Component = (props: any) => {
  const [value, setValue] = React.useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Modal open={props.open} onClose={props.onClose} aria-labelledby={`Actions modal for ${props.title}`}>
      <Box sx={style}>
        <Typography sx={{ color: 'secondary.main', textAlign: 'center' }} variant="h5">
          Recommended Action for {props.title}
        </Typography>
        <IconButton sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }} onClick={props.onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
        <Box sx={{ typography: 'body1', pt: 2 }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="Action tabs"
                sx={{
                  color: 'secondary.main !important',
                  '& .MuiTabs-indicator': {
                    backgroundColor: 'secondary.main'
                  },
                  '& .Mui-selected': {
                    bgcolor: 'secondary.main',
                    color: 'white !important',
                    '&:hover': {
                      bgcolor: 'secondary.main',
                      color: 'white !important'
                    }
                  },
                  '& .MuiTab-root': {
                    borderRadius: '8px 8px 0px 0px !important',
                    '&:hover': {
                      bgcolor: 'secondary',
                      color: 'secondary.main'
                    }
                  }
                }}
              >
                <Tab label="Console" value="1" />
                <Tab label="CLI" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Stack spacing={2} sx={{ p: 2 }}>
                {props.CommandText}
              </Stack>
            </TabPanel>
            <TabPanel value="2">
              <Stack spacing={2} sx={{ p: 2 }}>
                {props.CLIText}
              </Stack>
            </TabPanel>
          </TabContext>
        </Box>
        <Stack justifyContent="center" alignItems="center">
          <NeedHelp variant="contained" startIcon={<CustomerServiceOutlined />} text="Need Help" />
        </Stack>
      </Box>
    </Modal>
  );
};
