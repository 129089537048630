export type FileType = File | File[] | null;
export type EventHandler<T> = (event: CustomEvent<T>) => void;
export type FireEvent<T> = (handler: EventHandler<T>, detail: T) => void;

export interface ChangeDetail {
  value: FileType;
}

export interface DismissDetail {
  index: number;
  file: File;
}

export enum FileSize {
  BYTES = 'bytes',
  KB = 'KB',
  KIB = 'KiB',
  MB = 'MB',
  MIB = 'MiB',
  GB = 'GB',
  GIB = 'GiB'
}

export interface FileMetadata {
  name?: boolean;
  type?: boolean;
  size?: FileSize;
  lastModified?: boolean;
  lastModifiedLocale?: string;
  thumbnail?: boolean;
}
