import { Select, MenuItem, FormControl } from '@mui/material';
import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { RootStateProps } from 'types/root';
// import { useParams } from 'react-router';
import './Component.css';
import { FILTER } from '../../constants/defaults';
import { LableValuePair } from 'constants/types';

interface ComponentProps {
  handleValueChange: (value: string) => void;
}

const filters: LableValuePair[] = [
  { label: 'Past 3 Months', value: 'Past 3 Months' },
  { label: 'Past 6 Months', value: 'Past 6 Months' },
  { label: 'Past 12 Months', value: 'Past 12 Months' }
];

export const Component: React.FC<ComponentProps> = (props) => {
  // const filters = useSelector((state: RootStateProps) => state.filters.data);
  const [statusValue, setStatusValue] = useState('');
  // const { type } = useParams();

  useEffect(() => {
    if (filters.length > 1) {
      setStatusValue(FILTER);
    }
    props.handleValueChange(FILTER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <FormControl>
      <Select
        value={statusValue}
        onChange={(event) => {
          const newValue = event.target.value;
          setStatusValue(newValue);
          props.handleValueChange(newValue);
        }}
      >
        {filters.length > 0 &&
          filters?.map((option: LableValuePair) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
