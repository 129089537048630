import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

interface InitialState {
  isAwsSetupDone: boolean;
  accountID: string;
  accountName: string;
  s3BucketName: string;
  selectedAccountId: string;
  externalId: string;
}

const initialState: InitialState = {
  isAwsSetupDone: false,
  accountID: '',
  accountName: '',
  s3BucketName: '',
  selectedAccountId: '',
  externalId: ''
};

const awsSetup = createSlice({
  name: 'awsSetup',
  initialState,
  reducers: {
    setIsAwsSetupDone(state, action) {
      state.isAwsSetupDone = action.payload;
    },
    setAwsDetails(state, action) {
      state.accountID = action.payload.accountID;
      state.accountName = action.payload.accountName;
      state.s3BucketName = action.payload.s3BucketName;
      state.externalId = uuidv4();
    },
    setSelectedAccountId(state, action) {
      state.selectedAccountId = action.payload;
    }
  }
});

export default awsSetup.reducer;

export const { setIsAwsSetupDone, setAwsDetails, setSelectedAccountId } = awsSetup.actions;
