import { Box, Stack, Typography, CircularProgress, circularProgressClasses, CircularProgressProps } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
        }}
        {...props}
        value={100}
        size={35}
      />
      <Box sx={{ position: 'absolute', left: 0, display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          // disableShrink
          sx={{
            animationDuration: '550ms',
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round'
            }
          }}
          size={35}
          {...props}
          value={props.value}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">{`${props.value}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default function Component(props: any) {
  return (
    <Stack justifyContent="center" alignItems="center">
      {props.value < 100 && <CircularProgressWithLabel value={props.value} />}
      <Stack direction="row" justifyContent="center" alignItems="center">
        {props.value === 100 && <CheckCircleIcon sx={{ color: 'rgba(29, 129, 2, 1)' }} />}
        <Typography>{props.message}</Typography>
      </Stack>
    </Stack>
  );
}
