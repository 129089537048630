import { useEffect, useMemo, useState } from 'react';
import { Graph } from 'shared/graphFilter/Graph';
import { Grid, Typography } from '@mui/material';
import {
  amdRecommendationHeaderCells,
  ebsRecommendationHeaderCells,
  dataTransferRecommendationHeaderCells,
  runningHoursRecommendationHeaderCells,
  gravitonRecommendationHeaderCells,
  s3OptStorageRecommHeaderCells,
  s3OptApiRecommHeaderCells
} from 'constants/headerCells';
import useAxios from 'utils/axios';
import Loading from 'components/Loading';
import { useGraphData, useSubscriptionPlan, useUserData } from 'hooks';
import { EbsActionModal } from 'pages/architecturalSavings/EbsActionModal';
import { GraphFilter } from 'shared/graphFilter';
import { getFilteredData } from 'pages/utils/getGraphFilteredData';
import { FILTER } from '../../constants/defaults';
import { useParams } from 'react-router';
import { DataTable } from 'shared/dataTable';
import { AmdActionModal } from './amdActionModal';
import { getSavingsData, getSavingsName, isArchitecturalItems } from 'pages/utils/getSavingsData';
import { GraphData, RecommendationsData } from 'pages/types';
import { DtS3ActionModal } from './DtS3ActionModal';
import { getPastMonths } from 'pages/utils/getPastMonths';
import { FormattedMessage } from 'react-intl';
import { NOTIFY } from 'shared';
import { NeedHelp } from 'shared/callUs/NeedHelp';
import { SavingsData } from 'constants/types';
import { MESSAGES } from 'constants/messages';

const DEFAULT_GRAPH_DATA: GraphData = {
  xaxisData: [],
  yaxisData: [],
  loading: false
};

const RECOM_DEFAULT_DATA: RecommendationsData = {
  headerCells: [],
  hideColumns: null
};

export const SpecificSavingType = (props: any) => {
  const { getUserData } = useUserData();
  const { UserSubscriptionData } = useSubscriptionPlan();
  const axiosServices = useAxios();
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState(FILTER);
  const [loading, setLoading] = useState(false);
  const [openEbsModal, setOpenEbsModal] = useState(false);
  const [openAmdModal, setOpenAmdModal] = useState(false);
  const [openDtS3Modal, setOpenDtS3Modal] = useState(false);
  const [recommendationsData, setRecommendationsData] = useState(RECOM_DEFAULT_DATA);
  const [graphData, setGraphData] = useState({
    data: DEFAULT_GRAPH_DATA
  });
  const [totalAvailRecords, setTotalAvailRecords] = useState(0);
  const { fetchData } = useGraphData();

  const { atype, type } = useParams();

  useEffect(() => {
    setLoading(true);
    let savingsData: SavingsData = getSavingsData(type || atype);
    let serviceName = savingsData?.service_filter?.toLowerCase();
    switch (serviceName) {
      case 'amd':
        setRecommendationsData({
          headerCells: amdRecommendationHeaderCells,
          hideColumns: ['region', 'operating_system', 'amd_achieved_savings']
        });
        break;

      case 'ebs':
        setRecommendationsData({
          headerCells: ebsRecommendationHeaderCells,
          hideColumns: ['region', 'current_ebs_volume_size_gib', 'ebs_achieved_savings']
        });
        break;

      case 'dto':
      case 'dtaz':
      case 'dtir':
        setRecommendationsData({
          headerCells: dataTransferRecommendationHeaderCells,
          hideColumns: []
        });
        break;

      case 'ngw hours':
        setRecommendationsData((prevState) => ({
          ...prevState,
          headerCells: runningHoursRecommendationHeaderCells,
          hideColumns: []
        }));
        break;

      case 'ngw bytes':
        setRecommendationsData((prevState) => ({
          ...prevState,
          headerCells: dataTransferRecommendationHeaderCells,
          hideColumns: []
        }));
        break;

      case 'api':
        setRecommendationsData((prevState) => ({
          ...prevState,
          headerCells: s3OptApiRecommHeaderCells,
          hideColumns: []
        }));
        break;

      case 'storage':
        setRecommendationsData((prevState) => ({
          ...prevState,
          headerCells: s3OptStorageRecommHeaderCells,
          hideColumns: []
        }));
        break;

      case 'graviton':
        setRecommendationsData({
          headerCells: gravitonRecommendationHeaderCells,
          hideColumns: ['region', 'operating_system', 'graviton_achieved_savings']
        });
        break;

      default:
        break;
    }
    axiosServices
      .get(savingsData.recommendationsUrl, {
        params: {
          filter: 'Yesterday',
          aws_account_id: getUserData('selectedAwsAccountId'),
          ...savingsData.planType
        }
      })
      .then((res: any) => {
        setRowsData(res.data.data?.items || []);
        setTotalAvailRecords(res.data.data?.total);
      })
      .catch(() => {
        NOTIFY.Error(MESSAGES.API_REQUEST_ERROR);
      })
      .finally(() => setLoading(false));
  }, [atype, type, props.selectedRow]);

  useEffect(() => {
    const savingsData = getSavingsData(type || atype);
    setLoading(true);
    fetchData({ filter: filterValue, ...savingsData.planType }, savingsData?.graphUrl)
      .then((res) => {
        setGraphData({
          data: {
            xaxisData: getPastMonths(filterValue),
            yaxisData: res.yaxisData,
            loading: res.loading
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [atype, type, props.selectedRow, filterValue]);

  const handleActionClick = () => {
    let serviceName = getSavingsData(type || atype)?.service_filter?.toLowerCase();
    if (['amd', 'graviton'].includes(serviceName)) {
      setOpenAmdModal(true);
    } else if (serviceName === 'ebs') {
      setOpenEbsModal(true);
    } else if (['data-transfer', 's3-optimization', 'nat-gateway'].includes(atype?.toLowerCase() || '')) {
      setOpenDtS3Modal(true);
    }
  };

  const savingsType = getSavingsName(type || atype);

  const getGraphLabel = () => {
    switch (savingsType) {
      case 'API':
        return 'Usage (Requests)';
      case 'NAT Gateway Hours':
        return 'Usage (Hours)';
      default:
        return 'Usage (GB)';
    }
  };

  const getFreeAssessment = () => {
    return <NeedHelp text="free assessment" variant="text" />;
  };

  const getSubHeader = () => {
    if (savingsType === 'AMD Migration') {
      return (
        <Typography>
          Note: Some applications may not be fully compatible with AMD processors, leading to reduced performance or limited functionality.
          Identifying such applications is complex and requires expert guidance. Consult with our friendly DiscoverCloud team for an
          in-depth assessment and tailored solutions. Book your {getFreeAssessment()}.
        </Typography>
      );
    } else if (savingsType === 'Graviton Migration') {
      return (
        <Typography>
          Note: Some applications may not be fully compatible with Graviton processors, leading to reduced performance or limited
          functionality. Identifying such applications is complex and requires expert guidance. Consult with our friendly DiscoverCloud team
          for an in-depth assessment and tailored solutions. Book your {getFreeAssessment()}.
        </Typography>
      );
    }
  };

  const useMemoizedGraphData = (filterType: string) =>
    useMemo(
      () => getFilteredData(graphData.data.yaxisData, filterType, filterValue),
      [graphData.data.yaxisData, filterType, atype, type, filterValue]
    );
  const potentialSavingsData = useMemoizedGraphData('potential_savings');
  const achievedSavingsData = useMemoizedGraphData('achieved_savings');
  const costData = useMemoizedGraphData('Cost');
  const usageData = useMemoizedGraphData('Usage');

  return (
    <Grid container rowSpacing={2} columnSpacing={3}>
      <Loading show={loading || graphData.data.loading} />
      <Grid item xs={6}>
        <Typography variant="h5" sx={{ color: 'secondary.main', fontWeight: 500, fontSize: '18px' }}>
          {savingsType}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <GraphFilter handleValueChange={(e: any) => setFilterValue(e)} />
      </Grid>

      {isArchitecturalItems(atype!) ? (
        <>
          <Grid item xs={12} md={6}>
            <Graph
              title={<FormattedMessage id="potentialSavingsGraph" />}
              data={potentialSavingsData}
              categories={getPastMonths(filterValue)}
              type="line"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Graph
              title={<FormattedMessage id="achievedSavingsGraph" />}
              data={achievedSavingsData}
              categories={getPastMonths(filterValue)}
              type="line"
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <Graph title={<FormattedMessage id="cost" />} data={costData} categories={getPastMonths(filterValue)} type="line" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Graph
              title={<FormattedMessage id="usage" />}
              data={usageData}
              categories={getPastMonths(filterValue)}
              type="line"
              name={getGraphLabel()}
              formater="number"
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {recommendationsData.hideColumns !== null && (
          <DataTable
            data={rowsData || []}
            columns={recommendationsData.headerCells}
            hideColumns={recommendationsData.hideColumns}
            headerData={{ title: 'Recommendations', savingsType, subheader: getSubHeader(), showRecommendedAction: true }}
            tableActions={{ onActionClick: handleActionClick, showLockRecords: UserSubscriptionData?.SHOW_LOCK_RECORDS }}
            globalSearch={{
              searchPlaceholder: UserSubscriptionData?.SHOW_LOCK_RECORDS
                ? `Search ${rowsData.length} of ${totalAvailRecords} records...`
                : ''
            }}
          />
        )}
      </Grid>
      {openEbsModal && <EbsActionModal open={openEbsModal} handleClose={() => setOpenEbsModal(false)} />}
      {openAmdModal && <AmdActionModal open={openAmdModal} handleClose={() => setOpenAmdModal(false)} title={savingsType} />}
      {openDtS3Modal && <DtS3ActionModal open={openDtS3Modal} handleClose={() => setOpenDtS3Modal(false)} title={savingsType} />}
    </Grid>
  );
};
