import { useEncryption } from './useEncryption';
export interface IUserData {
  isNewAccount: boolean | undefined;
  awsDetailsLength: number;
  email: string;
  selectedAwsAccountId: string;
  createdTime: string;
  selectedType: string;
  userData: any;
}

const userDefaultData: IUserData = {
  isNewAccount: false,
  awsDetailsLength: 0,
  email: '',
  selectedAwsAccountId: '',
  createdTime: '',
  selectedType: '',
  userData: {}
};

const useUserData = () => {
  const { convertToEncryptedText, getCryptoDecryptedText } = useEncryption();
  const addUserData = <K extends keyof IUserData>(key: K, data: IUserData[K]) => {
    const storedData = getCryptoDecryptedText(localStorage.getItem('userData') || '');
    let userData = userDefaultData;
    if (storedData) {
      userData = JSON.parse(storedData);
    }
    userData[key] = data;
    userData['createdTime'] = new Date().toString();
    localStorage.setItem('userData', convertToEncryptedText(JSON.stringify(userData)));
  };

  const getUserData = <K extends keyof IUserData>(key: K): IUserData[K] => {
    const storedData = getCryptoDecryptedText(localStorage.getItem('userData') || '');
    if (storedData) {
      return JSON.parse(storedData)[key];
    }
    return userDefaultData[key];
  };

  return { addUserData, getUserData };
};

export default useUserData;
