export const getPastMonths = (filter: string) => {
  let count = 6;
  if (filter === 'Past 6 Months') count = 6;
  else if (filter === 'Past 3 Months') count = 3;
  else if (filter === 'Past 12 Months') count = 12;

  const months: string[] = [];
  const currentDate = new Date();

  for (let i = 1; i <= count; i++) {
    const pastDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
    const monthName = pastDate.toLocaleString('default', { month: 'short' });
    const year = pastDate.getFullYear();
    const formattedDate = `${monthName} ${year}`;
    months.push(formattedDate);
  }
  return months.reverse();
};
