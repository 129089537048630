import axios, { InternalAxiosRequestConfig } from 'axios';
import { useNavigate } from 'react-router';
import { NOTIFY } from 'shared';
import jwt_decode from 'jwt-decode';
import { useAuth } from 'hooks';
import { getDecryptData } from './decryption';
const code = 'ERR_NETWORK';
const useAxios = () => {
  const navigate = useNavigate();
  const { refreshCognitoToken } = useAuth();
  const axiosServices = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
  });
  axiosServices.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    let token = localStorage.getItem('serviceToken');
    if (token) {
      const attributes: any = jwt_decode(token!);
      const currentTime = Math.floor(Date.now() / 1000);
      if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
      }
      const expiresIn = attributes.exp - currentTime;
      const refreshThreshold = 180;
      if (expiresIn < refreshThreshold) {
        token = await refreshCognitoToken();
      }

      if (config && config.headers && token) config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  });

  axiosServices.interceptors.response.use(
    (response) => {
      response.data = getDecryptData(response.data);
      if (response.data.status_code !== 200) {
        if (response.data.status_code === 403) {
          NOTIFY.ClearAll();
          navigate('/login');
        }
        NOTIFY.Error(response.data.message);
        return Promise.reject(response.data);
      }
      return response;
    },
    (error) => {
      if (error.code === code) {
        navigate('/Error/Server');
      }

      return Promise.reject(error);
    }
  );

  return axiosServices;
};
export default useAxios;
