import { useState } from 'react';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { LogoutOutlined, UserOutlined, LockOutlined, TeamOutlined } from '@ant-design/icons';

import { useNavigate } from 'react-router';
import { useUserData } from 'hooks';
import Roles from 'enums/roles';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
  handleCancel: (arg: boolean) => void;
}

const ProfileTab = ({ handleLogout, handleCancel }: Props) => {
  const navigate = useNavigate();
  const { getUserData } = useUserData();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, url: string) => {
    setSelectedIndex(index);
    handleCancel(false);
    navigate(url);
  };
  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 0, '/account/profile')}
      >
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItemButton>      
      {getUserData('userData')?.role === Roles.ADMIN && (
        <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 2, '/account/role')}
        >
          <ListItemIcon>
            <TeamOutlined />
          </ListItemIcon>
          <ListItemText primary="Role" />
        </ListItemButton>
      )}
      <ListItemButton
        selected={selectedIndex === 3}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 3, '/account/change-password')}
      >
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
