import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { toast, ToastOptions } from 'react-toastify';
const TOAST_PROPS: ToastOptions = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 60000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
};
const toastId: string = `toastMsg`;
export const NOTIFY = {
  Toast: toast,
  isClearAll: true,
  Info: (message: string, isClearAll: boolean = true) => {
    if (toast.isActive(toastId)) {
      toast.update(toastId, {
        type: 'info',
        icon: <InfoCircleFilled />,
        render: message,
        ...TOAST_PROPS
      });
    }
    NOTIFY.isClearAll = isClearAll;
    toast.info(message, {
      ...TOAST_PROPS,
      toastId,
      icon: <InfoCircleFilled />
    });
  },
  Success: (message: string, isClearAll: boolean = true) => {
    if (toast.isActive(toastId)) {
      toast.update(toastId, {
        type: 'success',
        render: message,
        icon: <CheckCircleFilled />,
        ...TOAST_PROPS
      });
    } else {
      toast.success(message, {
        ...TOAST_PROPS,
        toastId,
        icon: <CheckCircleFilled />
      });
    }
    // NOTIFY.isClearAll = isClearAll;
  },
  Warn: (message: string, isClearAll: boolean = true) => {
    if (toast.isActive(toastId)) {
      toast.update(toastId, {
        type: 'warning',
        icon: <ExclamationCircleFilled />,
        render: message,
        ...TOAST_PROPS
      });
    }
    NOTIFY.isClearAll = isClearAll;
    toast.warn(message, {
      ...TOAST_PROPS,
      toastId,
      icon: <ExclamationCircleFilled />
    });
  },
  Error: (message: string, isClearAll: boolean = true) => {
    if (toast.isActive(toastId)) {
      toast.update(toastId, {
        type: 'error',
        icon: <CloseCircleFilled />,
        render: message,
        ...TOAST_PROPS
      });
    } else {
      toast.error(message, {
        ...TOAST_PROPS,
        toastId,
        icon: <CloseCircleFilled />
      });
    }
  },
  ClearAll: () => {
    if (NOTIFY.isClearAll) toast.dismiss();
  }
};
