import { Typography, Stack, Button } from '@mui/material';
import { ActionModal } from 'shared';
import { CopyOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';

export const EbsActionModal = (props: any) => {
  const theme = useTheme();
  const command1 = `aws ec2 modify-volume --volume-type gp3 -volume-id <---add the volume id that you want to modify -->`;
  const command2 = `aws ec2 modify-volume --volume-type gp3 --iops <--add the required IOPS value--> -throughput <--add the required Throughput Value--> --volume-id <--add the volume id that you want to modify-->`;
  const [copied, setCopied] = useState<any>({});

  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied((prevState: any) => ({ ...prevState, [text]: 'Copied!' }));
      })
      .catch(() => {
        setCopied((prevState: any) => ({ ...prevState, [text]: 'Copy' }));
      });
  };

  const CommandText = (
    <Stack spacing={1}>
      <ol>
        <li>Ensure that you are logged in to AWS Management Console with appropriate permissions to modify volume.</li>
        <li>
          Click this link to go to list of volumes on AWS Management console -{' '}
          <a href="https://us-east-1.console.aws.amazon.com/ec2/home?region=us-east-1#Volumes:">
            https://us-east-1.console.aws.amazon.com/ec2/home?region=us-east-1#Volumes:
          </a>
          .
        </li>
        <li>
          Search for the Volume ID that you want to modify, then modify Volume window displays the volume ID and the volume's current
          configuration, including type, size, IOPS, and throughput. Set new configuration values as follows:
          <br />
          <ol>
            <li>To modify the type, choose gp3 for Volume Type.</li>
            <li>To modify the size, enter a new value for Size.</li>
            <li>To modify the IOPS, enter a new value for IOPS.</li>
            <li>To modify the throughput, if the volume type is gp3, enter a new value for Throughput.</li>
          </ol>
        </li>
        <li>After you have finished changing the volume settings, choose Modify. When prompted for confirmation, choose Yes.</li>
      </ol>
    </Stack>
  );
  const CLIText = (
    <Stack spacing={1}>
      <ol>
        <li>Ensure that your AWS configuration is set to the desired AWS account and Region where the volume resides.</li>
        <li>
          Use the following command if you want to change it to the default IOPS and Throughput configuration of gp3.
          <Stack spacing={1} sx={{ mb: 1 }}>
            <Typography>
              <span style={{ float: 'right' }}>
                <Button
                  className="btncopy"
                  onClick={() => handleCopy(command1)}
                  startIcon={<CopyOutlined style={{ color: 'secondary.main' }} />}
                  sx={{ color: 'secondary.main' }}
                >
                  {copied[command1] || 'Copy'}
                </Button>
              </span>
            </Typography>
            <Typography
              sx={{ fontFamily: 'Courier New', border: `1px solid ${theme.palette.secondary.main}`, p: 1, mx: 8, borderRadius: '8px' }}
            >
              aws ec2 modify-volume --volume-type gp3 -volume-id{' '}
              <b>
                {'<'}---add the volume id that you want to modify --{'>'}
              </b>
            </Typography>
          </Stack>
        </li>
        <li>
          Use the following command if you want to change it to a different value of IOPS and Throughput.
          <Stack spacing={1}>
            <Typography>
              <span style={{ float: 'right' }}>
                <Button
                  className="btncopy"
                  onClick={() => handleCopy(command2)}
                  startIcon={<CopyOutlined style={{ color: 'secondary.main' }} />}
                  sx={{ color: 'secondary.main' }}
                >
                  {copied[command2] || 'Copy'}
                </Button>
              </span>
            </Typography>
            <Typography
              sx={{ fontFamily: 'Courier New', border: `1px solid ${theme.palette.secondary.main}`, p: 1, mx: 8, borderRadius: '8px' }}
            >
              aws ec2 modify-volume --volume-type gp3 --iops{' '}
              <b>
                {'<'}--add the required IOPS value--{'>'}
              </b>{' '}
              --throughput{' '}
              <b>
                {'<'}--add the required Throughput Value--{'>'}
              </b>{' '}
              --volume-id{' '}
              <b>
                {'<'}--add the volume id that you want to modify--
                {'>'}
              </b>
            </Typography>
          </Stack>
        </li>
      </ol>
    </Stack>
  );
  return (
    <ActionModal CommandText={CommandText} CLIText={CLIText} open={props.open} onClose={props.handleClose} title="EBS Modernization" />
  );
};
