import AWS from 'aws-sdk';
import { useNavigate } from 'react-router';
import { NOTIFY } from 'shared';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET,
  region: process.env.REACT_APP_AWS_REGION
});

const sts = new AWS.STS();
export const useLinkAwsAccount = (handleNext?: any) => {
  // const { user } = useAuth();
  const navigate = useNavigate();

  const linkAwsAccount = async (timeOut: number, accountID: string, externalId: any, handleError?: Function, fromUrl = 'step2') => {
    const roleArn = `arn:aws:iam::${accountID}:role/DiscoverCloudMasterAccountReader`;
    const roleSessionName = 'cco-session';
    const params = {
      RoleArn: roleArn,
      RoleSessionName: roleSessionName,
      ExternalId: externalId
    };

    return new Promise((resolve, reject) => {
      sts.assumeRole(params, function (err: Error, data: any) {
        if (err) {
          if (fromUrl === 'login') navigate('/awsAccountSetup', { replace: true });
          if (err.name === 'AccessDenied') {
            setTimeout(() => {
              timeOut = timeOut - 10000;
              if (timeOut < 0) {
                handleError && handleError(true);
                reject(err);
                handleNext && handleNext();
                return;
              }
              linkAwsAccount(timeOut, accountID, externalId, handleError, fromUrl).then(resolve).catch(reject);
            }, timeOut);
          } else {
            handleError && handleError(true);
            reject(err);
            handleNext && handleNext();
          }
        } else {
          const assumedRoleCredentials = data.Credentials;
          const cloudFormation = new AWS.CloudFormation({
            apiVersion: '2010-05-15',
            credentials: {
              accessKeyId: assumedRoleCredentials.AccessKeyId,
              secretAccessKey: assumedRoleCredentials.SecretAccessKey,
              sessionToken: assumedRoleCredentials.SessionToken
            }
          });

          const describeStacks = () => {
            cloudFormation.describeStacks({ StackName: process.env.REACT_APP_STACK_NAME }, function (err: Error, data: any) {
              if (err) {
                NOTIFY.Error(`Stack creation failed: ${err.message}`);
                reject(err);
                handleNext && handleNext();
              } else {
                const stackStatus = data?.Stacks?.[0]?.['StackStatus'];
                if (stackStatus === 'CREATE_IN_PROGRESS') {
                  setTimeout(() => {
                    describeStacks();
                  }, 20000);
                } else {
                  resolve(stackStatus);
                  handleNext && handleNext();
                }
              }
            });
          };
          describeStacks();
        }
      });
    });
  };

  return { linkAwsAccount };
};
