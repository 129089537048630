import { Typography, Stack, Button } from '@mui/material';
import { useState } from 'react';
import { ActionModal } from 'shared';
import { CopyOutlined } from '@ant-design/icons';
import { useTheme } from '@mui/material/styles';

export const ElasticacheActionModal = (props: any) => {
  const theme = useTheme();
  const command = `aws elasticache purchase-reserved-cache-nodes-offering --reserved-cache-nodes-offering-id <--add the corresponding offering Id for this purchase-->`;
  const [copy, icCopied] = useState('Copy');
  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        icCopied('Copied!');
      })
      .catch(() => {
        icCopied('Copy');
      });
  };

  const CommandText = (
    <Stack spacing={1}>
      <ol>
        <li>
          Sign in to the AWS Management Console and open the ElastiCache console at{' '}
          <a href="https://console.aws.amazon.com/elasticache/" target="_blank" rel="noopener noreferrer">
            https://console.aws.amazon.com/elasticache/
          </a>
          .
        </li>
        <li>In the navigation list, choose the Reserved Nodes link.</li>
        <li>Choose the Purchase reserved nodes button.</li>
        <li>For Engine, choose Memcached.</li>
        <li>
          To determine the available offerings, make selections for the following options:
          <ul>
            <li>Node Type</li>
            <li>Term</li>
            <li>Offering Type</li>
            <li>An optional Reserved node ID</li>
          </ul>
        </li>
        <li>After you make these selections, the cost per node and total cost of your selections is shown under Reservation details.</li>
        <li>Choose Purchase.</li>
      </ol>
    </Stack>
  );

  const CLIText = (
    <Stack spacing={1}>
      <ol>
        <li>Ensure that your AWS configuration is set to the desired AWS account and Region where the volume resides.</li>
        <li>
          Use the following command by adding the offering ID of Reservation that you want to subscribe.
          <Stack spacing={1}>
            <Typography>
              <span style={{ float: 'right' }}>
                <Button
                  className="btncopy"
                  onClick={() => handleCopy(command)}
                  startIcon={<CopyOutlined style={{ color: 'secondary.main' }} />}
                  sx={{ color: 'secondary.main' }}
                >
                  {copy}
                </Button>
              </span>
            </Typography>
            <Typography
              sx={{ fontFamily: 'Courier New', border: `1px solid ${theme.palette.secondary.main}`, p: 1, mx: 8, borderRadius: '8px' }}
            >
              aws elasticache purchase-reserved-cache-nodes-offering --reserved-cache-nodes-offering-id{' '}
              <b>
                {'<'}--add the corresponding offering Id for this purchase--{'>'}
              </b>
            </Typography>
          </Stack>
        </li>
      </ol>
    </Stack>
  );
  return (
    <ActionModal CommandText={CommandText} CLIText={CLIText} open={props.open} onClose={props.handleClose} title="Amazon ElastiCache" />
  );
};
