import { Toolbar, useMediaQuery } from '@mui/material';
import { Box, Stack, useTheme } from '@mui/system';
import { SIDENAV_VISIBLE } from 'constants/LayoutConstants';
import { SideNavConfig, SideNavVisible } from 'constants/types';
import { useConfig, useUserData } from 'hooks';
import Drawer from 'layout/MainLayout/Drawer';
import Header from 'layout/MainLayout/Header';
// import { patternMatch } from 'pages/utils/patternMatch';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router';
import { openDrawer } from 'store/reducers/menu';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { LAYOUT_CONST } from 'types/config';
import { RootStateProps } from 'types/root';
// import Products from 'enums/products';
// import { getSavingsName } from 'pages/utils/getSavingsData';
// import { capitalizeFirstLetter } from 'utils/capitalizeEachWord';
import './drawer.css';

const GenAILayout = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { getUserData } = useUserData();
  // const [breadcrumbs, setBreadcrumbs] = useState<any>([]);
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;
  const { miniDrawer, menuOrientation } = useConfig();
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const { pathname } = useLocation();
  // const { visibility } = useParams();
  const [showSideNav, setShowSideNav] = useState(false);
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  // interface BreadCrumbItem {
  //   name: string;
  //   href: string;
  // }

  useEffect(() => {
    const pathName: string = pathname.split('/')[1];
    let navVisible: SideNavConfig | undefined = undefined;
    if (Object.keys(SIDENAV_VISIBLE).includes(pathName)) {
      navVisible = SIDENAV_VISIBLE[pathName as keyof SideNavVisible];
    }
    if (navVisible && navVisible.showSideNav) {
      if (!navVisible.hiddenNavUrls.includes(pathname)) setShowSideNav(true);
      else setShowSideNav(false);
    } else setShowSideNav(false);
  }, [getUserData, pathname]);

  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    const pathName: string = pathname.split('/')[1];
    let navVisible: SideNavConfig | undefined = undefined;
    if (Object.keys(SIDENAV_VISIBLE).includes(pathName)) {
      navVisible = SIDENAV_VISIBLE[pathName as keyof SideNavVisible];
    }
    if (navVisible && navVisible.showSideNav) {
      if (!navVisible.hiddenNavUrls.includes(pathname) && !window.location.href.includes('?title=NexEnt')) setShowSideNav(true);
      else setShowSideNav(false);
    } else setShowSideNav(false);
  }, [getUserData, pathname]);

  // const formatBreadCrumbLabel = (value: any) => {
  //   const val = getSavingsName(value) || capitalizeFirstLetter(value);
  //   return val.replace(/-/g, ' ');
  // };
  // useEffect(() => {
  // const pathComps = pathname.split('/');
  // let pathArray: BreadCrumbItem[] = pathComps.map((item, index) => {
  //   let href = '';
  //   switch (item) {
  //     case Products.SOLUTIONS.toLowerCase():
  //       href = '/solutions';
  //       break;
  //   }
  //   return { name: item, href };
  // });
  // const lowerCasePathComps = pathComps.map((comp) => comp.toLowerCase());
  // if (lowerCasePathComps.includes('diagrams')) {
  //   if (visibility !== undefined) {
  //     pathArray = pathArray.filter((i) => i.name !== visibility);
  //   }
  // }
  // const breadcrumbComps = pathArray.map((breadcrumb: BreadCrumbItem, index: number) => {
  //   return (
  //     <Link underline="hover" key={index + 2} color="inherit" href={`${breadcrumb.href}`} sx={{ color: '#00248C' }}>
  //       {formatBreadCrumbLabel(breadcrumb.name)}
  //     </Link>
  //   );
  // });

  // setBreadcrumbs([
  //   ...breadcrumbComps.splice(1, breadcrumbComps.length - 2),
  //   <Typography key="3" color="text.primary">
  //     {formatBreadCrumbLabel(pathArray[pathArray.length - 1].name)}
  //   </Typography>
  // ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  const path = pathname.split('/');
  console.log('path', path);
  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      <Stack direction="row">
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        {showSideNav && <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />}
        <Box
          component="main"
          sx={{
            width: 'calc(100% - 320px)',
            flexGrow: 1,
            height: 'calc(100vh)',
            backgroundColor:
              // path.length === 2 && path.includes('/solutions')
              //   ? '#ffffff  '
              //   : showSideNav || patternMatch(bgColorChangeMenu, pathname.toLowerCase())
              //   ? '#F5F5F5'
              '#ffffff',
            p: { xs: 2, sm: 0 }
          }}
        >
          <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />

          {/* <Stack rowGap={2}>
              {showSideNav && (
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                  {breadcrumbs}
                </Breadcrumbs>
              )} */}
          <Outlet />
          {/* </Stack> */}
        </Box>
      </Stack>
    </Box>
  );
};

export default GenAILayout;
