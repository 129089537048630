import React, { useState, useEffect } from 'react';
import { useFormik, getIn } from 'formik';
import {
  Box,
  Button,
  TextField,
  Autocomplete,
  Grid,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Typography,
  FormHelperText,
  Stack,
  Paper
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { getTimeZoneOptions } from 'utils/timezones';
// import { NOTIFY } from 'shared/toast/Toast';
import Loading from 'components/Loading';
import useAxios from 'utils/axios';
import { URLS } from 'constants/endpoints';
import { isWeekend } from 'utils/holidays';
import { callUsValidationSchema } from './validationSchema';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
// import { SuccessModal } from './SuccessPopup';
import ConfirmDialog from 'shared/confirmDialog';
import { capitalizeFirstLetter } from 'utils/capitalizeEachWord';
import { TextFieldCounter } from 'shared';
import { Dayjs } from 'dayjs';

const MSG_CHAR_LIMIT = 500;
const timeSlots = [
  { value: '9AM - 10AM', label: '9AM - 10AM', valueIn24Hr: '9' },
  // { value: '10AM - 11AM', label: '10AM - 11AM', valueIn24Hr: '10' },
  { value: '11AM - 12PM', label: '11AM - 12PM', valueIn24Hr: '11' },
  { value: '12PM - 1PM', label: '12PM - 1PM', valueIn24Hr: '12' },
  // { value: '1PM - 2PM', label: '1PM - 2PM', valueIn24Hr: '13' },
  // { value: '2PM - 3PM', label: '2PM - 3PM', valueIn24Hr: '14' },
  { value: '3PM - 4PM', label: '3PM - 4PM', valueIn24Hr: '15' },
  // { value: '4PM - 5PM', label: '4PM - 5PM', valueIn24Hr: '16' }
  { value: '5PM - 6PM', label: '5PM - 6PM', valueIn24Hr: '17' }
];

const DEFAULT_VALUE = { last_name: '', first_name: '', email: '' };

export const Component = (props: any) => {
  const axiosServices = useAxios();
  const [userData, setUserData] = useState(DEFAULT_VALUE);
  const timezones = React.useMemo(() => {
    return getTimeZoneOptions(true);
  }, []);
  const [loading, setLoading] = React.useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    timezones.find((tz) => tz.value === Intl.DateTimeFormat().resolvedOptions().timeZone)
  );

  useEffect(() => {
    setLoading(true);
    axiosServices
      .get(URLS.USER.GET_USER)
      .then((res: any) => {
        setLoading(false);
        if (res.data?.data) {
          const { last_name, first_name, email } = res.data.data;
          setUserData({ last_name, first_name, email });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: userData.first_name,
      last_name: userData.last_name,
      email: userData.email,
      date: null,
      time_zone: selectedTimeZone?.value || '',
      time_slot: '',
      message: ''
    },
    validationSchema: callUsValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      axiosServices
        .post(URLS.CONTACT.CREATE, values)
        .then((res: any) => {
          // NOTIFY.Success(res.data.message);
          setOpenPopup(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  });

  const shouldDisableDate = (date: Dayjs) => {
    return isWeekend(date);
  };
  const disableTimeSlot = (timeSlotValue: string) => {
    const now = new Date().toLocaleString('en-US', { timeZone: formik.values.time_zone });
    const currentDateTime = new Date(now);
    let hours = currentDateTime.getHours();
    if (hours >= Number(timeSlotValue) && formik.values.date && new Date(formik.values.date).getDate() === new Date().getDate()) {
      return true;
    }
    return false;
  };
  const handleDateChange = (value: any) => {
    formik.setFieldValue('date', value);
    if (disableTimeSlot(formik.values.time_slot)) formik.setFieldValue('time_slot', '');
  };

  const getFormattedDate = (date: any) => {
    const dateObject = new Date(date);

    const formattedDate = `${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject
      .getDate()
      .toString()
      .padStart(2, '0')}-${dateObject.getFullYear()}`;

    return formattedDate;
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ p: 1 }}>
      <Loading show={loading} />
      <Stack spacing={2}>
        <Stack sx={{ width: { xs: '100%', md: '60%' } }} spacing={1}>
          <Typography sx={{ color: '#00248C', fontSize: '24px', fontWeight: 500 }}>Need Help?</Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>We're Here For You!</Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
            Got questions or running into issues? No worries—our support team is ready to help. Just book a time slot, and we'll sort things
            out quickly.
          </Typography>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="center">
          <Paper elevation={2} sx={{ p: { xs: 2, md: 4 }, width: { xs: '100%', md: '60%' } }} className="item">
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  name="first_name"
                  label="First name*"
                  fullWidth
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  variant="outlined"
                  error={Boolean(formik.touched.first_name && formik.errors.first_name)}
                  helperText={formik.touched.first_name && formik.errors.first_name}
                  InputProps={{ readOnly: Boolean(userData.first_name) }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name="last_name"
                  label="Last name*"
                  fullWidth
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  variant="outlined"
                  error={Boolean(formik.touched.last_name && formik.errors.last_name)}
                  helperText={formik.touched.last_name && formik.errors.last_name}
                  InputProps={{ readOnly: Boolean(userData.last_name) }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="email"
                  type="email"
                  value={formik.values.email}
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth
                  variant="outlined"
                  label="Email address*"
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputProps={{ readOnly: Boolean(userData.email) }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date*"
                    value={formik.values.date}
                    onChange={(value) => handleDateChange(value)}
                    disablePast
                    shouldDisableDate={shouldDisableDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        onBlur={formik.handleBlur('date')}
                        error={Boolean(formik.touched.date && formik.errors.date)}
                        helperText={Boolean(formik.touched.date && formik.errors.date) && getIn(formik.errors, 'date')}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={timezones}
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  value={selectedTimeZone}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Timezone*"
                      variant="outlined"
                      name="time_zone"
                      error={Boolean(formik.touched.time_zone && formik.errors.time_zone)}
                      helperText={formik.touched.time_zone && formik.errors.time_zone}
                    />
                  )}
                  onChange={(e, timezone) => {
                    formik.setFieldValue('timezone', timezone?.value);
                    setSelectedTimeZone(timezone);
                  }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={5}
                  id="message"
                  type="message"
                  value={formik.values.message}
                  name="message"
                  onBlur={formik.handleBlur}
                  fullWidth
                  variant="outlined"
                  label="Message*"
                  error={Boolean(formik.touched.message && formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                  onChange={(event) => {
                    const capitalizedValue = capitalizeFirstLetter(event.target.value);
                    if (capitalizedValue.length <= MSG_CHAR_LIMIT) formik.setFieldValue('message', capitalizedValue);
                    else formik.setFieldError('message', `Only ${MSG_CHAR_LIMIT} characters are allowed`);
                  }}
                  InputProps={{
                    endAdornment: <TextFieldCounter value={formik.values.message.length} maxLength={MSG_CHAR_LIMIT} />
                  }}
                  sx={{ '& .MuiInputLabel-root': { lineHeight: '1.1em' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: 'secondary.main', fontSize: '16px', fontWeight: 500 }}>Available Time Slots</Typography>
                <FormControl error={Boolean(formik.touched.time_slot) && Boolean(getIn(formik.errors, 'time_slot'))} sx={{ width: '100%' }}>
                  <RadioGroup
                    value={formik.values.time_slot}
                    name="time_slot"
                    onChange={(e: any) => formik.setFieldValue('time_slot', e.target.value)}
                  >
                    <div>
                      {timeSlots.map((timeSlot, index) => (
                        <FormControlLabel
                          key={index}
                          value={timeSlot.value}
                          control={
                            <Radio
                              disabled={disableTimeSlot(timeSlot.valueIn24Hr)}
                              icon={
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  sx={{ borderColor: '#858585', color: '#595959' }}
                                  //disabled={disableTimeSlot(timeSlot.valueIn24Hr)}
                                >
                                  <span style={{ whiteSpace: 'nowrap' }}>{timeSlot.label}</span>
                                </Button>
                              }
                              checkedIcon={
                                <Button
                                  variant="outlined"
                                  sx={{ backgroundColor: 'secondary.main', color: 'white' }}
                                  fullWidth
                                  disabled={disableTimeSlot(timeSlot.valueIn24Hr)}
                                >
                                  <span style={{ whiteSpace: 'nowrap' }}>{timeSlot.label}</span>
                                </Button>
                              }
                              sx={{ cursor: disableTimeSlot(timeSlot.valueIn24Hr) ? 'default' : 'pointer', width: '100%' }}
                            />
                          }
                          label=""
                          sx={{ width: '20%', mx: 0 }}
                        />
                      ))}
                    </div>
                  </RadioGroup>
                  <FormHelperText sx={{ ml: '0px' }}>
                    {Boolean(formik.touched.time_slot) && getIn(formik.errors, 'time_slot')}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex' }} justifyContent="center">
                <Button variant="contained" type="submit">
                  Book An Appointment
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <Stack justifyContent="center" textAlign="center" sx={{ width: { xs: '100%', md: '40%' } }} className="item">
            <Box>
              <img src="NeedHelp.svg" alt="Need Help" width="100%" />
            </Box>
          </Stack>
        </Stack>
      </Stack>
      {openPopup && (
        <ConfirmDialog
          open={openPopup}
          onClose={() => {
            formik.resetForm();
            setOpenPopup(false);
          }}
          onConfirm={() => {
            formik.resetForm();
            setOpenPopup(false);
          }}
          header="Successfully Appointment Booked"
          content={
            <>
              <Typography sx={{ color: '#595959' }}>
                <b>Date:</b> {getFormattedDate(formik.values.date)}
              </Typography>
              <Typography sx={{ color: '#595959' }}>
                <b>Time:</b> {formik.values.time_slot}
              </Typography>
            </>
          }
          confirmText="Ok"
          icon={EventAvailableIcon}
          borderColor="#1d8102"
        />
      )}
    </Box>
  );
};
