import axios, { InternalAxiosRequestConfig } from 'axios';
import { isTokenExpired } from './isTokenExpired';
// import { getDecryptData } from './decryption';
import { NOTIFY } from 'shared';
import { useNavigate } from 'react-router';
const code = 'ERR_NETWORK';

const useGenAiAxios = () => {
  const navigate = useNavigate();

  // const { inforaLogin } = useAuth();
  const axiosServices = axios.create({
    baseURL: 'https://genai-api.demo.discovercloud.io/api/v1'
  });
  axiosServices.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    let token = localStorage.getItem('serviceToken');
    if (isTokenExpired(token)) {
      // await inforaLogin();
      token = localStorage.getItem('serviceToken');
    }
    if (token) {
      if (config && config.headers && token) config.headers['Authorization'] = token;
    }
    return config;
  });

  axiosServices.interceptors.response.use(
    (response) => {
      // response.data = getDecryptData(response.data);
      if (response.data.status_code !== 200) {
        if (response.data.status_code === 403) {
          NOTIFY.ClearAll();
          navigate('/login');
        }
        NOTIFY.Error(response.data.message);
        return Promise.reject(response.data);
      }
      return response;
    },
    (error) => {
      if (error.code === code) {
        navigate('/Error/Server');
      }

      return Promise.reject(error);
    }
  );

  return axiosServices;
};

export default useGenAiAxios;
