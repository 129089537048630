import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import { DashboardMenu } from 'menu-items/dashboard';

import { useSelector } from 'store';
import { useConfig, useSubscriptionPlan, useSelectedProduct, useUserData } from 'hooks';
import { HORIZONTAL_MAX_ITEM } from 'config';

// types
import { NavItemType } from 'types/menu';
import { LAYOUT_CONST } from 'types/config';
import { useLocation } from 'react-router';
import menuItems from 'menu-items';
// import { Link } from 'react-router-dom';
// import { menuList } from './menu';
// import { OverrideIcon } from 'types/root';
// import { FormattedMessage } from 'react-intl';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { getUserData } = useUserData();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { UserSubscriptionData } = useSubscriptionPlan();
  const { isTraverse } = useSelectedProduct();
  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);
  const [selectedItems, setSelectedItems] = useState<string | undefined>('');
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [menu, setMenu] = useState<NavItemType[]>([]);
  const [solutionsMenu, setSolutionsMenu] = useState<NavItemType[]>([]);
  const data = isTraverse ? menuItems.traverseItems : menuItems.items;
  const solutionsData =
    getUserData('selectedType') === 'Solutions' && !pathname.includes('/solutions/media') ? menuItems.solutions : menuItems.media;

  useEffect(() => {
    handlerMenuItem();
    // eslint-disable-next-line
  }, [pathname]);

  let getDash = DashboardMenu();
  const handlerMenuItem = () => {
    const isFound = data.some((element) => {
      if (element.id === 'group-dashboard') {
        return true;
      }
      return false;
    });
    if (getDash?.id !== undefined && !isFound) {
      data.splice(0, 0, getDash);
    }

    // Show Children of Operational savings only if there is access
    if (!UserSubscriptionData?.OS_ACCESS) {
      if (data && data.length > 0 && data[data.length - 1]?.children && data[data.length - 1].children?.[0]) {
        const d = data[data.length - 1]!;
        if (d.children && d.children.length > 0) {
          const firstChild = d.children[0]!;
          firstChild.type = 'item';
          firstChild.children = [];
          d.children[0] = firstChild;
          setMenu([...data.slice(0, data.length - 1), d]);
        }
      }
    } else {
      setMenu(data);
      setSolutionsMenu(solutionsData);
    }

    // if (!isTraverse) {
    //   type MenuItem = {
    //     id: string;
    //     title?: string | JSX.Element;
    //     type: string;
    //     url?: string;
    //     icon?: string | OverrideIcon | undefined;
    //     children: MenuItem[];
    //   };
    //   type ChildReference = any;

    //   const storedMenuString = localStorage.getItem('menu');
    //   const input: Record<string, ChildReference> = storedMenuString ? JSON.parse(storedMenuString) : {};

    //   const transformNode = (nodeId: string, reference: ChildReference): MenuItem => {
    //     if (Array.isArray(reference)) {
    //       const ndl = menuList[nodeId];
    //       return {
    //         id: nodeId,
    //         title: <FormattedMessage id={ndl.title} />,
    //         type: ndl.type,
    //         icon: ndl.icon,
    //         url: ndl.url,
    //         children: reference
    //           .filter((c: string) => c !== 'Others')
    //           .map((childId) => {
    //             const nd = menuList[childId];
    //             return {
    //               id: childId,
    //               title: <FormattedMessage id={nd.title} />,
    //               type: nd.type,
    //               icon: nd.icon,
    //               url: nd.url,
    //               children: []
    //             };
    //           })
    //       };
    //     } else {
    //       const children = Object.keys(reference).map((childKey) => {
    //         const childValue = reference[childKey];
    //         return transformNode(childKey, childValue);
    //       });
    //       const nd = menuList[nodeId];
    //       return {
    //         id: nodeId,
    //         title: <FormattedMessage id={nd.title} />,
    //         type: nd.type,
    //         icon: nd.icon,
    //         url: nd.url,
    //         children
    //       };
    //     }
    //   };

    //   const output: MenuItem[] = Object.keys(input).map((key) => {
    //     if (typeof input[key] === 'object') {
    //       const parentID = `${key.replace(' ', '')}-group`;
    //       return {
    //         id: parentID,
    //         type: 'group',
    //         children: [transformNode(key, input[key])]
    //       };
    //     } else {
    //       return transformNode(key, input[key]);
    //     }
    //   });
    //   output.unshift({
    //     id: 'dashboard-group',
    //     type: 'group',
    //     children: [menuList.DashBoard]
    //   } as MenuItem);

    //   setMenu(output);
    // } else {
    //   setMenu(data);
    // }
  };

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = data.length - 1;
  let remItems: NavItemType[] = [];
  let lastItemId: string;

  if (lastItem && lastItem < data.length) {
    lastItemId = data[lastItem - 1].id!;
    lastItemIndex = lastItem - 1;
    remItems = data.slice(lastItem - 1, data.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }));
  }

  const navGroups = menu.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem!}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  const solutionsNavGroups = solutionsMenu.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem!}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        color: '#ffffff',
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
      }}
    >
      <div className="d-flex flex-column justify-content-between">
        {getUserData('selectedType') === 'Solutions' ? <div>{solutionsNavGroups}</div> : <div>{navGroups}</div>}
        {/* <div
          style={{ background: '#E8EEFF', width: `${DRAWER_WIDTH}px`, padding: '12px 0px', position: 'fixed', bottom: 0 }}
          className="d-flex justify-content-center"
        >
          <ButtonBase disableRipple onClick={() => navigate(APP_DEFAULT_PATH)}>
            <img src="/logo.svg" alt="Logo" height={48} />
          </ButtonBase>
        </div> */}
      </div>
    </Box>
  );
};

export default Navigation;
