import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const PageNotFound = Loadable(lazy(() => import('pages/maintenance/404')));
export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <AuthLogin />
    },
    LoginRoutes,
    MainRoutes,
    {
      path: '*',
      element: <PageNotFound />
    }
  ]);
}
