import { LableValuePair } from 'constants/types';
import * as moment from 'moment-timezone';

export const getClientTimezone = () => {
  const currentDate = new Date();

  // Get the client's time zone
  const clientTimeZone = moment.tz.guess();

  // Get the UTC offset for the client's time zone at the current date and time
  const utcOffsetMinutes = moment.tz(currentDate, clientTimeZone).utcOffset();

  // Convert the UTC offset from minutes to hours and minutes
  const offsetHours = Math.floor(utcOffsetMinutes / 60);
  const offsetMinutes = utcOffsetMinutes % 60;

  // Format the UTC offset as a string in the format of "+/-hh:mm"
  const offsetSign = offsetHours < 0 ? '-' : '+';
  const offsetHoursAbs = Math.abs(offsetHours);
  const offsetMinutesAbs = Math.abs(offsetMinutes);
  const utcOffsetString = `${offsetSign}${pad(offsetHoursAbs)}:${pad(offsetMinutesAbs)}`;
  return utcOffsetString;
};

export const getTimeZoneOptions = function (showTimezoneOffset: boolean): LableValuePair[] {
  const timeZones: string[] = moment.tz.names();
  const offsetTmz: LableValuePair[] = [];

  for (const tz of timeZones) {
    const tzOffset: number = moment.tz(tz).utcOffset();
    const offsetHours: number = Math.abs(Math.floor(tzOffset / 60));
    const offsetMinutes: number = Math.abs(tzOffset % 60);
    const offsetSign: string = tzOffset < 0 ? '-' : '+';

    const timeZoneOption: LableValuePair = {
      label: showTimezoneOffset ? `${tz} (UTC${offsetSign}${pad(offsetHours)}:${pad(offsetMinutes)})` : tz,
      value: tz
    };

    offsetTmz.push(timeZoneOption);
  }

  return offsetTmz;
};

function pad(num: number): string {
  return num.toString().padStart(2, '0');
}
