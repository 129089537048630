import Products from 'enums/products';
import useUserData from './useUserData';

export const useSelectedProduct = () => {
  const { getUserData } = useUserData();

  const isTrekora = getUserData('selectedType') === Products.TREKORA;
  const isTraverse = getUserData('selectedType') === Products.TRAVERSE;
  const isSAP = getUserData('selectedType') === Products.SAPASSIST;
  const isInfora = getUserData('selectedType') === Products.INFORA;
  const isSolutions = getUserData('selectedType') === Products.SOLUTIONS;
  const isVideoSummarize = getUserData('selectedType') === Products.VIDEO_SUMMARIZE;
  const isHotTopics = getUserData('selectedType') === Products.HOT_TOPICS;

  return { isTrekora, isTraverse, isSAP, isInfora, isSolutions, isVideoSummarize, isHotTopics };
};
