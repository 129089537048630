import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
// import Button from 'themes/overrides/Button';
import { Button } from '@mui/material';
interface IResendProps {
  countdownTimer?: number;
  onResend: Function;
}

const getTicker = (countdownTimer: number) => Date.now() + countdownTimer * 1000;

export const Resend: React.FC<IResendProps> = (props) => {
  const [timerTime, setTimerTime] = React.useState(getTicker(props.countdownTimer || 30));
  const handleResend = () => {
    setTimerTime(getTicker(props.countdownTimer || 30));
    props.onResend();
  };

  const Resend = (props: any) => (
    <Button disableElevation fullWidth size="large" variant="contained"
      className="primary-button w-100 prt-5" onClick={handleResend}
    >
      Resend
    </Button>
  );

  const countdownRenderer = (renderProps: any) => {
    if (renderProps.completed) {
      return <Resend {...props} />;
    } else {
      return (
        <span>
          {zeroPad(renderProps.minutes)}:{zeroPad(renderProps.seconds)}
        </span>
      );
    }
  };

  return (
    <div>
      <Countdown key={timerTime} date={timerTime} renderer={countdownRenderer} />
    </div>
  );
};
