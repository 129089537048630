import { TextField } from '@mui/material';

const capitalizeEachWord = (str: string): string => {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
};

export const Component = ({ handleBlur, values, touched, errors, setFieldValue, name, ...rest }: any) => {
  const handleChange = (event: any) => {
    const capitalizedValue = capitalizeEachWord(event.target.value);
    setFieldValue(name, capitalizedValue);
  };

  return (
    <TextField
      {...rest}
      name={name}
      value={values[name]}
      onBlur={handleBlur}
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      size="medium"
      onChange={handleChange}
      fullWidth
    />
  );
};
