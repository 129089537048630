import { IHeadCell } from './tableTypes';

export const roleHeaderCells = [
  {
    Header: 'Email',
    accessor: 'email_of_invitee',
    dataType: 'text'
  },
  {
    Header: 'Name',
    accessor: 'invitee_name',
    dataType: 'text'
  },
  {
    Header: 'Role',
    accessor: 'invitee_role',
    dataType: 'select'
  },
  {
    Header: 'Status',
    accessor: 'status',
    dataType: 'text'
  }
];

export const commercialHeaderCells: IHeadCell[] = [
  {
    id: 'savings_type',
    align: 'left',
    disablePadding: false,
    label: 'Savings Type',
    filter: false
  },
  {
    id: 'potential_savings',
    align: 'left',
    disablePadding: true,
    label: 'Potential Savings',
    filter: false,
    format: 'currency'
  },
  {
    id: 'achieved_savings',
    align: 'left',
    disablePadding: false,
    label: 'Achieved Savings',
    filter: false,
    format: 'currency'
  }
];

export const commercialRecommendationHeaderCells: IHeadCell[] = [
  {
    id: 'upfront_cost',
    align: 'left',
    disablePadding: false,
    label: 'Upfront Cost',
    format: 'currency'
  },
  {
    id: 'year',
    align: 'left',
    disablePadding: false,
    label: 'Year'
  },
  {
    id: 'month',
    align: 'left',
    disablePadding: false,
    label: 'Month'
  },
  {
    id: 'estimated_savings',
    align: 'left',
    disablePadding: false,
    label: 'Estimated Savings',
    format: 'currency'
  }
];

export const rdsRecommendationHeaderCells: IHeadCell[] = [
  {
    id: 'term_in_years',
    align: 'left',
    disablePadding: false,
    label: 'Term',
    filter: 'includes'
  },
  {
    id: 'payment_option',
    align: 'left',
    disablePadding: false,
    label: 'Payment Option',
    filter: 'includes'
  },
  {
    id: 'lookback_period_in_days',
    align: 'left',
    disablePadding: false,
    label: 'Lookback Period(In Days)',
    filter: 'includes'
  },
  {
    id: 'db_instance_type',
    align: 'left',
    disablePadding: false,
    label: 'RDS Instance Type'
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'database_engine',
    align: 'left',
    disablePadding: false,
    label: 'Database Engine'
  },
  {
    id: 'license',
    align: 'left',
    disablePadding: false,
    label: 'License'
  },
  {
    id: 'current_generation',
    align: 'left',
    disablePadding: false,
    label: 'Current Generation'
  },
  {
    id: 'upfront_cost',
    align: 'left',
    disablePadding: false,
    label: 'Upfront Cost',
    format: 'currency'
  },
  {
    id: 'estimated_savings',
    align: 'left',
    disablePadding: false,
    label: 'Estimated Savings',
    format: 'currency'
  }
];

export const openSearchRecommendationHeaderCells: IHeadCell[] = [
  {
    id: 'term_in_years',
    align: 'left',
    disablePadding: false,
    label: 'Term',
    filter: 'includes'
  },
  {
    id: 'payment_option',
    align: 'left',
    disablePadding: false,
    label: 'Payment Option',
    filter: 'includes'
  },
  {
    id: 'lookback_period_in_days',
    align: 'left',
    disablePadding: false,
    label: 'Lookback Period(In Days)',
    filter: 'includes'
  },
  {
    id: 'os_instance_type',
    align: 'left',
    disablePadding: false,
    label: 'Instance Type'
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'current_generation',
    align: 'left',
    disablePadding: false,
    label: 'Current Generation'
  },
  {
    id: 'upfront_cost',
    align: 'left',
    disablePadding: false,
    label: 'Upfront Cost',
    format: 'currency'
  },
  {
    id: 'estimated_savings',
    align: 'left',
    disablePadding: false,
    label: 'Estimated Savings',
    format: 'currency'
  }
];

export const elasticRecommendationHeaderCells: IHeadCell[] = [
  {
    id: 'term_in_years',
    align: 'left',
    disablePadding: false,
    label: 'Term',
    filter: 'includes'
  },
  {
    id: 'payment_option',
    align: 'left',
    disablePadding: false,
    label: 'Payment Option',
    filter: 'includes'
  },
  {
    id: 'lookback_period_in_days',
    align: 'left',
    disablePadding: false,
    label: 'Lookback Period(In Days)',
    filter: 'includes'
  },
  {
    id: 'ec_instance_type',
    align: 'left',
    disablePadding: false,
    label: 'Instance Type'
  },
  {
    id: 'cache_engine',
    align: 'left',
    disablePadding: false,
    label: 'Cache Engine'
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'current_generation',
    align: 'left',
    disablePadding: false,
    label: 'Current Generation'
  },
  {
    id: 'upfront_cost',
    align: 'left',
    disablePadding: false,
    label: 'Upfront Cost',
    format: 'currency'
  },
  {
    id: 'estimated_savings',
    align: 'left',
    disablePadding: false,
    label: 'Estimated Savings',
    format: 'currency'
  }
];

export const redshiftRecommendationHeaderCells: IHeadCell[] = [
  {
    id: 'term_in_years',
    align: 'left',
    disablePadding: false,
    label: 'Term',
    filter: 'includes'
  },
  {
    id: 'payment_option',
    align: 'left',
    disablePadding: false,
    label: 'Payment Option',
    filter: 'includes'
  },
  {
    id: 'lookback_period_in_days',
    align: 'left',
    disablePadding: false,
    label: 'Lookback Period(In Days)',
    filter: 'includes'
  },
  {
    id: 'rs_instance_type',
    align: 'left',
    disablePadding: false,
    label: 'Instance Type'
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'current_generation',
    align: 'left',
    disablePadding: false,
    label: 'Current Generation'
  },
  {
    id: 'upfront_cost',
    align: 'left',
    disablePadding: false,
    label: 'Upfront Cost',
    format: 'currency'
  },
  {
    id: 'estimated_savings',
    align: 'left',
    disablePadding: false,
    label: 'Estimated Savings',
    format: 'currency'
  }
];

export const amdRecommendationHeaderCells: IHeadCell[] = [
  {
    id: 'instance_id',
    align: 'left',
    disablePadding: false,
    label: 'Instance ID'
  },
  {
    id: 'current_instance_type',
    align: 'left',
    disablePadding: false,
    label: 'Current Instance Type'
  },
  {
    id: 'current_on_demand_spend',
    align: 'left',
    disablePadding: false,
    label: 'Current On Demand Spend',
    format: 'currency'
  },

  {
    id: 'amd_instance_type',
    align: 'left',
    disablePadding: false,
    label: 'AMD Instance Type'
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'new_on_demand_cost',
    align: 'left',
    disablePadding: false,
    label: 'New On Demand Cost',
    format: 'currency'
  },
  {
    id: 'operating_system',
    align: 'left',
    disablePadding: false,
    label: 'Operating System'
  },
  {
    id: 'amd_modernization_savings',
    align: 'left',
    disablePadding: false,
    label: 'AMD Migration Savings',
    format: 'currency'
  }
];

export const ebsRecommendationHeaderCells: IHeadCell[] = [
  {
    id: 'volume_id',
    align: 'left',
    disablePadding: false,
    label: 'Volume Id'
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'current_ebs_volume_cost',
    align: 'left',
    disablePadding: false,
    label: 'Current EBS Volume Cost',
    format: 'currency'
  },
  {
    id: 'current_ebs_volume_size_gib',
    align: 'left',
    disablePadding: false,
    label: 'Current EBS Volume Size Gib'
  },
  {
    id: 'recommended_rbs_volume_type',
    align: 'left',
    disablePadding: false,
    label: 'Recommended EBS Volume Type'
  },
  {
    id: 'new_ebs_cost',
    align: 'left',
    disablePadding: false,
    label: 'New EBS Cost',
    format: 'currency'
  },
  {
    id: 'ebs_modernization_savings',
    align: 'left',
    disablePadding: false,
    label: 'EBS Modernization Savings',
    format: 'currency'
  }
];

export const dataTransferRecommendationHeaderCells: IHeadCell[] = [
  {
    id: 'usage_type',
    align: 'left',
    disablePadding: false,
    label: 'Usage Type'
  },
  {
    id: 'usage_qty',
    align: 'left',
    disablePadding: false,
    label: 'Usage (GBs)'
  },
  {
    id: 'usage_cost',
    align: 'left',
    disablePadding: false,
    label: 'Current Cost',
    format: 'currency'
  },
  {
    id: 'potential_savings',
    align: 'left',
    disablePadding: false,
    label: 'Potential Savings',
    format: 'currency'
  }
];

export const runningHoursRecommendationHeaderCells: IHeadCell[] = [
  {
    id: 'aws_resource_id',
    align: 'left',
    disablePadding: false,
    label: 'Resource ID'
  },
  {
    id: 'usage_qty',
    align: 'left',
    disablePadding: false,
    label: 'Usage (Hours)',
    format: 'number'
  },
  {
    id: 'usage_cost',
    align: 'left',
    disablePadding: false,
    label: 'Current Cost',
    format: 'currency'
  },
  {
    id: 'potential_savings',
    align: 'left',
    disablePadding: false,
    label: 'Potential Savings',
    format: 'currency'
  }
];

export const s3OptApiRecommHeaderCells: IHeadCell[] = [
  {
    id: 'aws_resource_id',
    align: 'left',
    disablePadding: false,
    label: 'S3 Bucket Name'
  },
  {
    id: 'usage_qty',
    align: 'left',
    disablePadding: false,
    label: 'Usage (Requests)',
    format: 'number'
  },
  {
    id: 'usage_cost',
    align: 'left',
    disablePadding: false,
    label: 'Current Cost',
    format: 'currency'
  },
  {
    id: 'potential_savings',
    align: 'left',
    disablePadding: false,
    label: 'Potential Savings',
    format: 'currency'
  }
];

export const s3OptStorageRecommHeaderCells: IHeadCell[] = [
  {
    id: 'aws_resource_id',
    align: 'left',
    disablePadding: false,
    label: 'S3 Bucket Name'
  },
  {
    id: 'usage_qty',
    align: 'left',
    disablePadding: false,
    label: 'Usage (GBs)',
    format: 'number'
  },
  {
    id: 'usage_cost',
    align: 'left',
    disablePadding: false,
    label: 'Current Cost',
    format: 'currency'
  },
  {
    id: 'potential_savings',
    align: 'left',
    disablePadding: false,
    label: 'Potential Savings',
    format: 'currency'
  }
];

export const gravitonRecommendationHeaderCells: IHeadCell[] = [
  {
    id: 'instance_id',
    align: 'left',
    disablePadding: false,
    label: 'Instance ID'
  },
  {
    id: 'current_instance_type',
    align: 'left',
    disablePadding: false,
    label: 'Current Instance Type'
  },
  {
    id: 'current_on_demand_spend',
    align: 'left',
    disablePadding: false,
    label: 'Current On Demand Spend',
    format: 'currency'
  },

  {
    id: 'graviton_instance_type',
    align: 'left',
    disablePadding: false,
    label: 'Graviton Instance Type'
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'new_on_demand_cost',
    align: 'left',
    disablePadding: false,
    label: 'New On Demand Cost',
    format: 'currency'
  },
  {
    id: 'operating_system',
    align: 'left',
    disablePadding: false,
    label: 'Operating System'
  },
  {
    id: 'graviton_modernization_savings',
    align: 'left',
    disablePadding: false,
    label: 'Graviton Migration Savings',
    format: 'currency'
  }
];

export const savingsPlanRecommendationHeaderCells: IHeadCell[] = [
  {
    id: 'savings_plan_term',
    align: 'left',
    disablePadding: false,
    label: 'Savings Plan Term',
    filter: 'includes'
  },
  {
    id: 'payment_option',
    align: 'left',
    disablePadding: false,
    label: 'Payment Option',
    filter: 'includes'
  },
  {
    id: 'lookback_period_in_days',
    align: 'left',
    disablePadding: false,
    label: 'Lookback Period(In Days)',
    filter: 'includes'
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'instance_family',
    align: 'left',
    disablePadding: false,
    label: 'Instance Family'
  },
  {
    id: 'upfront_cost',
    align: 'left',
    disablePadding: false,
    label: 'Upfront Cost',
    format: 'currency'
  },
  {
    id: 'hourly_commitment_to_purchase',
    align: 'left',
    disablePadding: false,
    label: 'Hourly Commitment To Purchase',
    format: 'currency'
  },
  {
    id: 'estimated_savings_amount',
    align: 'left',
    disablePadding: false,
    label: 'Estimated Savings Amount',
    format: 'currency'
  }
];

export const resourceTypesHeaderCells: IHeadCell[] = [
  {
    id: 'icon',
    align: 'left',
    disablePadding: false,
    label: 'Icon',
    disableSortBy: true,
    width: 70,
    maxWidth: 70,
    minWidth: 70
  },
  {
    id: 'type',
    align: 'left',
    disablePadding: false,
    label: 'Type',
    ellipsis: true,
    width: 500,
    maxWidth: 600,
    minWidth: 500
  },
  {
    id: 'resourceCount',
    align: 'left',
    disablePadding: false,
    label: 'Count',
    width: '100%',
    maxWidth: 500,
    minWidth: 120
  }
];

export const resourcesHeaderCells: IHeadCell[] = [
  {
    id: 'icon',
    align: 'left',
    disablePadding: false,
    label: '',
    disableSortBy: true,
    width: 70,
    maxWidth: 70,
    minWidth: 70
  },
  {
    id: 'title',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    ellipsis: true,
    width: 200,
    maxWidth: 600,
    minWidth: 200
  },
  {
    id: 'resourceType',
    align: 'left',
    disablePadding: false,
    label: 'Type',
    ellipsis: true,
    width: 200,
    maxWidth: 600,
    minWidth: 200
  },
  {
    id: 'accountId',
    align: 'left',
    disablePadding: false,
    label: 'Account Id',
    width: 150,
    maxWidth: 200,
    minWidth: 150
  },
  {
    id: 'arnName',
    align: 'left',
    disablePadding: false,
    label: 'ARN',
    ellipsis: true,
    width: 200,
    maxWidth: 600,
    minWidth: 200
  },
  { id: 'awsRegion', align: 'left', disablePadding: false, label: 'Region', width: '100%', maxWidth: 500, minWidth: 120 }
];

export const accountsHeaderCells: IHeadCell[] = [
  {
    id: 'accountId',
    align: 'left',
    disablePadding: false,
    label: 'Account Id',
    width: 150,
    maxWidth: 200,
    minWidth: 150
  },
  {
    id: 'accountName',
    align: 'left',
    disablePadding: false,
    label: 'Account name',
    width: 400,
    maxWidth: 600,
    minWidth: 400,
    ellipsis: true
  },
  {
    id: 'regionCount',
    align: 'left',
    disablePadding: false,
    label: 'Regions',
    disableSortBy: true,
    width: '100%',
    maxWidth: 500,
    minWidth: 120
  }
];

export const regionHeaderCells: IHeadCell[] = [
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Region',
    disableSortBy: true,
    width: 400,
    maxWidth: 600,
    minWidth: 400,
    ellipsis: true
  },
  {
    id: 'accountId',
    align: 'left',
    disablePadding: false,
    label: 'Account Id',
    disableSortBy: true,
    width: 150,
    maxWidth: 200,
    minWidth: 150
  },
  {
    id: 'count',
    align: 'left',
    disablePadding: false,
    label: 'Resources',
    disableSortBy: true,
    width: '100%',
    maxWidth: 500,
    minWidth: 120
  }
];

export const importRegionsHeaderCells: IHeadCell[] = [
  {
    id: 'accountId',
    align: 'left',
    disablePadding: false,
    label: 'Account Id',
    width: 150,
    maxWidth: 200,
    minWidth: 150
  },
  {
    id: 'accountName',
    align: 'left',
    disablePadding: false,
    label: 'Account name',
    ellipsis: true,
    width: 400,
    maxWidth: 600,
    minWidth: 400
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region name',
    disableSortBy: true,
    width: '100%',
    maxWidth: 500,
    minWidth: 120
  }
];

export const manageDiagramHeaderCells: IHeadCell[] = [
  {
    id: 'labelName',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    ellipsis: true,
    width: 400,
    maxWidth: 600,
    minWidth: 400
  },
  {
    id: 'visibility',
    align: 'left',
    disablePadding: false,
    label: 'Visibility',
    disableSortBy: true,
    width: 150,
    maxWidth: 200,
    minWidth: 150
  },
  {
    id: 'lastModified',
    align: 'left',
    disablePadding: false,
    label: 'Last modified',
    disableSortBy: true,
    width: '100%',
    maxWidth: 500,
    minWidth: 120,
    ellipsis: true
  }
];

export const viewAwsAccountsHeaderCells: IHeadCell[] = [
  {
    id: 'accountId',
    align: 'left',
    disablePadding: false,
    label: 'Account Id',
    disableSortBy: true
  }
];

export const viewRegionsHeaderCells: IHeadCell[] = [
  {
    id: 'id',
    align: 'left',
    disablePadding: false,
    label: 'Id',
    disableSortBy: true
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    disableSortBy: true
  }
];

export const viewResourceTypesHeaderCells: IHeadCell[] = [
  {
    id: 'type',
    align: 'left',
    disablePadding: false,
    label: 'Type',
    disableSortBy: true
  }
];

export const underUtilizedEC2HeaderCells: IHeadCell[] = [
  {
    id: 'account_id',
    align: 'left',
    disablePadding: false,
    label: 'Account Id'
  },
  {
    id: 'instance_id',
    align: 'left',
    disablePadding: false,
    label: 'Instance Id'
  },
  {
    id: 'instance_name',
    align: 'left',
    disablePadding: false,
    label: 'Instance name'
  },
  {
    id: 'instance_type',
    align: 'left',
    disablePadding: false,
    label: 'Instance type'
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'estimated_monthly_savings',
    align: 'left',
    disablePadding: false,
    label: 'Potential monthly savings',
    format: 'currency'
  }
];

export const underUtilizedEBSHeaderCells: IHeadCell[] = [
  {
    id: 'account_id',
    align: 'left',
    disablePadding: false,
    label: 'Account Id'
  },
  {
    id: 'volume_id',
    align: 'left',
    disablePadding: false,
    label: 'Volume Id'
  },
  {
    id: 'volume_type',
    align: 'left',
    disablePadding: false,
    label: 'Volume type'
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'volume_size',
    align: 'left',
    disablePadding: false,
    label: 'Volume size'
  },
  {
    id: 'monthly_storage_cost',
    align: 'left',
    disablePadding: false,
    label: 'Potential monthly savings',
    format: 'currency'
  }
];
export const underUtilizedELBHeaderCells: IHeadCell[] = [
  {
    id: 'account_id',
    align: 'left',
    disablePadding: false,
    label: 'Account Id'
  },
  {
    id: 'resource_id',
    align: 'left',
    disablePadding: false,
    label: 'Resource ID'
  },
  {
    id: 'region',
    align: 'left',
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'estimated_monthly_savings',
    align: 'left',
    disablePadding: false,
    label: 'Potential monthly savings',
    format: 'currency'
  }
];

export const ResourcesCosts: IHeadCell[] = [
  {
    id: 'resource',
    label: 'Resource',
    align: 'left',
    disablePadding: false,
    disableSortBy: true,
    width: 300,
    maxWidth: 600,
    minWidth: 300,
    ellipsis: true
  },
  {
    id: 'cost',
    label: 'Estimated cost',
    align: 'left',
    disablePadding: false,
    width: 200,
    maxWidth: 300,
    minWidth: 200,
    ellipsis: true,
    format: 'currency'
  },
  {
    id: 'line_item_usage_account_id',
    label: 'Account Id',
    align: 'left',
    disablePadding: false,
    disableSortBy: true,
    width: 150,
    maxWidth: 200,
    minWidth: 150
  },
  {
    id: 'region',
    label: 'Region',
    align: 'left',
    disablePadding: false,
    disableSortBy: true,
    width: '100%',
    maxWidth: 500,
    minWidth: 120,
    ellipsis: true
  }
];

export const CostReportResourcesHeaderCells: IHeadCell[] = [
  {
    id: 'icon',
    align: 'left',
    disablePadding: false,
    label: 'Icon',
    disableSortBy: true,
    width: 70,
    maxWidth: 70,
    minWidth: 70
  },
  {
    id: 'resource',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    width: 200,
    maxWidth: 600,
    minWidth: 200,
    ellipsis: true
  },
  {
    id: 'service',
    align: 'left',
    disablePadding: false,
    label: 'Billing Service',
    width: 200,
    maxWidth: 600,
    minWidth: 200,
    ellipsis: true
  },
  {
    id: 'type',
    align: 'left',
    disablePadding: false,
    label: 'Type',
    width: 200,
    maxWidth: 600,
    minWidth: 200,
    ellipsis: true
  },
  {
    id: 'cost',
    label: 'Estimated Cost ($)',
    format: 'currency',
    align: 'left',
    disablePadding: false,
    width: 150,
    maxWidth: 200,
    minWidth: 150,
    ellipsis: true
  },
  {
    id: 'accountId',
    label: 'Account Id',
    align: 'left',
    disablePadding: false,
    disableSortBy: true,
    width: 150,
    maxWidth: 200,
    minWidth: 150
  },
  {
    id: 'resourceArn',
    label: 'ARN',
    align: 'left',
    disablePadding: false,
    disableSortBy: true,
    ellipsis: true,
    width: 400,
    maxWidth: 600,
    minWidth: 400
  },
  {
    id: 'region',
    label: 'Region',
    align: 'left',
    disablePadding: false,
    width: '100%',
    maxWidth: 500,
    minWidth: 120,
    ellipsis: true
  }
];
export const sapAssessment: IHeadCell[] = [
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Name',
    ellipsis: true,
    width: 500,
    maxWidth: 800,
    minWidth: 500
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'Status',
    width: '100%',
    maxWidth: 500,
    minWidth: 120,
    ellipsis: true
  }
];

export const casesHeaderCells: IHeadCell[] = [
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Case Name',
    disableSortBy: true
  },
  {
    id: 'caseId',
    align: 'left',
    disablePadding: false,
    label: 'Case ID',
    disableSortBy: true
  },
  {
    id: 'dateCreated',
    align: 'left',
    disablePadding: false,
    label: 'Creation Date',
    disableSortBy: true
  },
  {
    id: 'numberOfDocuments',
    align: 'left',
    disablePadding: false,
    label: 'Number of documents',
    disableSortBy: true
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'Status',
    disableSortBy: true
  }
];

export const documentHeaderCells: IHeadCell[] = [
  {
    id: 'docName',
    align: 'left',
    disablePadding: false,
    label: 'Document Name',
    disableSortBy: true
  },
  {
    id: 'docId',
    align: 'left',
    disablePadding: false,
    label: 'Document ID',
    disableSortBy: true
  },
  {
    id: 'dateCreated',
    align: 'left',
    disablePadding: false,
    label: 'Creation Date',
    disableSortBy: true
  },
  {
    id: 'fileType',
    align: 'left',
    disablePadding: false,
    label: 'File Type',
    disableSortBy: true
  }
];

export const uploadedFileHeaderCells: IHeadCell[] = [
  {
    id: 'fileName',
    align: 'left',
    disablePadding: false,
    label: 'File Name',
    disableSortBy: true
  },
  {
    id: 'fileType',
    align: 'left',
    disablePadding: false,
    label: 'File Type',
    disableSortBy: true
  },
  {
    id: 'fileSize',
    align: 'left',
    disablePadding: false,
    label: 'File Size',
    disableSortBy: true
  },
  {
    id: 'uploadedDate',
    align: 'left',
    disablePadding: false,
    label: 'Uploaded Date',
    disableSortBy: true
  }
];

export const videoHeaderCells: IHeadCell[] = [
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'File Name',
    filter: false,
    width: '100%'
  },
  {
    id: 'downloadPdf',
    align: 'left',
    disablePadding: true,
    label: 'Download PDF',
    filter: false,
    width: '100%'
  }
];
