import bcrypt from 'bcryptjs';
import CryptoJS from 'crypto-js';
export const useEncryption = () => {
  const salt = '$2a$10$kIGL2c94vTO0LLnUn.Pdw.';
  const getEncryptedText = (plainText: string) => {
    const encryptedText = bcrypt.hashSync(plainText, salt);
    return encryptedText;
  };
  const convertToEncryptedText = (plainText: string) => {
    const encryptedText = CryptoJS.AES.encrypt(plainText, salt!).toString();
    return encryptedText;
  };
  const getCryptoDecryptedText = (encryptedText: string) => {
    var bytes = CryptoJS.AES.decrypt(encryptedText, salt!);
    return bytes.toString(CryptoJS.enc.Utf8);
  };
  return { getEncryptedText, convertToEncryptedText, getCryptoDecryptedText };
};
