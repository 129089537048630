import { createSlice } from '@reduxjs/toolkit';
import { fileUploadDetails } from 'constants/types';

const initialState: fileUploadDetails = {
  isFileUploading: false
};

const fileUpload = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    fileUploading(state, action) {
      state.isFileUploading = action.payload;
    }
  }
});

export default fileUpload.reducer;

export const { fileUploading } = fileUpload.actions;
