import { NavItemType } from 'types/menu';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import PublicIcon from '@mui/icons-material/Public';

// icons
const icons = {
  MonetizationOnIcon,
  HealthAndSafetyIcon,
  SubscriptionsIcon,
  BeachAccessIcon,
  PublicIcon
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //
const financialServices: NavItemType = {
  id: 'financial-services',
  type: 'group',
  children: [
    {
      id: 'financial-services',
      title: 'Financial Services',
      type: 'collapse',
      icon: icons.MonetizationOnIcon,
      children: [
        {
          id: 'banking',
          title: 'Banking',
          url: '/solutions/banking',
          type: 'item'
        },
        {
          id: 'rating-pricing',
          title: 'Invoices',
          url: '/solutions/invoices',
          type: 'item'
        }
        // {
        //   id: 'market',
        //   title: 'Capital Market',
        //   url: '/solutions/capital-market',
        //   type: 'item'
        // }
      ]
    }
  ]
};

const insurance: NavItemType = {
  id: 'insurance',
  type: 'group',
  children: [
    {
      id: 'insurance',
      title: 'Insurance',
      type: 'collapse',
      icon: icons.BeachAccessIcon,
      children: [
        {
          id: 'medical-insurance',
          title: 'Medical Insurance',
          url: '/solutions/medical-insurance',
          type: 'item'
        },
        {
          id: 'vehicle-insurance',
          title: 'Vehicle Insurance',
          url: '/solutions/vehicle-insurance',
          type: 'item'
        },
        {
          id: 'life-insurance',
          title: 'Life Insurance',
          url: '/solutions/life-insurance',
          type: 'item'
        }
      ]
    }
  ]
};

const healthCare: NavItemType = {
  id: 'health-care',
  type: 'group',
  children: [
    {
      id: 'health-care',
      title: 'Medical & Health Care',
      type: 'collapse',
      icon: icons.HealthAndSafetyIcon,
      children: [
        {
          id: 'discharge-summaries',
          title: 'Discharge Summaries',
          url: '/solutions/discharge-summaries',
          type: 'item'
        },
        // {
        //   id: 'medical-bills',
        //   title: 'Medical Bills',
        //   url: '/solutions/medical-bills',
        //   type: 'item'
        // },
        {
          id: 'medical-reports',
          title: 'Medical Reports',
          url: '/solutions/medical-reports',
          type: 'item'
        },
        {
          id: 'treatment-reports',
          title: 'Patient Treatment Reports',
          url: '/solutions/treatment-reports',
          type: 'item'
        }
      ]
    }
  ]
};

const media: NavItemType = {
  id: 'media-entertainment',
  type: 'group',
  children: [
    {
      id: 'media-entertainment',
      title: 'Media & Entertainment',
      type: 'collapse',
      icon: icons.SubscriptionsIcon,
      children: [
        {
          id: 'text',
          title: <>News Articles</>,
          url: '/solutions/media/text',
          type: 'item'
        },
        {
          id: 'audio',
          title: <>Expand Audio Learnings</>,
          url: '/solutions/media/audio',
          type: 'item'
        },

        {
          id: 'video',
          title: <>Video Verse</>,
          url: '/solutions/media/video-verse',
          type: 'item'
        }
      ]
    }
  ]
};

const publicSector: NavItemType = {
  id: 'public-sector',
  type: 'group',
  children: [
    {
      id: 'public-sector',
      title: 'Public Sector',
      type: 'item',
      icon: icons.PublicIcon,
      url: '/solutions/public-sector'
    }
  ]
};

export { financialServices, insurance, healthCare, media, publicSector };
