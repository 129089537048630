import { forwardRef, useEffect, ForwardRefExoticComponent, RefAttributes, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project import
import Dot from 'components/@extended/Dot';
import { useConfig } from 'hooks';
import { dispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/reducers/menu';

// types
import { LinkTarget, NavItemType } from 'types/menu';
import { LAYOUT_CONST } from 'types/config';
import './navitem.css';
// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const menu = useSelector((state) => state.menu);
  const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { drawerOpen } = menu;
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { menuOrientation } = useConfig();
  const [isSelected, setIsSelected] = useState(false);

  // active menu item on page load
  useEffect(() => {
    if (pathname.toLowerCase() === item?.url?.toLowerCase() || pathname?.toLowerCase().startsWith(item.url?.toLowerCase() || '')) {
      dispatch(activeItem({ openItem: [item.id] }));
      setIsSelected(true);
    } else {
      dispatch(activeItem({ openItem: [''] }));
      setIsSelected(false);
    }
    // eslint-disable-next-line
  }, [pathname]);

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = { component: forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />) };
  if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const Icon = item.icon!;

  // const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem', color: isSelected ? '#ffffff' : 'secondary' }} />
  ) : (
    false
  );

  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
  const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : '#FFFFFF';
  const iconSelectedSmColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : 'secondary.main';

  return (
    <>
      {menuOrientation === LAYOUT_CONST.VERTICAL_LAYOUT || downLG ? (
        <div role="listitem">
          <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            selected={isSelected}
            sx={{
              zIndex: 1201,
              pl: drawerOpen ? `${level * 28}px` : 1.5,
              py: !drawerOpen && level === 1 ? 1.25 : 1,
              ...(drawerOpen && {
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'grey.100'
                },
                '&.Mui-selected': {
                  background: 'linear-gradient(89.91deg, #9027E2 0.04%, #00248C 99.89%)',
                  borderRight: `2px solid ${theme.palette.primary.main}`,
                  color: iconSelectedColor,
                  '&:hover': {
                    color: iconSelectedColor,
                    bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter'
                  }
                }
              }),
              ...(!drawerOpen && {
                '&:hover': {
                  bgcolor: 'transparent'
                },
                '&.Mui-selected': {
                  '&:hover': {
                    bgcolor: 'transparent'
                  },
                  bgcolor: 'transparent'
                }
              })
            }}
            {...(matchDownLg && {
              onClick: () => dispatch(openDrawer(false))
            })}
          >
            {itemIcon && (
              <ListItemIcon
                sx={{
                  minWidth: 28,
                  color: isSelected ? iconSelectedColor : textColor,
                  ...(!drawerOpen && {
                    borderRadius: 1.5,
                    width: 36,
                    height: 36,
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      bgcolor: theme.palette.mode === 'dark' ? 'grey.300' : 'grey.100'
                    }
                  }),
                  ...(!drawerOpen &&
                    isSelected && {
                      background: 'linear-gradient(89.91deg, #9027E2 0.04%, #00248C 99.89%)',
                      '&:hover': {
                        bgcolor: theme.palette.mode === 'dark' ? 'primary.darker' : 'primary.lighter'
                      }
                    })
                }}
              >
                {itemIcon}
              </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
              <ListItemText
                primary={
                  <Typography variant="h6" sx={{ color: isSelected ? (drawerOpen ? iconSelectedColor : iconSelectedSmColor) : textColor }}>
                    {item.title}
                  </Typography>
                }
              />
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
              <Chip
                color={item.chip.color}
                variant={item.chip.variant}
                size={item.chip.size}
                label={item.chip.label}
                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
              />
            )}
          </ListItemButton>
        </div>
      ) : (
        <div role="listitem">
          <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            selected={isSelected}
            sx={{
              zIndex: 1201,
              ...(drawerOpen && {
                '&:hover': {
                  bgcolor: 'transparent'
                },
                '&.Mui-selected': {
                  bgcolor: 'transparent',
                  color: iconSelectedColor,
                  '&:hover': {
                    color: iconSelectedColor,
                    bgcolor: 'transparent'
                  }
                }
              }),
              ...(!drawerOpen && {
                '&:hover': {
                  bgcolor: 'transparent'
                },
                '&.Mui-selected': {
                  '&:hover': {
                    bgcolor: 'transparent'
                  },
                  bgcolor: 'transparent'
                }
              })
            }}
          >
            {itemIcon && (
              <ListItemIcon
                sx={{
                  minWidth: 36,
                  ...(!drawerOpen && {
                    borderRadius: 1.5,
                    width: 36,
                    height: 36,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    '&:hover': {
                      bgcolor: 'transparent'
                    }
                  }),
                  ...(!drawerOpen &&
                    isSelected && {
                      background: 'linear-gradient(89.91deg, #9027E2 0.04%, #00248C 99.89%)',
                      '&:hover': {
                        bgcolor: 'transparent'
                      }
                    })
                }}
              >
                {itemIcon}
              </ListItemIcon>
            )}

            {!itemIcon && (
              <ListItemIcon
                sx={{
                  color: isSelected ? 'primary.main' : 'secondary.main',
                  ...(!drawerOpen && {
                    borderRadius: 1.5,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    '&:hover': {
                      bgcolor: 'transparent'
                    }
                  }),
                  ...(!drawerOpen &&
                    isSelected && {
                      bgcolor: 'transparent',
                      '&:hover': {
                        bgcolor: 'transparent'
                      }
                    })
                }}
              >
                <Dot size={4} sx={{ color: isSelected ? '#ffffff' : 'secondary' }} />{' '}
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Typography variant="h6" color="inherit">
                  {item.title}
                </Typography>
              }
            />
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
              <Chip
                color={item.chip.color}
                variant={item.chip.variant}
                size={item.chip.size}
                label={item.chip.label}
                avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
              />
            )}
          </ListItemButton>
        </div>
      )}
    </>
  );
};

export default NavItem;
