// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import filters from './filters';
import awsSetup from './awsSetup';
import awsAccountDetails from './awsAccountDetails';
import chat from './chat';
import fileUpload from './fileUpload';
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  filters,
  awsSetup,
  awsAccountDetails,
  chat,
  fileUpload
});

export default reducers;
