import { Typography, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { ActionModal } from 'shared';

export const SPActionModal = (props: any) => {
  const [title, setTitle] = useState('');
  useEffect(() => {
    const type = props.type?.toLowerCase();
    if (type === 'compute') setTitle('Compute');
    else if (type === 'ec2') setTitle('EC2');
    else if (type === 'sage-maker') setTitle('Sage Maker');
  }, []);
  const CommandText = (
    <Stack spacing={1}>
      <Typography>
        For subscribing to Savings Plan through AWS Management Console , please follow the AWS documentation{' '}
        <a href="https://docs.aws.amazon.com/savingsplans/latest/userguide/sp-purchase.html" target="_blank" rel="noopener noreferrer">
          Purchasing Savings Plans - Savings Plans
        </a>
        .
      </Typography>
    </Stack>
  );

  const CLIText = (
    <Stack spacing={1}>
      <Typography>
        For subscribing to Savings Plan via AWS CLI, please follow the AWS CLI documentation{' '}
        <a href="https://docs.aws.amazon.com/cli/latest/reference/savingsplans/index.html" target="_blank" rel="noopener noreferrer">
          savingsplans — AWS CLI 1.29.1 Command Reference
        </a>
        .
      </Typography>
    </Stack>
  );
  return <ActionModal CommandText={CommandText} CLIText={CLIText} open={props.open} onClose={props.handleClose} title={title} />;
};
