import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { useSelectedProduct, useEnvironment } from 'hooks';
import { NeedHelp } from 'shared/callUs/NeedHelp';
import EnvironmentMode from 'enums/environmentMode';
import { ReactNode } from 'react';

const TraverseContent = () => {
  return (
    <>
      <h3 className="text-center" style={{ color: 'black' }}>
        To maximize Traverse's potential across your AWS Organizations, additional setup is required for insights from linked AWS accounts.
        Contact our support team, and we'll guide you through it!
      </h3>
      <NeedHelp variant="contained" text="Contact Us" />
    </>
  );
};

const OperationalSavingsContent = () => {
  const navigate = useNavigate();

  return (
    <>
      <h3 className="text-center" style={{ color: 'black' }}>
        Elevate your cloud efficiency to the next level. Upgrade now to gain exclusive access to valuable Operational Savings
        recommendations that could significantly lower your cloud costs.
      </h3>
      <Button variant="contained" onClick={() => navigate('/limited-access')}>
        Upgrade Now
      </Button>
    </>
  );
};

interface HideContentProps {
  children: ReactNode;
}

const HideContent: React.FC<HideContentProps> = ({ children }: any) => {
  const { isTrekora } = useSelectedProduct();
  const { currentEnvironment } = useEnvironment();

  return (
    <>
      {(isTrekora || (!isTrekora && currentEnvironment === EnvironmentMode.PRODUCTION)) && (
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-content">{isTrekora ? <OperationalSavingsContent /> : <TraverseContent />}</div>
          </div>
          <div className="content">{children}</div>
        </div>
      )}
      {!isTrekora && currentEnvironment !== EnvironmentMode.PRODUCTION && <div>{children}</div>}
    </>
  );
};

export default HideContent;
