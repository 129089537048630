const REGEX = {
  PASSWORD: {
    expression: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$#!%^&*()_+|~\-=`{}[\]:";'<>?,./])[A-Za-z\d@#$!%^&*()_+|~\-=`{}[\]:";'<>?,./]{8,}$/,
    message:
      'The password should have minimum 8 characters with at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 numerical character.'
  },
  ALPHABET_CHECK: {
    EXPRESSION: /^[a-zA-Z ]*$/,
    MESSAGE: 'Only alphabets are allowed'
  },
  ALPHANUMERIC_CHECK: {
    EXPRESSION: /^[a-zA-Z0-9 ]*$/,
    MESSAGE: 'Only alphanumerics are allowed'
  }
};

export { REGEX };
