// material-ui
import { Box } from '@mui/material';

// project import
import { MainCardProps } from 'components/MainCard';

// ==============================|| AUTHENTICATION - CARD WRAPPER ||============================== //

const AuthCard = ({ children, ...other }: MainCardProps) => (
  <Box
    sx={{
      maxWidth: { xs: 400, lg: 475 },
      // margin: { xs: 2.5, md: 3 },
      '& > *': {
        flexGrow: 1,
        flexBasis: '50%'
      },
      px: 5,
      pt: 4,
      pb: 8,
      mt: 5,
      borderRadius: '30px'
    }}
    style={{ boxShadow: '8px 0px 75.69999694824219px 0px rgba(92, 43, 200, 0.15)' }}
  >
    {/* <Box
    // sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}
    > */}
    {children}
    {/* </Box> */}
  </Box>
);

export default AuthCard;
