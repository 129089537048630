import React from 'react';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { InputAdornment, TextField } from '@mui/material';
import IconButton from 'components/@extended/IconButton';

const Component = (props: any) => {
  const textInputRef = React.useRef<HTMLInputElement>();
  const [values, setValues] = React.useState({
    showPassword: false
  });

  const handleClickShowPassword = (prop: keyof typeof values) => {
    setValues({ ...values, [prop]: !values.showPassword });
    setTimeout(() => {
      textInputRef?.current?.setSelectionRange(textInputRef?.current?.value.length, textInputRef?.current?.value.length);
    }, 10);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
  };

  return (
    <TextField
      type={values.showPassword ? 'text' : 'password'}
      variant="outlined"
      fullWidth={props.fullWidth || true}
      {...props}
      inputRef={textInputRef}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => handleClickShowPassword('showPassword')}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              sx={{ color: 'grey.500' }}
            >
              {values.showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default Component;
