export const currencyFormat = (value: number): string => {
  const roundedValue: string = parseFloat(value.toString()).toFixed(2);
  const formattedValue: string = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(roundedValue));
  return formattedValue;
};

const randomStrings: string[] = ['Discover', 'DiscoverCloud', 'Discover Cloud', 'DC', 'D.Cloud', 'Dicsover.C'];
const randomNumbers: string[] = ['$D.C', '$DC.DC', '$Di.Cl', '$Dis.Cl', '$D.Cl', '$Disc.Cld', '$dd.cc', '$d.c'];

export const formatValue = (format: string, value: number): string => {
  if (format === 'number') {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + 'M';
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + 'K';
    }
    return new Intl.NumberFormat('en-US').format(value);
  } else if (format === 'percentage') {
    return value.toFixed(2) + '%';
  } else if (format === 'currency') {
    value = value ?? 0;
    if (value >= 1e6) {
      return currencyFormat(value / 1e6) + 'M';
    } else if (value >= 1e3) {
      return currencyFormat(value / 1e3) + 'K';
    }
    return currencyFormat(value);
  }
  return value.toString();
};

export const lockedDataFormatValue = (format: string, value: any) => {
  if (format === 'number' || format === 'currency') {
    return randomNumbers[Math.floor(Math.random() * randomNumbers.length)];
  }
  return randomStrings[Math.floor(Math.random() * randomStrings.length)];
};
