import * as React from 'react';

interface TextFieldCounterProps {
  value: number | undefined;
  maxLength?: number;
}

export const TextFieldCounter: React.FC<TextFieldCounterProps> = ({ value, maxLength }) => {
  return (
    <p style={{ position: 'absolute', bottom: 0, right: 0, margin: '0px 16px 0px 0px', color: '#8C8C8C' }}>{`${
      value || 0
    }/${maxLength}`}</p>
  );
};

TextFieldCounter.defaultProps = {
  maxLength: 500
};
