import CryptoJS from 'crypto-js';
import { NOTIFY } from 'shared';
import { MESSAGES } from 'constants/messages';

export const getDecryptData = (encryptedData: any) => {
  const base64Key = process.env.REACT_APP_API_DECRYPT_KEY!;
  const key = CryptoJS.enc.Base64.parse(base64Key);
  const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
  const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
  const ciphertext = CryptoJS.lib.WordArray.create(dataBytes.words.slice(4));
  // Convert ciphertext to a Base64-encoded string
  const ciphertextString = CryptoJS.enc.Base64.stringify(ciphertext);
  const decryptedBytes = CryptoJS.AES.decrypt(ciphertextString, key, { iv });
  try {
    const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
    const decryptedObj = JSON.parse(decryptedText.replace(/'/g, '"'));
    return decryptedObj;
  } catch (err: any) {
    NOTIFY.Error(MESSAGES.API_REQUEST_ERROR);
  }
};
