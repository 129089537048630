import { Grid, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import BarGraph from '../graphs/barGraph';
import LineGraph from '../graphs/lineGraph';
import StackedGraph from '../graphs/stackedGraph';
export const Graph = (props: any) => {
  const graphType = (type: string) => {
    switch (type) {
      case 'line':
        return <LineGraph {...props} />;
      case 'stacked':
        return <StackedGraph {...props} />;

      default:
        return <BarGraph {...props} />;
    }
  };
  return (
    <MainCard sx={{ p: 2 }} content={false}>
      <Grid container alignItems="center">
        <Grid item sx={{ alignItems: 'center' }}>
          <Typography variant="h5" sx={{ color: 'secondary.main', fontWeight: 500, fontSize: '16px' }}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item sx={{ alignItems: 'center', ml: -2 }} xs={12}>
          {graphType(props.type)}
        </Grid>
      </Grid>
    </MainCard>
  );
};
