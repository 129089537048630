import { NavItemType } from 'types/menu';
import { Explore, Insights, Settings } from '@mui/icons-material';

// icons
const icons = {
  Explore,
  Insights,
  Settings
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //
const explore: NavItemType = {
  id: 'explore-group',
  type: 'group',
  children: [
    {
      id: 'explore',
      title: 'Explore',
      type: 'collapse',
      icon: icons.Explore,
      //   url: '/explore',
      children: [
        {
          id: 'resources',
          title: 'Resources',
          url: '/traverse/resources',
          type: 'item'
        },
        {
          id: 'views',
          title: 'Views',
          url: '/traverse/views',
          type: 'item'
        },
        {
          id: 'costs',
          title: 'Costs',
          url: '/traverse/costs',
          type: 'item'
        }
      ]
    }
  ]
};
const diagrams: NavItemType = {
  id: 'diagrams-group',
  type: 'group',
  children: [
    {
      id: 'diagrams',
      title: 'Diagrams',
      type: 'collapse',
      // url: '/diagrams',
      icon: icons.Insights,
      children: [
        {
          id: 'manage',
          title: 'Manage',
          type: 'item',
          url: '/traverse/diagrams',
          breadcrumbs: false
        }
      ]
    }
  ]
};
const configure: NavItemType = {
  id: 'configure-group',
  type: 'group',
  children: [
    {
      id: 'configure',
      title: 'Configure',
      type: 'collapse',
      //   url: '/',
      icon: icons.Settings,
      children: [
        {
          id: 'accounts',
          title: 'Accounts',
          type: 'item',
          url: '/traverse/accounts',
          breadcrumbs: false
        }
      ]
    }
  ]
};

export { explore, diagrams, configure };
