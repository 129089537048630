// project import
import { commercialSavings, architecturalSavings, operationalSavings, dashboard } from './applications';
import { financialServices, insurance, healthCare, media, publicSector } from './solutionsMenuItems';
import { explore, configure, diagrams } from './traverseMenuItems';

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[]; traverseItems: NavItemType[]; solutions: NavItemType[]; media: NavItemType[] } = {
  items: [dashboard, commercialSavings, architecturalSavings, operationalSavings],
  traverseItems: [explore, diagrams, configure],
  solutions: [financialServices, insurance, healthCare, publicSector],
  media: [media]
};

export default menuItems;
