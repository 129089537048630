export const getFilteredData = (data: any, propertyName: string, filter: string) => {
  let count = 6;
  if (filter === 'Past 6 Months') count = 6;
  else if (filter === 'Past 3 Months') count = 3;
  else if (filter === 'Past 12 Months') count = 12;

  const filteredData = data.map((item: any) => item[propertyName]).reverse();
  if (filteredData.every((value: any) => value === 0)) {
    return Array(count).fill(0);
  }
  return filteredData;
};
