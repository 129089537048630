import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js';

// project imports
import Loader from 'components/Loader';
import { INFORALOGIN, LOGIN, LOGOUT, TRAVERSELOGIN } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import { Auth, Amplify } from 'aws-amplify';
// types
import { AWSCognitoContextType, InitialLoginContextProps } from 'types/auth';
import { useEncryption, useUserData } from 'hooks';
// constant
const initialState: InitialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  isTraverseLoggedIn: false,
  user: null
};

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_AWS_POOL_ID || '',
  ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID || ''
});

const setToken = (token?: string | null, tokenType?: string) => {
  if (token) {
    localStorage.setItem(tokenType!, token!);
  } else {
    localStorage.removeItem(tokenType!);
  }
};

// ==============================|| AWS COGNITO - CONTEXT & PROVIDER ||============================== //

const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { getEncryptedText } = useEncryption();
  const { addUserData } = useUserData();
  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken) {
          setToken(serviceToken, 'serviceToken');
          const attributes: any = await getAttributes();
          let name: any = '';
          let subAttribute: any = '';
          if (attributes) {
            name = attributes.find((a: any) => a.Name === 'name');
            subAttribute = attributes.find((a: any) => a.Name === 'sub');
          }
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                name: name.Value,
                sub: subAttribute.Value
              }
            }
          });

          const traverseToken = window.localStorage.getItem('traverseToken');
          if (traverseToken) {
            dispatch({
              type: TRAVERSELOGIN,
              payload: {
                isLoggedIn: true,
                isTraverseLoggedIn: true
              }
            });
          }
          const inforaToken = window.localStorage.getItem('inforaToken');
          if (inforaToken) {
            dispatch({
              type: INFORALOGIN,
              payload: {
                isLoggedIn: true,
                isInforaLoggedIn: true
              }
            });
          }
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);
  const traverseLogin = async () => {
    const awsconfig = {
      Auth: {
        identityPoolId: process.env.REACT_APP_TRAVERSE_IDENTITYPOOLID,
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_TRAVERSE_USERPOOLID,
        userPoolWebClientId: process.env.REACT_APP_TRAVERSE_USERPOOLWEBCLIENTID
      },
      Storage: {
        AWSS3: {
          bucket: process.env.REACT_APP_TRAVERSE_S3BUCKET,
          region: process.env.REACT_APP_AWS_REGION
        }
      },
      aws_appsync_graphqlEndpoint: process.env.REACT_APP_TRAVERSE_GRAPHQL_ENDPOINT,

      aws_appsync_region: process.env.REACT_APP_AWS_REGION,
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
      aws_appsync_apiKey: ''
    };
    Amplify.configure(awsconfig);
    try {
      Auth.signIn(process.env.REACT_APP_TRAVERSE_USER!, process.env.REACT_APP_TRAVERSE_PW!)
        .then((signInResult) => {
          const accessToken = signInResult.signInUserSession.accessToken.jwtToken;
          setToken(accessToken, 'traverseToken');
          dispatch({
            type: LOGIN,
            payload: {
              isTraverseLoggedIn: true,
              isLoggedIn: true
            }
          });
        })
        .catch((error) => {
          console.error('Sign-in error:', error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const inforaLogin = async () => {
    const awsconfig = {
      Auth: {
        //identityPoolId: process.env.REACT_APP_TRAVERSE_IDENTITYPOOLID,
        userPoolId: process.env.REACT_APP_INFORA_USERPOOLID,
        userPoolWebClientId: process.env.REACT_APP_INFORA_USERPOOLWEBCLIENTID,
        region: process.env.REACT_APP_INFORA_REGION
      }
    };
    Amplify.configure(awsconfig);
    try {
      Auth.signIn(process.env.REACT_APP_INFORA_USER!, process.env.REACT_APP_INFORA_PW!)
        .then(async (signInResult) => {
          const accessToken = signInResult.signInUserSession.idToken.jwtToken;
          localStorage.setItem('inforaToken', accessToken);
          await dispatch({
            type: LOGIN,
            payload: {
              isInforaInitialized: false,
              isInforaLoggedIn: true,
              isLoggedIn: true
            }
          });
        })
        .catch((error) => {
          console.error('Sign-in error:', error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const login = async (email: string, password: string) => {
    const usr = new CognitoUser({
      Username: email,
      Pool: userPool
    });

    const authData = new AuthenticationDetails({
      Username: email,
      Password: getEncryptedText(password)
    });
    return new Promise(function (resolve, reject) {
      usr.authenticateUser(authData, {
        onSuccess: async (session: CognitoUserSession) => {
          addSession(session);
          addUserData('email', email);
          resolve(true);
        },
        onFailure: (_err) => {
          reject(_err);
        },
        newPasswordRequired: () => {}
      });
    }).catch((err) => {
      throw err;
    });
  };
  const addSession = async (session: CognitoUserSession) => {
    setToken(session.getAccessToken().getJwtToken(), 'serviceToken');
    setToken(session.getIdToken().getJwtToken(), 'idToken');
    setToken(session.getRefreshToken().getToken(), 'refreshToken');
    const attributes: any = await getAttributes();

    let name: any = '';
    let subAttribute: any = '';
    let emailAttribute: any = '';
    if (attributes) {
      name = attributes.find((a: any) => a.Name === 'name');
      subAttribute = attributes.find((a: any) => a.Name === 'sub');
      emailAttribute = attributes.find((a: any) => a.Name === 'email');
    }
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: {
          email: emailAttribute.value,
          name: name.Value,
          sub: subAttribute.Value
        }
      }
    });
    // addUserData('email', emailAttribute.value);
  };
  const getAttributes = async () => {
    const currentUser = userPool.getCurrentUser();
    return new Promise(function (resolve, reject) {
      if (currentUser) {
        currentUser.getSession((err: any, session: CognitoUserSession) => {
          if (err) {
            reject(err);
          }
          currentUser.getUserAttributes((err, attributes) => {
            if (err) {
              reject(err);
            } else {
              resolve(attributes);
            }
          });
        });
      }
    }).catch((err) => {
      throw err;
    });
  };
  async function refreshCognitoToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    const attributes: any = await getAttributes();
    let email: any = '';
    if (attributes) {
      email = attributes.find((a: any) => a.Name === 'email');
    }
    const userData = {
      Username: email,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);

    const refreshTokenObj = refreshToken ? new CognitoRefreshToken({ RefreshToken: refreshToken }) : null;

    return new Promise((resolve, reject) => {
      if (refreshTokenObj) {
        cognitoUser.refreshSession(refreshTokenObj, (error, session) => {
          if (error) {
            // Handle error while refreshing the token
            console.error('Error refreshing Cognito token:', error);
            reject(error);
          } else {
            // Token refreshed successfully
            const accessToken = session.getAccessToken().getJwtToken();
            setToken(session.getAccessToken().getJwtToken(), 'serviceToken');
            setToken(session.getIdToken().getJwtToken(), 'idToken');
            setToken(session.getRefreshToken().getToken(), 'refreshToken');
            resolve(accessToken);
          }
        });
      } else {
        // Handle the case where refreshToken is null
        const error = new Error('Refresh token is missing or invalid');
        console.error('Error refreshing Cognito token:', error);
        reject(error);
      }
    });
  }
  const register = (email: string, password: string, firstName: string, lastName: string) =>
    new Promise((success, rej) => {
      userPool.signUp(
        email,
        getEncryptedText(password),
        [
          new CognitoUserAttribute({ Name: 'email', Value: email }),
          new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })
        ],
        [],
        async (err, result) => {
          if (err) {
            rej(err);
            return;
          }
          success(result);
          addUserData('email', email);
        }
      );
    });

  const logout = () => {
    const loggedInUser = userPool.getCurrentUser();
    if (loggedInUser) {
      setToken(null, 'serviceToken');
      setToken(null, 'idToken');
      setToken(null, 'refreshToken');
      setToken(null, 'traverseToken');
      loggedInUser.signOut();
      dispatch({ type: LOGOUT });
      localStorage.clear();
      window.location.reload();
    }
  };

  const forgotPassword = async (email: string) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool
    });
    return new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: function (data) {
          resolve(data);
        },
        onFailure: function (error) {
          reject(error);
        }
      });
    });
  };
  const validateOtp = async (email: string, verificationCode: string): Promise<string> => {
    const userData = {
      Username: email!,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(verificationCode, true, (err, data) => {
        if (err) {
          reject(err.message);
        } else {
          addUserData('email', '');
          resolve(data);
        }
      });
    });
  };

  const resendConfirmationCode = async (email: string) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool
    });
    return new Promise((resolve, reject) => {
      if (!cognitoUser) {
        reject(`could not find ${email}`);
        return;
      }
      cognitoUser.resendConfirmationCode((res) => resolve(res));
    }).catch((err) => {
      throw err;
    });
  };

  const resetPassword = async (email: string, verificationCode: string, newPassword: string) => {
    const user = new CognitoUser({
      Username: email as string,
      Pool: userPool
    });
    return new Promise((resolve, reject) => {
      user.confirmPassword(verificationCode, getEncryptedText(newPassword), {
        onSuccess: function (data) {
          localStorage.removeItem('email');
          addUserData('email', '');
          resolve(data);
        },
        onFailure: function (error) {
          reject(error.message);
        }
      });
    });
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      Amplify.configure({
        Auth: {
          userPoolId: process.env.REACT_APP_AWS_POOL_ID || '',
          region: process.env.REACT_APP_AWS_REGION,
          userPoolWebClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID
        }
      });
      // Step 2: Authenticate the user with their current password
      const user = await Auth.currentAuthenticatedUser();
      return await Auth.changePassword(user, getEncryptedText(oldPassword), getEncryptedText(newPassword));
    } catch (error: any) {
      return error.message;
    }
  };
  const updateAttributes = async (name: string) => {
    try {
      Amplify.configure({
        Auth: {
          userPoolId: process.env.REACT_APP_AWS_POOL_ID || '',
          region: process.env.REACT_APP_AWS_REGION,
          userPoolWebClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID
        }
      });
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { name });
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: {
            email: user.attributes.email,
            name,
            sub: user.attributes.sub
          }
        }
      });
      return true;
    } catch (error: any) {
      return error.message;
    }
  };

  const updateProfile = () => {};

  if (
    (state.isInitialized !== undefined && !state.isInitialized) ||
    (state.isTraverseInitialized !== undefined && !state.isTraverseInitialized) ||
    (state.isInforaInitialized !== undefined && !state.isInforaInitialized)
  ) {
    return <Loader />;
  }
  return (
    <AWSCognitoContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        forgotPassword,
        resetPassword,
        updateProfile,
        validateOtp,
        resendConfirmationCode,
        changePassword,
        updateAttributes,
        refreshCognitoToken,
        addSession,
        getAttributes,
        traverseLogin,
        inforaLogin
      }}
    >
      {children}
    </AWSCognitoContext.Provider>
  );
};

export default AWSCognitoContext;
