import { Stack, Box, Typography, Button, Skeleton } from '@mui/material';
import '../aws-setup/component.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useGeneratePdf, useImages } from 'hooks';
import { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import useAxios from 'utils/axios';
import { URLS } from 'constants/endpoints';
import { NeedHelp } from 'shared/callUs/NeedHelp';

export const ArchitectureImages = () => {
  const navigate = useNavigate();
  const { generatePDF } = useGeneratePdf();
  const [prodUrl, setProdUrl] = useState('');
  const [nonProdUrl, setNonProdUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const { getImages } = useImages();
  const axiosServices = useAxios();
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    axiosServices
      .get(URLS.SAP.GET_PLANS, { params: { id } })
      .then(async (res: any) => {
        if (res.data.data.submission_completed) {
          getImages(res.data.data.s3_path_for_prod_arch, res.data.data.s3_path_for_non_prod_arch)
            .then((res) => {
              setProdUrl(res.prodUrl);
              setNonProdUrl(res.nonProdUrl);
            })
            .catch(() => {})
            .finally(() => {
              setLoading(false);
            });
        } else navigate('/sapAssist/info/' + res.data.data.id);
      })
      .catch(() => setLoading(false));
  }, []);

  return (
    <Stack spacing={2}>
      <Stack spacing={1} justifyContent="center" alignItems="center">
        <Loading show={loading} />
        <Stack direction="row" spacing={2} justifyContent="center" sx={{ width: '100%' }}>
          <Stack spacing={2} sx={{ textAlign: 'center', width: '50%' }}>
            <Typography variant="subtitle1">Target SAP on AWS Non-Production Architecture:</Typography>
            <Box>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={450} animation="wave" />
              ) : (
                <img src={nonProdUrl} alt="Non-Production Environment Architecture" width="100%" height={450} />
              )}
            </Box>
          </Stack>
          <Stack spacing={2} sx={{ textAlign: 'center', width: '50%' }}>
            <Typography variant="subtitle1">Target SAP on AWS Production Architecture:</Typography>
            <Box>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={450} animation="wave" />
              ) : (
                <img src={prodUrl} alt="Production Environment Architecture" width="100%" height={450} />
              )}
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button variant="outlined" onClick={() => navigate('/sapAssist/dashboard')}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              generatePDF([nonProdUrl, prodUrl], ['SAP on AWS Non-Production Architecture', ' SAP on AWS Production Architecture'])
            }
          >
            Download
          </Button>
        </Stack>
        <Box sx={{ mt: 2 }}>
          <Typography>
            We will get back to you on your target AWS infra structure pricing soon. For any clarification, Please{' '}
            <NeedHelp text="contact us" variant="text" />.
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};
