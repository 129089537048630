export const tooltip = (header: string, tooltipContent: string, totalValue: string) => `<div class="table">
<div class="header">
    <div class="row">
    <div class="cellHeader">${header}</div>
    </div>
  </div>
    ${tooltipContent}
    <div class="row horizontal-line bold">
    <div class="cell1"><div class='cellText'>Total</div></div>
        <div class="cell">${totalValue}</div>
    </div>
</div>
`;

export const tooltipDonut = (header: string, color: string, amount: string, percentage: string) => `
<div style="background-color:white;color:black; padding:10px;width:100%">
        <div style="display:flex; align-items:center; margin-top:30px">
            <div class="box" style="background-color:${color}"> </div>
            <div style="font-size:12px; text-wrap: wrap; margin-left: 10px">${header}</div>
        </div>
    <div style="display:flex; align-items:center; margin-top:10px">
        <div>
            <div><div >Estimated Cost</div></div>
        </div>
        <div style="margin-left: 40px;text-align:right;width:100%">${amount}</div>
    </div>
    <div style="display:flex; align-items:center;">
        <div>
            Percentage
        </div>
        <div style="margin-left: 40px;text-align:right;width:100%">${percentage}</div>
    </div>
</div>
`;
