import { useSelector } from 'react-redux';

// material-ui
// import { useTheme } from '@mui/material';

// project import
// import NavCard from './NavCard';
import Navigation from './Navigation';
//import SimpleBar from 'components/third-party/SimpleBar';

// types
import { RootStateProps } from 'types/root';

const DrawerContent = () => {
  // const theme = useTheme();
  // const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  return (
    <>
      <Navigation />
      {drawerOpen}
      {/* && !matchDownMD && <NavCard />} */}
    </>
  );
};

export default DrawerContent;
