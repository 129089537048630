import { Typography, Stack, Button, Box } from '@mui/material';
import { useState } from 'react';
import { ActionModal } from 'shared';
import { CopyOutlined } from '@ant-design/icons';
import { useTheme } from '@mui/material/styles';

export const RedshiftActionModal = (props: any) => {
  const theme = useTheme();
  const command = `aws redshift purchase-reserved-node-offering --reserved-node-offering-id <--add the corresponding offering Id for this purchase-->`;
  const [copy, icCopied] = useState('Copy');
  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        icCopied('Copied!');
      })
      .catch(() => {
        icCopied('Copy');
      });
  };

  const CommandText = (
    <Stack spacing={1}>
      <Box>
        <Typography>
          <b>To purchase a reserved node:</b>
        </Typography>
      </Box>
      <ol>
        <li>
          Sign in to the AWS Management Console and open the Amazon Redshift console at{' '}
          <a href="https://console.aws.amazon.com/redshift/" target="_blank" rel="noopener noreferrer">
            https://console.aws.amazon.com/redshift/
          </a>
          .
        </li>
        <li>
          On the navigation menu, choose <b>Clusters</b>, then choose <b>Reserved nodes</b> to display the list of reserved nodes.
        </li>
        <li>
          Choose <b>Purchase reserved nodes</b> to display the page to choose the properties of the node that you want to purchase.
        </li>
        <li>
          Enter the properties of the node, then choose <b>Purchase reserved nodes</b>.
        </li>
        <li>
          AWS Doc Reference{' '}
          <a
            href="https://docs.aws.amazon.com/redshift/latest/mgmt/purchase-reserved-node-offering-console.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://docs.aws.amazon.com/redshift/latest/mgmt/purchase-reserved-node-offering-console.html
          </a>
          .
        </li>
      </ol>
    </Stack>
  );

  const CLIText = (
    <Stack spacing={1}>
      <ol>
        <li>Ensure that your AWS configuration is set to the desired AWS account and Region where the volume resides.</li>
        <li>
          Use the following command by adding the offering ID of Reservation that you want to subscribe.
          <Stack spacing={1} sx={{ mb: 1 }}>
            <Typography>
              <span style={{ float: 'right' }}>
                <Button
                  className="btncopy"
                  onClick={() => handleCopy(command)}
                  startIcon={<CopyOutlined style={{ color: 'secondary.main' }} />}
                  sx={{ color: 'secondary.main' }}
                >
                  {copy}
                </Button>
              </span>
            </Typography>
            <Typography
              sx={{ fontFamily: 'Courier New', border: `1px solid ${theme.palette.secondary.main}`, p: 1, mx: 8, borderRadius: '8px' }}
            >
              aws redshift purchase-reserved-node-offering --reserved-node-offering-id{' '}
              <b>
                {'<'}--add the corresponding offering Id for this purchase--{'>'}
              </b>
            </Typography>
          </Stack>
        </li>
        <li>
          <b>AWS doc reference : </b>
          <a
            href="https://docs.aws.amazon.com/cli/latest/reference/opensearch/purchase-reserved-instance-offering.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://docs.aws.amazon.com/cli/latest/reference/redshift/purchase-reserved-instance-offering.html
          </a>
          .
        </li>
      </ol>
    </Stack>
  );
  return <ActionModal CommandText={CommandText} CLIText={CLIText} open={props.open} onClose={props.handleClose} title="Amazon Redshift" />;
};
