import { StringPairs } from './types';

const commercialSavingsTypes: string[] = [
  'Savings Plan',
  'Amazon Relational Database Service',
  'Amazon ElastiCache',
  'Amazon OpenSearch',
  'Amazon Redshift',
  'Compute',
  'EC2',
  'SageMaker'
];

const architecturalSavingsTypes: string[] = [
  'AMD Migration',
  'EBS Modernization',
  'Data Transfer Out',
  'Data Transfer Availability Zone',
  'Data Transfer Inter Region',
  'Running Hours',
  'Bytes',
  'API',
  'Storage',
  'Graviton Migration'
];

const operationalSavingsTypes: any[] = [];

const serviceFilterNamesToAPI: StringPairs = {
  rds: 'Amazon Relational Database Service',
  elasticache: 'Amazon ElastiCache',
  opensearch: 'Amazon Elasticsearch Service',
  redshift: 'Amazon Redshift',
  savingsPlan: 'Savings Plan',
  compute: 'COMPUTE_SP',
  ec2: 'EC2_INSTANCE_SP',
  sageMaker: 'SAGEMAKER_SP',
  amd: 'AMD',
  ebs: 'EBS',
  graviton: 'Graviton',
  dto: 'DTO',
  dtaz: 'DTAZ',
  dtir: 'DTIR',
  natHours: 'NGW Hours',
  natBytes: 'NGW Bytes',
  api: 'API',
  storage: 'Storage',
  'underutilized-ec2': 'EC2',
  'underutilized-ebs': 'EBS',
  'underutilized-elb': 'ELB'
};

export { commercialSavingsTypes, architecturalSavingsTypes, operationalSavingsTypes, serviceFilterNamesToAPI };
