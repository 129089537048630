import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateProps } from 'types/root';
import useAxios from 'utils/axios';
import useUserData from './useUserData';
import { getPastMonths } from 'pages/utils/getPastMonths';
import { getTableNames } from 'pages/utils/getSavingsData';

export const getMonthName = (month: number, year: number) => {
  const date = new Date(year, month - 1);
  const monthName = date.toLocaleString('default', { month: 'short' });
  return monthName + ' ' + year;
};

export const getDates = (data: string) => {
  const dateParts = data.split('-');
  const startDateString = dateParts[0];
  const endDateString = dateParts[1];
  const startDate = new Date(startDateString.trim());
  const endDate = new Date(endDateString.trim());
  const from_date = startDate.toISOString().slice(0, 10);
  const to_date = endDate.toISOString().slice(0, 10);
  return {
    from_date,
    to_date
  };
};
export const useStackedGraphData = () => {
  const { getUserData } = useUserData();
  const filters = useSelector((state: RootStateProps) => state.filters.data);
  const axiosServices = useAxios();
  const [loading, setLoading] = useState(false);

  const uppercaseWord = ['RDS', 'OpenSearch', 'ElastiCache'];

  const getData = (data: any, keyName: string) =>
    data
      .reduce((result: any, item: any) => {
        Object.entries(item[keyName]).forEach(([key, value]) => {
          let seriesName = key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
          seriesName = getTableNames(key.replace(/\s+/g, '_')) || seriesName;
          uppercaseWord.forEach((word) => {
            const regex = new RegExp(`\\b${word}\\b`, 'gi');
            seriesName = seriesName.replace(regex, () => word);
          });
          const existingSeries = result.find((series: any) => series.name === seriesName);
          if (existingSeries) {
            existingSeries.data.push(value);
          } else {
            result.push({ name: seriesName, data: [value] });
          }
        });
        return result;
      }, [])
      .map((type: any) => ({ ...type, data: type.data.reverse() }));
  const fetchStackedData = async (params: any, url: string) => {
    if (filters[0].value.length !== 0 && filters.filter((f) => f.value === params.filter).length === 0) {
      const { filter, ...rest } = params;
      params = { ...rest, from_date: getDates(filter).from_date, to_date: getDates(filter).to_date };
    }
    setLoading(true);
    return await axiosServices
      .get(url, { params: { ...params, aws_account_id: getUserData('selectedAwsAccountId') } })
      .then((res) => {
        const data = res.data.data.items;
        setLoading(false);
        return {
          xaxisData: getPastMonths(params.filter),
          cumulativeData: res.data.data.cumulative,
          cardData: res.data.data.card_data || null,
          loading,
          achievedData: getData(data, 'achieved_savings'),
          potentialData: getData(data, 'potential_savings')
        };
      })
      .catch(() => {
        setLoading(false);
        return { xaxisData: [], achievedData: [], potentialData: [], cumulativeData: [], cardData: null, loading };
      });
  };
  return { fetchStackedData };
};
