import { createSlice } from '@reduxjs/toolkit';
import { LableValuePair } from 'constants/types';

const initialState: { data: LableValuePair[] } = {
  data: [{ value: '', label: '' }]
};

const filters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.data = action.payload;
    }
  }
});

export default filters.reducer;

export const { setFilters } = filters.actions;
