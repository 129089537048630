import { useEffect, useState } from 'react';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import { Box, Stack, Typography } from '@mui/material';
import { tooltip } from './tooltip';
import './tooltip.css';
import { formatAxisLabels, roundToNearest } from 'utils/utilityFns';
import { formatValue } from 'utils/formatter';

interface GraphData {
  name: string;
  data: number[];
}
interface StackedGraphProps {
  name: string;
  data: GraphData[];
  categories: string[];
  type: string;
  isDefaultHeight?: boolean;
}

const findMaxTotal = (data: GraphData[]) => {
  let maxTotal = 0;
  data.forEach((item: GraphData) => {
    const maxValue = Math.max(...item.data);
    maxTotal += maxValue;
  });
  return maxTotal;
};

const StackedGraph: React.FC<StackedGraphProps> = ({ name, data, categories, type, isDefaultHeight = true }) => {
  const [series, setSeries] = useState(data);
  const [graphHeight, setGraphHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setGraphHeight(window.innerHeight - 404 > 250 ? window.innerHeight - 404 : 400);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (data) {
      setSeries(data);
    } else {
      setSeries([]);
    }
  }, [data, categories]);

  const allZeros = data.every((type: GraphData) => type.data.every((val: number) => Number(val) === 0));
  const options: ChartProps = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: ['#00248C', '#AF57F3', '#1792F3', '#66E5FB', '#FF9800', '#FE5A71'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        columnWidth: '60%',
        horizontal: false,
        borderRadius: 4
      }
    },
    yaxis: {
      tickAmount: 4,
      min: 0,
      max: roundToNearest(findMaxTotal(data)),
      decimalsInFloat: 0,
      labels: {
        formatter: formatAxisLabels,
        style: {
          colors: '#000000'
        }
      }
    },
    xaxis: {
      categories,
      title: {
        text: 'Timeline',
        offsetY: -5,
        style: {
          fontWeight: 600
        }
      },
      labels: {
        style: {
          colors: '#000000'
        }
      }
    },
    legend: {
      show: true,
      position: 'bottom',
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false
      }
    },
    fill: {
      opacity: 1
    },
    stroke: {
      show: true,
      width: 8,
      colors: ['transparent']
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }: { seriesIndex: number; dataPointIndex: number; w: any }) {
        const headerLable = w.globals.labels[dataPointIndex];
        let seriesValues = [];
        for (let i = 0; i < w.globals.series.length; i++) {
          const seriesData = w.config.series[i].data;
          seriesValues.push(seriesData[dataPointIndex]);
        }
        let tooltipContent = '';
        let totalValue: number = 0;
        for (var i = 0; i < seriesValues.length; i++) {
          const value = Number(seriesValues[i]);
          tooltipContent += `<div class="row ${seriesIndex === i ? 'bold' : ''}">`;
          tooltipContent += ` <div class="cell1"><div style="background-color:${w.globals.colors[i]}" class="box"><div><div class="cellText">${w.config.series[i].name}</div></div></div></div>`;
          tooltipContent += `<div class="cell">${formatValue('currency', value)}</div>`;
          tooltipContent += '</div>';
          totalValue += value;
        }
        return tooltip(headerLable, tooltipContent, formatValue('currency', totalValue));
      }
    }
  };
  if (allZeros && options && data.length > 0) {
    options.annotations = {
      points: [
        {
          x: categories[Math.round(categories.length / 2) - 1],
          y: 2000,
          yAxisIndex: 0,
          seriesIndex: 0,
          marker: {
            size: 0,
            fillColor: 'transparent'
          },
          label: {
            borderColor: 'transparent',
            text: 'No Data Available',
            textAnchor: 'front',
            style: {
              color: '#000000',
              fontSize: '18px',
              fontWeight: 'bold'
            }
          }
        }
      ]
    };
    options.yaxis = {
      ...options.yaxis,
      show: true,
      min: 0,
      max: 4000,
      tickAmount: 4,
      decimalsInFloat: 0
    };
    options.tooltip = {
      enabled: false
    };
  }
  return (
    <Box sx={{ p: 2.5, pb: 0 }}>
      <Stack sx={{ mb: -1 }}>
        <Typography sx={{ fontSize: '12px', fontWeight: '600', fill: 'rgb(55, 61, 63)' }}>{name || 'Costs ($)'}</Typography>
      </Stack>
      {allZeros && <ReactApexChart options={options} series={series} type="bar" height={isDefaultHeight ? 'auto' : graphHeight} />}
      {!allZeros && <ReactApexChart options={options} series={series} type="bar" height={isDefaultHeight ? 'auto' : graphHeight} />}
    </Box>
  );
};

export default StackedGraph;
