import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 14,
      borderRadius: '4px',
      background: '#FFF',
      boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.25)',
      color: '#262626'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#FFF'
    }
  })
);
