import { Plan } from 'constants/types';
import useUserData from './useUserData';
import { plans } from 'constants/plans';

export const useSubscriptionPlan = () => {
  const { getUserData } = useUserData();
  const UserSubscriptionData: Plan = plans[getUserData('userData').subscription_plan];

  return { UserSubscriptionData };
};
