import { useEffect, useState } from 'react';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import { Box, Stack, Typography } from '@mui/material';
import { useConfig } from 'hooks';
import { formatAxisLabels, roundToNearest } from 'utils/utilityFns';

interface BarGraphProps {
  name: string;
  data: number[];
  categories: string[];
  type?: string;
}

const BarGraph: React.FC<BarGraphProps> = ({ name, data, categories, type }) => {
  const { mode } = useConfig();
  const [series, setSeries] = useState([{ name: name || 'Costs', data }]);

  useEffect(() => {
    if (data) {
      setSeries([{ name: name || 'Costs', data }]);
    } else {
      setSeries([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, categories]);

  const allZeros = data.every((val: number) => val === 0);

  const options: ChartProps = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
        borderRadius: 4
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 8,
      colors: ['transparent']
    },
    xaxis: {
      categories,
      title: {
        text: 'Timeline',
        style: {
          fontWeight: 600
        }
      },
      labels: {
        style: {
          colors: '#000000'
        }
      }
    },
    yaxis: {
      tickAmount: 4,
      min: 0,
      max: roundToNearest(Math.max(...data)),
      decimalsInFloat: 0,
      labels: {
        formatter: formatAxisLabels,
        style: {
          colors: '#000000'
        }
      }
    },
    fill: {
      opacity: 1,
      colors: ['#00248C']
    },
    tooltip: {
      y: {
        formatter(value: number) {
          return type === 'percentage' ? `${value}%` : `$${value >= 1000 ? (value / 1000).toFixed(2) + 'K' : value}`;
        }
      },
      theme: mode === 'dark' ? 'dark' : 'light'
    },
    legend: {
      show: false,
      showForSingleSeries: false,
      customLegendItems: [name]
      // markers: {
      //   fillColors: ['#00E396', '#775DD0']
      // }
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          yaxis: {
            show: true
          }
        }
      }
    ]
  };

  if (type === 'percentage') {
    options.yaxis = {
      ...options.yaxis,
      min: 0,
      max: 100,
      tickAmount: 4
    };
  }

  if (allZeros && options && data.length > 0) {
    options.annotations = {
      points: [
        {
          x: categories[Math.round(categories.length / 2) - 1],
          y: 2000,
          yAxisIndex: 0,
          seriesIndex: 0,
          marker: {
            size: 0,
            fillColor: 'transparent'
          },
          label: {
            borderColor: 'transparent',
            text: 'No Data Available',
            textAnchor: 'front',
            style: {
              color: '#000000',
              fontSize: '18px',
              fontWeight: 'bold'
            }
          }
        }
      ]
    };
    options.yaxis = {
      ...options.yaxis,
      show: true,
      min: 0,
      max: 4000,
      tickAmount: 4
    };
    options.tooltip = {
      enabled: false
    };
  }

  return (
    <Box sx={{ p: 2.5, pb: 0 }}>
      <Stack sx={{ mb: -1 }}>
        <Typography sx={{ fontSize: '12px', fontWeight: '600', fill: 'rgb(55, 61, 63)' }}>{name || 'Costs ($)'}</Typography>
      </Stack>
      {allZeros && <ReactApexChart options={options} series={series} type="bar" />}
      {!allZeros && <ReactApexChart options={options} series={series} type="bar" />}
    </Box>
  );
};

export default BarGraph;
