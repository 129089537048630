import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface ILoadingProps {
  show?: boolean;
}

const Loading: React.FC<ILoadingProps> = (props) => (
  <Backdrop
    sx={{
      color: '#fff',
      zIndex: (theme) => theme.zIndex.drawer + 1
    }}
    open={props.show || false}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default Loading;
