import { useEffect, useMemo, useState } from 'react';
import { Graph } from 'shared/graphFilter/Graph';
import { Grid, Typography } from '@mui/material';
import {
  rdsRecommendationHeaderCells,
  elasticRecommendationHeaderCells,
  openSearchRecommendationHeaderCells,
  redshiftRecommendationHeaderCells,
  savingsPlanRecommendationHeaderCells
} from 'constants/headerCells';
import { URLS } from 'constants/endpoints';
import useAxios from 'utils/axios';
import Loading from 'components/Loading';
import { useGraphData, useUserData } from 'hooks';
import { useParams } from 'react-router';
import { RdsActionModal } from './RdsActionModal';
import { ElasticacheActionModal } from './ElasticacheActionModal';
import { OpenSearchActionModal } from './OpenSearchActionModal';
import { RedshiftActionModal } from './RedshiftActionModal';
import { GraphFilter } from 'shared/graphFilter';
import { getFilteredData } from 'pages/utils/getGraphFilteredData';
import { FILTER } from '../../constants/defaults';
import { DataTable } from 'shared/dataTable';
import { getSavingsData, getSavingsName } from 'pages/utils/getSavingsData';
import { SPActionModal } from './SPActionModal';
import { UtilizationGraphData, CoverageGraphData, RecommendationsData } from 'pages/types';
import { getPastMonths } from 'pages/utils/getPastMonths';
import { serviceFilterNamesToAPI } from 'constants/response';
import { FormattedMessage } from 'react-intl';
import { NOTIFY } from 'shared';
import { MESSAGES } from 'constants/messages';

const DEFAULT_UTILIZATION_GRAPH_DATA: UtilizationGraphData = {
  utilizationXAxisData: [],
  utilizationYAxisData: [],
  utilizationDataLoading: false
};

const DEFAULT_COVERAGE_GRAPH_DATA: CoverageGraphData = { coverageXaxisData: [], coverageYaxisData: [], coverageDataLoading: false };

const RECOM_DEFAULT_DATA: RecommendationsData = {
  headerCells: [],
  hideColumns: null
};

export const SpecificSavingType = (props: any) => {
  const { getUserData } = useUserData();
  const axiosServices = useAxios();
  const [rowsData, setRowsData] = useState<any>([]);
  const [filterValue, setFilterValue] = useState(FILTER);
  const [loading, setLoading] = useState(false);
  const [openRdsModal, setOpenRdsModal] = useState(false);
  const [openElasticacheModal, setOpenElasticacheModal] = useState(false);
  const [openOpenSearchModal, setOpenSearchModal] = useState(false);
  const [openRedshiftModal, setOpenRedshiftModal] = useState(false);
  const [openSpModal, setOpenSpModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const { ctype, type } = useParams();
  const [recommendationsData, setRecommendationsData] = useState(RECOM_DEFAULT_DATA);

  const [graphData, setGraphData] = useState({
    utilizationData: DEFAULT_UTILIZATION_GRAPH_DATA,
    coverageData: DEFAULT_COVERAGE_GRAPH_DATA
  });
  const { fetchData } = useGraphData();

  useEffect(() => {
    const savingsData = getSavingsData(type);
    let serviceName = savingsData?.service_filter;
    setLoading(true);

    switch (serviceName) {
      case serviceFilterNamesToAPI['rds']:
        setRecommendationsData({
          headerCells: rdsRecommendationHeaderCells,
          hideColumns: ['region', 'current_generation', 'license', 'db_instance_type', 'upfront_cost']
        });
        break;

      case serviceFilterNamesToAPI['elasticache']:
        setRecommendationsData({
          headerCells: elasticRecommendationHeaderCells,
          hideColumns: ['region', 'current_generation', 'ec_instance_type', 'upfront_cost']
        });
        break;

      case serviceFilterNamesToAPI['opensearch']:
        setRecommendationsData({
          headerCells: openSearchRecommendationHeaderCells,
          hideColumns: ['region', 'current_generation', 'upfront_cost']
        });
        break;

      case serviceFilterNamesToAPI['redshift']:
        setRecommendationsData({
          headerCells: redshiftRecommendationHeaderCells,
          hideColumns: ['region', 'current_generation', 'license', 'upfront_cost']
        });
        break;

      case serviceFilterNamesToAPI['compute']:
      case serviceFilterNamesToAPI['ec2']:
      case serviceFilterNamesToAPI['sageMaker']:
        setRecommendationsData({
          headerCells: savingsPlanRecommendationHeaderCells,
          hideColumns: ['region', 'instance_family', 'upfront_cost']
        });
        break;

      default:
        break;
    }
    let params = { filter: 'Yesterday', aws_account_id: getUserData('selectedAwsAccountId') };
    if (ctype?.toLowerCase() === 'savings-plan') {
      params = { ...params, ...savingsData.planType };
    }
    axiosServices
      .get(savingsData.recommendationsUrl, { params })
      .then((res: any) => {
        setRowsData(res.data.data?.items || []);
      })
      .catch(() => {
        NOTIFY.Error(MESSAGES.API_REQUEST_ERROR);
      })
      .finally(() => setLoading(false));
  }, [type, props.selectedRow]);

  useEffect(() => {
    let utilizationUrl = '';
    let coverageUrl = '';

    if (ctype?.toLowerCase() === 'ri') {
      utilizationUrl = URLS.COMMERCIAl.RI_UTILIZATION;
      coverageUrl = URLS.COMMERCIAl.RI_COVERAGE;
    } else if (ctype?.toLowerCase() === 'savings-plan') {
      utilizationUrl = URLS.COMMERCIAl.SP_UTILIZATION;
      coverageUrl = URLS.COMMERCIAl.SP_COVERAGE;
    }

    const fetchDataAndSetGraphData = async () => {
      const savingsData = getSavingsData(type);
      setLoading(true);
      try {
        const utilizationResponse = await fetchData({ filter: filterValue, ...savingsData.planType }, utilizationUrl);
        setGraphData((prevGraphData) => ({
          ...prevGraphData,
          utilizationData: {
            utilizationXAxisData: getPastMonths(filterValue),
            utilizationYAxisData: utilizationResponse.yaxisData,
            utilizationDataLoading: utilizationResponse.loading
          }
        }));

        const coverageResponse = await fetchData({ filter: filterValue, ...savingsData.planType }, coverageUrl);
        setGraphData((prevGraphData) => ({
          ...prevGraphData,
          coverageData: {
            coverageXaxisData: getPastMonths(filterValue),
            coverageYaxisData: coverageResponse.yaxisData,
            coverageDataLoading: coverageResponse.loading
          }
        }));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDataAndSetGraphData();
  }, [type, props.selectedRow, filterValue]);

  const handleActionClick = (selectedRow: any) => {
    let serviceName = getSavingsData(type).service_filter;
    setSelectedRow(selectedRow);
    if (serviceName === serviceFilterNamesToAPI['rds']) {
      setOpenRdsModal(true);
    } else if (serviceName === serviceFilterNamesToAPI['elasticache']) {
      setOpenElasticacheModal(true);
    } else if (serviceName === serviceFilterNamesToAPI['opensearch']) {
      setOpenSearchModal(true);
    } else if (serviceName === serviceFilterNamesToAPI['redshift']) {
      setOpenRedshiftModal(true);
    } else if (ctype?.toLowerCase() === 'savings-plan') {
      setOpenSpModal(true);
    }
  };

  const savingsType = getSavingsName(type);

  const utilizationData = useMemo(
    () =>
      getFilteredData(
        graphData.utilizationData.utilizationYAxisData,
        ctype?.toLowerCase() === 'ri' ? 'utilization_percentage' : 'calc_savings_plan_utilization_percent',
        filterValue
      ),
    [graphData.utilizationData.utilizationYAxisData, ctype, filterValue]
  );

  const coverageData = useMemo(
    () =>
      getFilteredData(
        graphData.coverageData.coverageYaxisData,
        ctype?.toLowerCase() === 'ri' ? 'coverage_hours_percentage' : 'coverage_percentage',
        filterValue
      ),
    [graphData.coverageData.coverageYaxisData, ctype, filterValue]
  );

  return (
    <Grid container rowSpacing={2} columnSpacing={3}>
      <Loading show={loading || graphData.utilizationData.utilizationDataLoading || graphData.coverageData.coverageDataLoading} />
      <Grid item xs={6}>
        <Typography variant="h5" sx={{ color: 'secondary.main', fontWeight: 500, fontSize: '18px' }}>
          {savingsType}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <GraphFilter handleValueChange={(e: any) => setFilterValue(e)} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Graph
          title={<FormattedMessage id="utilization" />}
          data={utilizationData}
          categories={getPastMonths(filterValue)}
          name="Utilization(%)"
          type="percentage"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Graph
          title={<FormattedMessage id="coverage" />}
          data={coverageData}
          categories={getPastMonths(filterValue)}
          name="Coverage(%)"
          type="percentage"
        />
      </Grid>
      <Grid item xs={12}>
        {recommendationsData.hideColumns !== null && (
          <DataTable
            data={rowsData || []}
            columns={recommendationsData.headerCells}
            hideColumns={recommendationsData.hideColumns}
            headerData={{ title: 'Recommendations', savingsType, showRecommendedAction: true }}
            tableActions={{
              onActionClick: handleActionClick,
              showFilter: true
            }}
          />
        )}
      </Grid>
      {openRdsModal && <RdsActionModal open={openRdsModal} selectedRow={selectedRow} handleClose={() => setOpenRdsModal(false)} />}
      {openElasticacheModal && (
        <ElasticacheActionModal open={openElasticacheModal} selectedRow={selectedRow} handleClose={() => setOpenElasticacheModal(false)} />
      )}
      {openOpenSearchModal && (
        <OpenSearchActionModal open={openOpenSearchModal} selectedRow={selectedRow} handleClose={() => setOpenSearchModal(false)} />
      )}
      {openRedshiftModal && (
        <RedshiftActionModal open={openRedshiftModal} selectedRow={selectedRow} handleClose={() => setOpenRedshiftModal(false)} />
      )}
      {openSpModal && <SPActionModal open={openSpModal} selectedRow={selectedRow} handleClose={() => setOpenSpModal(false)} type={type} />}
    </Grid>
  );
};
