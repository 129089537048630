import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography, Stack, Divider } from '@mui/material';
import { CloseOutlined as CloseOutlinedIcon } from '@mui/icons-material';
import './component.css';
import { useTheme } from '@mui/material/styles';

interface ComponentProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  icon: React.ElementType;
  borderColor?: string;
  header: string;
  content: React.ReactNode;
  cancelText?: string;
  confirmText: string;
}

export const Component: React.FC<ComponentProps> = (props) => {
  const theme = useTheme();
  const Icon = props.icon;
  const borderColor = props.borderColor || theme.palette.error.main;
  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '8px',
          borderTop: `7px solid ${borderColor}`,
          maxWidth: '500px',
          minWidth: '400px',
          width: '500px'
        }
      }}
    >
      <DialogTitle>
        <IconButton style={{ position: 'absolute', top: 8, right: 8 }} onClick={props.onClose}>
          <CloseOutlinedIcon sx={{ color: '#666666', fontSize: '28px' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: '40px', py: 2 }}>
        <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
          <div className="circle-icon" style={{ backgroundColor: borderColor }}>
            <Icon sx={{ fontSize: '40px', color: 'white' }} />
          </div>
          <Stack spacing={1}>
            <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{props.header}</Typography>
            {props.content}
          </Stack>
        </Stack>
      </DialogContent>
      <Divider sx={{ backgroundColor: '#D9D9D9' }} />
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
          {props.cancelText && (
            <Button onClick={props.onClose} variant="outlined" sx={{ width: '20%' }}>
              {props.cancelText}
            </Button>
          )}
          <Button onClick={props.onConfirm} variant="contained" sx={{ width: '20%' }}>
            {props.confirmText}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
