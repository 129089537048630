import ExcelJS from 'exceljs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export const useDownloadTableData = () => {
  const generateExcel = (columns: any[], rows: any[], title: string) => {
    const cols: any[] = columns.map((col) => {
      return { name: col.Header };
    });
    const data: any[] = rows.map((row) => {
      return columns.map((col) => row[col.accessor]);
    });
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet(title);
    ws.addTable({
      name: 'TableData',
      ref: 'A1',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium2',
        showRowStripes: true
      },
      columns: cols,
      rows: data
    });
    wb.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${title}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const generatePDF = (columns: any[], rows: any[], title: string) => {
    const doc = new jsPDF();
    const colHeaders: any[] = columns.map((col) => col.Header);
    const data: any[] = rows.map((row) => {
      return columns.map((col) => row[col.accessor]);
    });
    autoTable(doc, {
      head: [colHeaders],
      body: data
    });

    doc.save(`${title}.pdf`);
  };

  return { generateExcel, generatePDF };
};
