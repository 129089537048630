import { useNavigate } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';
import { APP_DEFAULT_PATH } from 'config';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer style={{ zIndex: 1200 }}>
      <div
        className="d-flex justify-content-center w-100"
        style={{
          // position: 'relative',
          // bottom: 0,
          // borderTop: '1px solid rgba(140, 140, 140, 0.5)',
          background: 'rgba(232, 238, 255, 1)',
          padding: '10px 0px',
          boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.1)'
        }}
      >
        <ButtonBase disableRipple onClick={() => navigate(APP_DEFAULT_PATH)}>
          <img src="/logo.svg" alt="Logo" height={48} />
        </ButtonBase>
      </div>
    </footer>
  );
};
export default Footer;
