import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';
import Loading from 'components/Loading';
import { ConfirmDialog } from 'shared';
import { LogoutOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';

export const LogoutDialog = (props: any) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate(`/login`, {
        state: {
          from: ''
        }
      });
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  return (
    <>
      <Loading show={isLoading} />
      <ConfirmDialog
        open={props.dialogOpen}
        onClose={props.handleCancel}
        onConfirm={handleLogout}
        header="Logout"
        content={<Typography>Are you sure you want to log out?</Typography>}
        cancelText="No"
        confirmText="Yes"
        icon={LogoutOutlined}
      />
    </>
  );
};
