import * as Yup from 'yup';

export const callUsValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid Email').required('Email is required'),
  date: Yup.string().nullable().required('Date is required'),
  time_zone: Yup.string().required('Time Zone is required'),
  time_slot: Yup.string().required('Time Slot is required'),
  message: Yup.string().required('Message is required')
});
