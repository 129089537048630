import { Box, Modal, IconButton, Typography, Stack } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { CustomerServiceOutlined } from '@ant-design/icons';
import { NeedHelp } from 'shared/callUs/NeedHelp';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  p: 1,
  overflowY: 'auto',
  borderRadius: '8px',
  padding: '40px'
};

export const DtS3ActionModal = (props: any) => {
  return (
    <Modal open={props.open} onClose={props.handleClose} aria-labelledby={`Actions modal for ${props.title}`}>
      <Box sx={style}>
        <Typography sx={{ color: 'secondary.main', textAlign: 'center' }} variant="h5">
          Recommended Action for {props.title}
        </Typography>
        <IconButton sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }} onClick={props.handleClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
        <Box sx={{ typography: 'body1', p: 2 }}>
          <Typography>
            For Architectural Optimizations of this category, reach out to the DiscoverCloud Architects who can help you on an optimum
            architecture on the basis of Well Architected Pillars and your applications business criticality.
          </Typography>
        </Box>
        <Stack justifyContent="center" alignItems="center">
          <NeedHelp variant="contained" startIcon={<CustomerServiceOutlined />} text="Need Help" />
        </Stack>
      </Box>
    </Modal>
  );
};
