import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  setUpCompletedAccounts: undefined,
  awsAccounts: undefined
};

const awsAccountDetails = createSlice({
  name: 'awsSetup',
  initialState,
  reducers: {
    setSetUpCompletedAccounts(state, action) {
      state.setUpCompletedAccounts = action.payload;
    },
    setAwsAccounts(state, action) {
      state.awsAccounts = action.payload;
    }
  }
});

export default awsAccountDetails.reducer;

export const { setSetUpCompletedAccounts, setAwsAccounts } = awsAccountDetails.actions;
