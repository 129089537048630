import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery, AppBarProps, ButtonBase } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import IconButton from 'components/@extended/IconButton';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// typs
import { LAYOUT_CONST } from 'types/config';
import Logo from 'components/logo';
import { useLocation, useNavigate } from 'react-router';
import { useUserData, useConfig, useSelectedProduct, useEnvironment } from 'hooks';
import Products from 'enums/products';
import EnvironmentMode from 'enums/environmentMode';
import sol_img from '../../../assets/images/Solutions_icon.svg';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const Header = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { menuOrientation } = useConfig();
  const location = useLocation();
  const { getUserData, addUserData } = useUserData();
  const navigate = useNavigate();
  const { isTrekora, isTraverse, isSAP, isInfora, isSolutions, isVideoSummarize, isHotTopics } = useSelectedProduct();
  const { currentEnvironment } = useEnvironment();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;
  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.100';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';
  const [showToggle, setShowToggle] = useState(false);

  const selectedType = getUserData('selectedType');

  useEffect(() => {
    const selectedAwsAccountId: boolean = getUserData('selectedAwsAccountId') !== '' ? true : false;
    setShowToggle((isTrekora && selectedAwsAccountId) || isTraverse);
  }, [getUserData, location.pathname]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleDocumentClick: any = (event: MouseEvent) => {
    if (menuRef.current === null || (menuRef.current && !menuRef.current.contains(event.target as Node))) {
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    body?.addEventListener('click', () => {
      setIsOpen(false);
    });
  }, []);

  // common header
  const mainHeader: ReactNode = (
    <Toolbar sx={{ py: 0 }}>
      {!isHorizontal && showToggle ? (
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          variant="light"
          sx={{
            color: 'text.primary',
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            ml: { xs: 0, lg: -2 },
            '&:hover': {
              bgcolor: open ? iconBackColorOpen : iconBackColor
            }
          }}
        >
          {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconButton>
      ) : (
        <div>
          {selectedType === '' && <Logo isIcon={!open} sx={{ width: open ? 'auto' : 35, height: 35 }} />}
          {selectedType !== '' && (
            <div className="d-flex align-items-center">
              <div className="menu-container" ref={menuRef}>
                <img
                  src="/switcher.svg"
                  alt="switcher"
                  style={{ marginRight: '8px', top: '4px', position: 'relative' }}
                  onClick={toggleMenu}
                  className="cursor-pointer"
                />

                {isOpen && (
                  <div className="menu-content">
                    <div className="pointer"></div>
                    <div className="menu-heading text-center">Switch to other products</div>
                    <ul className="menu-list">
                      <li
                        className="d-flex align-items-center cursor-pointer switcher-menu-item"
                        onClick={() => {
                          setIsOpen(false);
                          addUserData('selectedType', Products.TREKORA);
                          addUserData('selectedAwsAccountId', '');
                          navigate('/trekora/savingsReview');
                        }}
                      >
                        <img src="/trekora-switcher.svg" alt="Trekora" /> <span className="prl-2">Trekora</span>
                      </li>
                      {currentEnvironment === EnvironmentMode.DEMO && (
                        <li
                          className="d-flex align-items-center cursor-pointer switcher-menu-item"
                          onClick={() => {
                            setIsOpen(false);
                            addUserData('selectedType', Products.TRAVERSE);
                            addUserData('selectedAwsAccountId', '');
                            navigate('/traverse/welcome');
                          }}
                        >
                          <img src="/traverse-switcher.svg" alt="Traverse" /> <span className="prl-2">Traverse</span>
                        </li>
                      )}
                      <li
                        className="d-flex align-items-center cursor-pointer switcher-menu-item"
                        onClick={() => {
                          setIsOpen(false);
                          addUserData('selectedType', Products.SAPASSIST);
                          addUserData('selectedAwsAccountId', '');
                          navigate('/sapAssist/dashboard');
                        }}
                      >
                        <img src="/SAPAssist-switcher.svg" alt="SAPAssist" /> <span className="prl-2">SAPAssist</span>
                      </li>
                      {currentEnvironment === EnvironmentMode.DEMO && (
                        <li
                          className="d-flex align-items-center  cursor-pointer switcher-menu-item"
                          onClick={() => {
                            setIsOpen(false);
                            addUserData('selectedType', Products.SOLUTIONS);
                            addUserData('selectedAwsAccountId', '');
                            navigate('/solutions');
                          }}
                        >
                          <img src="/Solutions_icon.svg" alt="LLMx" /> <span className="prl-2">LLMx</span>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>

              {isTrekora && (
                <ButtonBase disableRipple onClick={() => navigate('/trekora/savingsReview')}>
                  <img src="/Trekora-logo.svg" alt="Trekora" className="prl-2" />
                </ButtonBase>
              )}
              {isTraverse && (
                <ButtonBase disableRipple onClick={() => navigate('/traverse/welcome')}>
                  <img src="/Traverse-logo.svg" alt="Traverse" className="prl-2" />
                </ButtonBase>
              )}
              {isSAP && (
                <ButtonBase disableRipple onClick={() => navigate('/sapAssist/dashboard')}>
                  <img src="/SAPAssist-logo.svg" alt="SAPAssist" className="prl-2" />
                </ButtonBase>
              )}
              {/* {isInfora && (
                <ButtonBase disableRipple onClick={() => navigate('/Infora/cases')}>
                  <img src="/Infora-Logo.svg" alt="Infora" className="prl-2" />
                </ButtonBase>
              )} */}
              {(isSolutions || isVideoSummarize || isHotTopics || isInfora) && (
                <ButtonBase disableRipple onClick={() => navigate('/Solutions')}>
                  <img src={sol_img} alt="LLMx" className="prl-2" width={80} />
                </ButtonBase>
              )}
            </div>
          )}
        </div>
      )}
      {headerContent}
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 1000,
      width: isHorizontal || !showToggle ? '100%' : open ? 'calc(100% - 260px)' : { xs: '100%', lg: 'calc(100% - 60px)' }
    }
  };

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
