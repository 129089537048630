import { SearchOutlined } from '@ant-design/icons';
import { FormControl, InputAdornment, ListSubheader, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
interface ComponentProps {
  options: { code: string; label: string; phone: string }[];
  value?: string | null;
  onValueChange?: (value: string | null) => void;
  disabled?: boolean;
}

const SelectWithSearch: React.FC<ComponentProps> = ({ ...props }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      autoFocus: false,
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
   useEffect(() => {
    if (props.value) setSelectedOption(props.value!);
  }, [props.value]);

  const containsText = (text: any, search: string) =>
    text.label.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
    text.code.toLowerCase() === search.toLowerCase() ||
    text.phone.indexOf(search) > -1;
  const displayedOptions = useMemo(() => props.options.filter((option) => containsText(option, searchText)), [searchText]);
  const change = (val: string) => {
    setSelectedOption(val);
    if (props.onValueChange) props.onValueChange(val);
  };
  return (
    <FormControl sx={{ m: 1, width: 100, mt: 1, ml: '-14px ' }}>
      <Select
        MenuProps={MenuProps}
        labelId="search-select-label"
        id="search-select"
        value={selectedOption}
        // label="Options"
        onChange={(e) => change(e.target.value!)}
        onClose={() => setSearchText('')}
        renderValue={() => selectedOption}
        inputProps={{ readOnly: props.disabled }}
      >
        <ListSubheader>
          <TextField
            size="small"
            id="txtSearch"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              )
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {displayedOptions.map((option, i) => (
          <MenuItem key={i} value={option.phone}>
            {option.label} ({option.phone})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default SelectWithSearch;
