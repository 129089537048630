import { ButtonBase, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

import { useNavigate } from 'react-router';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 14,
      borderRadius: '4px',
      background: '#FFF',
      boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.25)',
      color: '#262626'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#FFF'
    }
  })
);

export const SubscriptionPlan = () => {
  const navigate = useNavigate();
  //const { getUserData } = useUserData();
  // const plan = getUserData('userData')?.subscription_plan;
  // const getTitle = () => {
  //   switch (plan) {
  //     case 'FREE':
  //       return 'You are in Free Forever Plan';
  //     case 'PRO':
  //       return 'You are in Pro Plan';
  //     case 'PREMIUM':
  //       return 'You are in Premium Plan';
  //     default:
  //       return 'You are in Free Forever Plan';
  //   }
  // };
  // const getLogo = () => {
  //   switch (plan) {
  //     case 'FREE':
  //       return '/freeLogo.svg';
  //     case 'PRO':
  //       return '/proLogo.svg';
  //     case 'PREMIUM':
  //       return '/premiumLogo.svg';
  //     default:
  //       return '/freeLogo.svg';
  //   }
  // };
  return (
    <LightTooltip title='Limited Access' placement="top">
      <ButtonBase disableRipple onClick={() => navigate('/limited-access')}>
        <img src='/freeLogo.svg' alt="Limited Access" height="21" />
      </ButtonBase>
    </LightTooltip>
  );
};
