import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  Divider,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  styled
} from '@mui/material';

// project import
import ProfileTab from './ProfileTab';
import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import { LogoutDialog } from '../Logout';
import { useUserData, useAuth } from 'hooks';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#E3EFFF',
      color: theme.palette.primary.main,
      boxShadow: theme.shadows[1],
      fontSize: 14,
      marginTop: '5px !important'
    }
  })
);

const Profile = () => {
  const theme = useTheme();
  const { getUserData } = useUserData();
  const { user } = useAuth();
  const [logoutFlag, setLogoutFlag] = useState(false);
  const selectedAccountId = getUserData('selectedAwsAccountId');

  const handleLogout = async () => {
    setOpen(false);
    setLogoutFlag(true);
  };

  const handleCancel = () => {
    setLogoutFlag(false);
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const capitalizeAfterSpace = (str: string) => {
    str = str.trim().replace(/\s+/g, ' ');
    // const name = str.split(' ');
    // const fullName = `${name[0]} ${name[1]}`;
    // if (name.length > 2 && fullName.length < 15) str = `${name[0]} ${name[1]} ${name[2]}`;
    // else str = fullName;
    return str
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const getAvatar = (name: string) => {
    const names = name.split(' ').slice(0, 2);
    let initials = '';
    names.forEach((n) => {
      initials += n.slice(0, 1).toUpperCase();
    });
    return initials;
  };
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'grey.300' : 'grey.100' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Stack>
            <Typography variant="h6" style={{ maxWidth: '100px', whiteSpace: 'pre-wrap' }}>
              {capitalizeAfterSpace(getUserData('userData')?.first_name || '')}
            </Typography>
            {selectedAccountId !== '' && (
              <Typography variant="body2" color="textSecondary">
                {selectedAccountId}
              </Typography>
            )}
          </Stack>
          {open ? <UpOutlined /> : <DownOutlined />}
        </Stack>
      </ButtonBase>
      <Popper
        id={open ? 'profile-grow' : undefined}
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
        sx={{ zIndex: 100 }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 150,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 250
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={false}>
                  <CardContent sx={{ p: 2 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <Stack direction="row" spacing={1.25} alignItems="center">
                          <Avatar>{getAvatar(user?.name || '')}</Avatar>
                          <Stack>
                            <Typography variant="h6">
                              {getUserData('email').length > 20 ? (
                                <LightTooltip title={getUserData('email')}>
                                  <div
                                    style={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      width: '150px',
                                      cursor: 'default'
                                    }}
                                  >
                                    {getUserData('email')}
                                  </div>
                                </LightTooltip>
                              ) : (
                                <div>{getUserData('email')}</div>
                              )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider sx={{ color: '#262626' }} />
                  <ProfileTab handleLogout={handleLogout} handleCancel={(e: any) => setOpen(e)} />
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      {logoutFlag && <LogoutDialog dialogOpen={logoutFlag} handleCancel={handleCancel} />}
    </Box>
  );
};

export default Profile;
