import EnvironmentMode from 'enums/environmentMode';

export const useEnvironment = () => {
  const environmentMode = process.env.REACT_APP_ENV_MODE;
  let currentEnvironment: string;
  switch (environmentMode) {
    case 'QA':
      currentEnvironment = EnvironmentMode.QA;
      break;
    case 'PRODUCTION':
      currentEnvironment = EnvironmentMode.PRODUCTION;
      break;
    case 'DEMO':
      currentEnvironment = EnvironmentMode.DEMO;
      break;
    default:
      currentEnvironment = EnvironmentMode.DEVELOPMENT;
      break;
  }

  return { currentEnvironment };
};
