import AWS from 'aws-sdk';

const useEmailTrigger = () => {
  const sendEmail = async (toEmailId: string, subject: string, message: string) => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET,
      region: process.env.REACT_APP_AWS_REGION
    });
    const ses = new AWS.SES({ apiVersion: '2010-12-01' });
    const params = {
      Source: process.env.REACT_APP_FROM_EMAILID!,
      Destination: {
        ToAddresses: [toEmailId]
      },
      Message: {
        Subject: {
          Data: subject
        },
        Body: {
          Text: {
            Data: message
          }
        }
      }
    };

    // Send the email using AWS SES
    ses.sendEmail(params, (err, data) => {
      if (err) {
        console.log('Error sending email:', err);
      } else {
        console.log('Email sent successfully:', data);
      }
    });
  };
  return { sendEmail };
};
export default useEmailTrigger;
