import jwtDecode from 'jwt-decode';

export function isTokenExpired(token: string | null) {
  if (!token) {
    return true;
  }
  const decodedToken: any = jwtDecode(token);
  const expirationDate = new Date(decodedToken.exp * 1000);
  const oneMinuteBeforeExpiration = new Date(expirationDate.getTime() - 60 * 1000);
  return oneMinuteBeforeExpiration < new Date();
}
