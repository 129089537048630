import { useEffect, useState } from 'react';
import { Button, Stack, TableCell, TableRow, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { FileUpload as FileUploadIcon } from '@mui/icons-material';
import useInforaAxios from 'utils/axiosInfora';
import { FileUploadStatus } from 'enums/fileUploadStatus';
import axios from 'axios';
import { uploadedFileHeaderCells } from 'constants/headerCells';
import { SubRow } from 'shared/dataTable/SubDataTable';
import { formatFileLastModified, formatFileSize } from 'shared/dataTable/utils';
import { NOTIFY } from 'shared';
import { MESSAGES } from 'constants/messages';

const allowedDocs: any = { generic: 1 };

export const UploadDoc = (props: any) => {
  const selectedCaseName = props.row.original;
  const [documentTypeList, setDocumentTypeList] = useState<string>('generic');
  const [chosenFile, setChosenFile] = useState<any>(null);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressStatus, setProgressStatus] = useState<FileUploadStatus | undefined>();
  const [uploadResultMessage, setUploadResultMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [inputKey, setInputKey] = useState(Date.now());
  const axiosInforaServices = useInforaAxios();
  const docTypeOptions: any = [];
  Object.keys(allowedDocs).forEach((docType) => {
    if (allowedDocs[docType] > 0) {
      docTypeOptions.push({ label: docType + ' - ' + allowedDocs[docType], value: docType });
    }
  });

  const handleUpload = (event: any) => {
    setDocumentTypeList('generic');
    setChosenFile(event.target.files[0]);
  };

  const handleDelete = () => {
    setChosenFile(null);
    setDocumentTypeList('');
    setUploadProgress(0);
    setUploadResultMessage('');
    setProgressStatus(undefined);
    setInputKey(Date.now());
  };

  const handleDone = () => {
    if (props.onFileUpload) {
      props.onFileUpload();
    }
  };

  const uploadFile = async (file: File, postPolicyFields: { [key: string]: string }, bucketUrl: string) => {
    let postData = new FormData();
    for (const [key, entry] of Object.entries(postPolicyFields)) {
      postData.append(key, entry);
    }
    postData.append('file', file);
    const options = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setUploadProgress(percent);
          // setUploadResultMessage(`${loaded}kb of ${total}kb | ${percent}%`);
        } else if (percent === 100) {
          setUploadProgress(percent);
          setUploadResultMessage('File uploaded successfully');
        }
      }
    };
    await axios
      .post(bucketUrl, postData, options)
      .then(() => {
        handleDone();
      })
      .catch(() => {
        setProgressStatus(FileUploadStatus.ERROR);
        setUploadResultMessage('File upload failed');
      });
  };

  const handleUploadClick = async () => {
    setProgressStatus(FileUploadStatus.IN_PROGRESS);
    setUploadResultMessage('');
    setUploadProgress(0);
    setShowProgressBar(true);

    const userName = process.env.REACT_APP_INFORA_USER!;
    const fileUploadParams = {
      userId: userName,
      caseId: `${userName}:${selectedCaseName.caseId}`,
      caseName: selectedCaseName.name,
      fileName: chosenFile.name,
      fileExtension: '.' + chosenFile.name.split('.').pop(),
      documentType: documentTypeList,
      tagging: `<Tagging><TagSet><Tag><Key>userId</Key><Value>${userName}</Value></Tag></TagSet></Tagging>`
    };
    try {
      axiosInforaServices
        .post('/document', fileUploadParams)
        .then((res: any) => {
          const result = res.data;
          uploadFile(chosenFile, result.fields, result.url);
        })
        .catch((err: Error) => {
          NOTIFY.Error(MESSAGES.API_REQUEST_ERROR);
          setProgressStatus(FileUploadStatus.ERROR);
          // setUploadResultMessage('File upload failed');
        })
        .finally(() => {
          // setChosenFile(null);
          // setIsUploading(false);
        });
    } catch (err: any) {
      NOTIFY.Error(MESSAGES.API_REQUEST_ERROR);
      console.error(err);
    }
    // finally {
    //   setChosenFile(null);
    // }
  };

  useEffect(() => {
    if (uploadProgress >= 100) {
      setProgressStatus(FileUploadStatus.SUCCESS);
      setUploadResultMessage('File uploaded sucessfully');
    } else if (uploadProgress > 0 && uploadProgress < 100) {
      setProgressStatus(FileUploadStatus.IN_PROGRESS);
    }
  }, [uploadProgress, progressStatus]);

  const getUploadedFileData = () => {
    return [
      {
        fileName: chosenFile.name,
        fileType: chosenFile.type,
        fileSize: formatFileSize(chosenFile.size),
        uploadedDate: formatFileLastModified(chosenFile.lastModified, chosenFile)
      }
    ];
  };

  return (
    <>
      <TableRow>
        <TableCell colSpan={8} sx={{ p: 2.5 }}>
          <MainCard sx={{ mt: 2 }}>
            <Stack spacing={2}>
              <Stack spacing={0.5}>
                <Typography>Files to upload</Typography>
                <Typography variant="caption" color="textSecondary">
                  Please choose the files you wish to upload to the selected case
                </Typography>
                <div>
                  <Button variant="outlined" component="label" startIcon={<FileUploadIcon />} disabled={chosenFile !== null}>
                    Choose files
                    <input type="file" style={{ display: 'none' }} onChange={handleUpload} key={inputKey} />
                  </Button>
                </div>
              </Stack>
              {chosenFile && (
                <SubRow
                  row={props.row}
                  rowProps={props.rowProps}
                  columns={uploadedFileHeaderCells}
                  data={getUploadedFileData() || []}
                  fileProperties={{
                    handleCancel: handleDelete,
                    showUploadFileActions: true,
                    handleUploadClick,
                    uploadResultMessage,
                    fileUploadProgress: uploadProgress,
                    showProgressBar
                  }}
                />
              )}
            </Stack>
          </MainCard>
        </TableCell>
      </TableRow>
    </>
  );
};
