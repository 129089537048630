import { PlanDetails } from './types';

export const plans: PlanDetails = {
  0: {
    NAME: 'FREE',
    NO_OF_ACCOUNTS: 1,
    SHOW_CONTENT: false,
    OS_ACCESS: false,
    SHOW_LOCK_RECORDS: true,
    PRODUCT_ACCESS: { TREKORA: true, TRAVERSE: false, SAPASSIST: false, INFORA: false, CHATGENIE: false },
    SHOW_RECOMMENDED_ACTION: false,
    ENABLE_TABLE_EXPORT: false
  },
  1: {
    NAME: 'PRO',
    NO_OF_ACCOUNTS: 3,
    SHOW_CONTENT: true,
    OS_ACCESS: true,
    SHOW_LOCK_RECORDS: false,
    PRODUCT_ACCESS: { TREKORA: true, TRAVERSE: true, SAPASSIST: false, INFORA: false, CHATGENIE: false },
    SHOW_RECOMMENDED_ACTION: true,
    ENABLE_TABLE_EXPORT: true
  },
  2: {
    NAME: 'PREMIUM',
    NO_OF_ACCOUNTS: 100,
    SHOW_CONTENT: true,
    OS_ACCESS: true,
    SHOW_LOCK_RECORDS: false,
    PRODUCT_ACCESS: { TREKORA: true, TRAVERSE: true, SAPASSIST: true, INFORA: true, CHATGENIE: true, SOLUTIONS: true },
    SHOW_RECOMMENDED_ACTION: true,
    ENABLE_TABLE_EXPORT: true
  }
};
