import axios, { InternalAxiosRequestConfig } from 'axios';
import { useAuth } from 'hooks';
import { isTokenExpired } from './isTokenExpired';

const useInforaAxios = () => {
  const { inforaLogin } = useAuth();
  const axiosInforaServices = axios.create({
    baseURL: process.env.REACT_APP_INFORA_ENDPOINT
  });
  axiosInforaServices.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    let token = localStorage.getItem('inforaToken');
    if (isTokenExpired(token)) {
      await inforaLogin();
      token = localStorage.getItem('inforaToken');
    }
    if (token) {
      if (config && config.headers && token) config.headers['Authorization'] = token;
    }
    return config;
  });

  axiosInforaServices.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return axiosInforaServices;
};

export default useInforaAxios;
