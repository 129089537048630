import { Typography, Stack, Button } from '@mui/material';
import { useState } from 'react';
import { ActionModal } from 'shared';
import { CopyOutlined } from '@ant-design/icons';
import { useTheme } from '@mui/material/styles';

export const OpenSearchActionModal = (props: any) => {
  const theme = useTheme();
  const command = `aws opensearch purchase-reserved-instance-offering --reserved-instance-offering-id <--add the corresponding offering Id
  for this purchase--> --instance-count <--add the count of instances you want to subscribe--> --region <--add
  the region of instances you want to subscribe--> --reservation-name <--add a unique name to the reservation -->`;
  const [copy, icCopied] = useState('Copy');
  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        icCopied('Copied!');
      })
      .catch(() => {
        icCopied('Copy');
      });
  };

  const CommandText = (
    <Stack spacing={1}>
      <ol>
        <li>
          Go to{' '}
          <a href="https://aws.amazon.com" target="_blank" rel="noopener noreferrer">
            https://aws.amazon.com
          </a>
          , and then choose <b>Sign In to the Console.</b>.
        </li>
        <li>
          Under <b>Analytics</b>, choose <b>Amazon OpenSearch Service.</b>.
        </li>
        <li>
          Choose <b>Reserved Instance Leases</b> from the navigation pane.
        </li>
        <li>
          On this page, you can view your existing reservations. If you have many reservations, you can filter them to more easily identify
          and view a particular reservation.
        </li>
        <li>
          Choose <b>Order Reserved Instance.</b>.
        </li>
        <li>Provide a unique and descriptive name.</li>
        <li>
          Choose an instance type and the number of instances. For guidance, see{' '}
          <a
            href="https://docs.aws.amazon.com/opensearch-service/latest/developerguide/sizing-domains.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sizing Amazon OpenSearch Service domains
          </a>
          .
        </li>
        <li>Choose a term length and payment option. Review the payment details carefully.</li>
        <li>
          Choose <b>Next</b>.
        </li>
        <li>Review the purchase summary carefully. Purchases of Reserved Instances are non-refundable.</li>
        <li>
          Choose <b>Order</b>.
        </li>
        <li>
          AWS Doc Reference :{' '}
          <a
            href=" https://docs.aws.amazon.com/opensearch-service/latest/developerguide/ri.html#ri-console"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://docs.aws.amazon.com/opensearch-service/latest/developerguide/ri.html#ri-console
          </a>
          .
        </li>
      </ol>
    </Stack>
  );

  const CLIText = (
    <Stack spacing={1}>
      <ol>
        <li>Ensure that your AWS configuration is set to the desired AWS account and Region where the volume resides.</li>
        <li>
          Use the following command by adding the offering ID of Reservation that you want to subscribe.
          <Stack spacing={1} sx={{ mb: 1 }}>
            <Typography>
              <span style={{ float: 'right' }}>
                <Button
                  className="btncopy"
                  onClick={() => handleCopy(command)}
                  startIcon={<CopyOutlined style={{ color: 'secondary.main' }} />}
                  sx={{ color: 'secondary.main' }}
                >
                  {copy}
                </Button>
              </span>
            </Typography>
            <Typography
              sx={{ fontFamily: 'Courier New', border: `1px solid ${theme.palette.secondary.main}`, p: 1, mx: 8, borderRadius: '8px' }}
            >
              aws opensearch purchase-reserved-instance-offering --reserved-instance-offering-id{' '}
              <b>
                {'<'}--add the corresponding offering Id for this purchase--{'>'}
              </b>{' '}
              --instance-count{' '}
              <b>
                {'<'}--add the count of instances you want to subscribe--{'>'}
              </b>{' '}
              --region{' '}
              <b>
                {'<'}--add the region of instances you want to subscribe--{'>'}
              </b>{' '}
              --reservation-name{' '}
              <b>
                {'<'}--add a unique name to the reservation --{'>'}
              </b>
            </Typography>
          </Stack>
        </li>
        <li>
          <b>AWS doc reference : </b>
          <a
            href="https://docs.aws.amazon.com/cli/latest/reference/opensearch/purchase-reserved-instance-offering.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://docs.aws.amazon.com/cli/latest/reference/opensearch/purchase-reserved-instance-offering.html
          </a>
          .
        </li>
      </ol>
    </Stack>
  );
  return (
    <ActionModal CommandText={CommandText} CLIText={CLIText} open={props.open} onClose={props.handleClose} title="Amazon OpenSearch" />
  );
};
