// action - state management
import { REGISTER, LOGIN, LOGOUT, TRAVERSELOGIN, INFORALOGIN } from './actions';

// types
import { AuthProps, AuthActionProps } from 'types/auth';

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  isTraverseInitialized: false,
  isTraverseLoggedIn: false,
  isInforaLoggedIn: false,
  isInforaInitialized: false,
  user: null
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action: AuthActionProps) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload!;
      return {
        ...state,
        user
      };
    }
    case LOGIN: {
      const { user } = action.payload!;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user
      };
    }
    case TRAVERSELOGIN: {
      return {
        ...state,
        isTraverseLoggedIn: true,
        isTraverseInitialized: true
      };
    }
    case INFORALOGIN: {
      return {
        ...state,
        isInforaLoggedIn: true,
        isInforaInitialized: true
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        isTraverseInitialized: true,
        isTraverseLoggedIn: false,
        isInforaLoggedIn: false,
        isInforaInitialized: true,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;
