import { URLS } from 'constants/endpoints';
import { StatusEnum } from 'enums/statusEnum';
import useAxios from 'utils/axios';

export const useAwsStatus = () => {
  const axiosServices = useAxios();
  const getAccountStatus = async (aws_account_id: string, accountDetails: any = null) => {
    try {
      let stackStatus = '',
        accountStatus = false,
        isPayerAccount = '',
        isValidS3Bucket = false;
      if (!accountDetails) {
        const res = await axiosServices.get(URLS.USER.ACCOUNT_STATUS, { params: { aws_account_id } });
        stackStatus = res.data.data.data?.aws_stack_status;
        accountStatus = res.data.data.data?.aws_account_setup_status;
        isPayerAccount = res.data.data.data?.is_payer_account;
        isValidS3Bucket = res.data.data.data?.is_valid_s3_bucket;
      } else {
        stackStatus = accountDetails.aws_stack_status;
        accountStatus = accountDetails.is_aws_account_setup_completed;
        isPayerAccount = accountDetails.is_payer_account;
        isValidS3Bucket = accountDetails.is_valid_s3_bucket;
      }

      switch (stackStatus) {
        case 'CREATE_COMPLETE':
          if (isPayerAccount === StatusEnum.CHILD_ACCOUNT) return StatusEnum.NOT_PAYER;
          else if (isPayerAccount === StatusEnum.PAYER_ACCOUNT && !isValidS3Bucket) return StatusEnum.INVALID_S3;
          else if (isPayerAccount !== StatusEnum.CHILD_ACCOUNT && isPayerAccount !== StatusEnum.PAYER_ACCOUNT) {
            if (isPayerAccount === StatusEnum.OUTSIDE_ORG_AWS_ACC) return StatusEnum.OUTSIDE_ORG;
            else return StatusEnum.OTHER_PAYER_EXCEPTION;
          }
          return !accountStatus ? StatusEnum.SETUP_IN_PROGRESS : StatusEnum.SETUP_COMPLETED;
        case 'CREATE_IN_PROGRESS':
        case 'IN_PROGRESS':
          return StatusEnum.SETUP_IN_PROGRESS;
        case 'CREATE_FAILED':
          return StatusEnum.ERROR;
        case 'ERROR_IN_STACK':
          return StatusEnum.NOT_CONFIGURED;
        default:
          return StatusEnum.NOT_CONFIGURED;
      }
    } catch (error) {
      return 'error';
    }
  };

  return { getAccountStatus };
};
