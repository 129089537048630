enum Products {
  TREKORA = 'Trekora',
  TRAVERSE = 'Traverse',
  SAPASSIST = 'SAPAssist',
  INFORA = 'Infora',
  CHATGENIE = 'ChatGenie',
  SOLUTIONS = 'Solutions',
  VIDEO_SUMMARIZE = 'Video_Summarize',
  HOT_TOPICS = 'Hot_Topics'
}

export default Products;
