export const roundToNearest = (input: number) => {
  if (input < 1) return input * 4;
  if (input < 2) return 2;
  if (input <= 4) return 4;
  if (input < 9) return 8;
  if (input < 20) return 20;
  if (input < 2999) {
    const multiplyNumer = input < 1000 ? 10 : 100;
    const number = Math.ceil(Math.ceil(input / 4) / multiplyNumer) * multiplyNumer;
    return number * 4;
  }
  let numDigits = Math.floor(Math.log10(input)) + 1;
  let nearestMultiple = Math.ceil(input / 400) * 400;

  while (Math.floor(Math.log10(nearestMultiple)) + 1 < numDigits) {
    nearestMultiple += 40;
  }

  if (numDigits >= 3 && nearestMultiple < 1000) {
    nearestMultiple = Math.floor(nearestMultiple / 100) * 100;
  }
  if (nearestMultiple % 4000 !== 0) {
    nearestMultiple = Math.ceil(nearestMultiple / 4000) * 4000;
  }

  if (nearestMultiple <= input) {
    nearestMultiple += 4 * numDigits - 1 * 10;
  }

  if (nearestMultiple < 10000) {
    nearestMultiple = Math.ceil(nearestMultiple / 100) * 100;
  }

  return nearestMultiple;
};

export const formatAxisLabels = (value: number) => {
  let val = Math.abs(value);
  if (val >= 1000000) {
    const formattedData = val / 1000000;
    return Number.isInteger(formattedData) ? formattedData + 'M' : formattedData.toFixed(2) + 'M';
  }
  if (val >= 1000) {
    const formattedData = val / 1000;
    return Number.isInteger(formattedData) ? formattedData + 'K' : formattedData.toFixed(2) + 'K';
  }
  return Number.isInteger(val) ? val : val.toFixed(2);
};
