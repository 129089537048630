import { SideNavVisible } from './types';

export const SIDENAV_VISIBLE: SideNavVisible = {
  trekora: { showSideNav: true, hiddenNavUrls: ['/trekora/savingsReview', '/trekora/awsAccountSetup'] },
  traverse: { showSideNav: true, hiddenNavUrls: [] },
  sapasisst: { showSideNav: false, hiddenNavUrls: [] },
  solutions: { showSideNav: true, hiddenNavUrls: ['/solutions', '/solutions/chatbot', '/solutions/video-summarize'] }
};
// export const bgColorChangeMenu: string[] = ['/trekora/awsaccountsetup', '/faq', '/limited-access', '/managed-services', '/solutions'];

export const bgColorChangeMenu: RegExp[] = [
  /^\/trekora\/awsaccountsetup$/,
  /^\/faq$/,
  /^\/limited-access$/,
  /^\/managed-services$/,
  /^\/solutions$/,
  /^\/video_summarize$/
];

export const FOOTER_HIDDEN_SCREENS: RegExp[] = [/^\/home$/];
