import { Button } from '@mui/material';

const link = {
  textDecoration: 'underline',
  textTransform: 'none',
  padding: 0
};

export const NeedHelp = (props: any) => {
  const handleClick = () => {
  };

  return (
    <span>
      <Button
        variant={props.variant}
        startIcon={props.startIcon}
        onClick={handleClick}
        className={props.className}
        sx={
          props.variant === 'text'
            ? {
                ...link,
                marginTop: '-1px',
                '&:hover': {
                  ...link
                }
              }
            : {}
        }
      >
        {props.text}
      </Button>
    </span>
  );
};
