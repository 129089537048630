import { Typography, Stack, Button } from '@mui/material';
import { useState } from 'react';
import { ActionModal } from 'shared';
import { CopyOutlined } from '@ant-design/icons';
import { useTheme } from '@mui/material/styles';

export const RdsActionModal = (props: any) => {
  const theme = useTheme();
  const command = `aws rds purchase-reserved-db-instances-offering --reserved-db-instances-offering-id <--add the corresponding offering Id for this purchase-->`;
  const [copy, icCopied] = useState('Copy');
  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        icCopied('Copied!');
      })
      .catch(() => {
        icCopied('Copy');
      });
  };

  const CommandText = (
    <Stack spacing={1}>
      <ol>
        <li>Sign in to the AWS Management Console, and open the Amazon RDS console. </li>
        <li>In the navigation pane, choose Reserved instances.</li>
        <li>Choose Purchase reserved DB instance. </li>
        <li>For Product description, choose the DB engine and licensing type.</li>
        <li>For DB instance class, choose the DB instance class.</li>
        <li>For Multi-AZ deployment, choose whether you want a Multi-AZ deployment.</li>
        <li>For Term, choose the length of time you want the DB instance reserved.</li>
        <li>For Offering type, choose the offering type. After you choose the offering type, you can see the pricing information.</li>{' '}
        <li>
          (Optional) You can assign your own identifier to the reserved DB instances that you purchase to help you track them. For Reserved
          Id, type an identifier for your reserved DB instance.
        </li>
        <li>Choose Submit. Your reserved DB instance is purchased, then displayed in the Reserved instances list.</li>
        <li>
          Quick Link for Landing to the Purchase Reserved DB Instances [Check the region is pointing to correct region and engine as per
          instructions above] -{' '}
          <a
            href="https://us-east-1.console.aws.amazon.com/rds/home?region=us-east-1#reserved-instance-purchase:"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://us-east-1.console.aws.amazon.com/rds/home?region=us-east-1#reserved-instance-purchase:
          </a>
          .
        </li>
      </ol>
    </Stack>
  );

  const CLIText = (
    <Stack spacing={1}>
      <ol>
        <li>Ensure that your AWS configuration is set to the desired AWS account and Region where the volume resides.</li>
        <li>
          Use the following command by adding the offering ID of Reservation that you want to subscribe.
          <Stack spacing={1} sx={{ mb: 1 }}>
            <Typography>
              <span style={{ float: 'right' }}>
                <Button
                  className="btncopy"
                  onClick={() => handleCopy(command)}
                  startIcon={<CopyOutlined style={{ color: 'secondary.main' }} />}
                  sx={{ color: 'secondary.main' }}
                >
                  {copy}
                </Button>
              </span>
            </Typography>
            <Typography
              sx={{ fontFamily: 'Courier New', border: `1px solid ${theme.palette.secondary.main}`, p: 1, mx: 8, borderRadius: '8px' }}
            >
              aws rds purchase-reserved-db-instances-offering --reserved-db-instances-offering-id{' '}
              <b>
                {'<'}--add the corresponding offering Id for this purchase--{'>'}
              </b>
            </Typography>
          </Stack>
        </li>
      </ol>
    </Stack>
  );
  return <ActionModal CommandText={CommandText} CLIText={CLIText} open={props.open} onClose={props.handleClose} title="Amazon RDS" />;
};
